<template>
  <div v-if="demonstrativosDisplay.length">
    <div>
      <p class="accordion-min-width w-100 fs-5 text-dark fw-bold d-md-none">
        Adiantamento
      </p>
      <div class="overflow-auto ct-scrollbar-thin-horizontal">
        <div
          v-for="(demoItem, indexAccordion) in demonstrativosDisplay"
          :key="indexAccordion"
          class="accordion-min-width w-100"
        >
          <div
            class="accordion-item d-flex flex-row w-100 px-4 py-3 mb-4"
            style="line-height: 1.2"
          >
            <div class="d-flex flex-column col-2 col-md-2 me-8">
              <span href="#" class="fs-6 text-secondary"> Data Pagamento </span>
              <span href="#" class="fs-5 fw-normal text-dark">
                {{ formatDayPtBr(demoItem.dtPagamento) }}
              </span>
            </div>
            <div class="d-flex flex-column col-2 me-8">
              <span href="#" class="fs-6 text-secondary"> Valor Bruto </span>
              <span href="#" class="fs-5 fw-normal text-dark">
                {{ formatMoney(demoItem.valorRequerido /*accordion.salario*/) }}
              </span>
            </div>
            <div class="d-flex flex-column col-2 me-8">
              <span href="#" class="fs-6 text-secondary"> IRRF </span>
              <span href="#" class="fs-5 fw-normal text-dark">
                {{ formatMoney(demoItem.irrf /*accordion.salario*/) }}
              </span>
            </div>
            <div class="d-flex flex-column col-2">
              <span href="#" class="fs-6 text-secondary"> Valor Líquido </span>
              <span href="#" class="fs-5 fw-normal text-dark">
                {{ formatMoney(demoItem.liquido /*accordion.salario*/) }}
              </span>
            </div>
            <div
              class="d-flex flex-row w-100 justify-content-end"
              style="cursor: pointer"
            >
              <DownloadDemonstrativo
                :id="demoItem.idAdiantamento"
                :periodo="demoItem.dtPagamento"
                tipo-recibo="ADIANTAMENTO"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mb-2 mt-4 w-100">
      <div class="fw-bold fs-6">
        <span>
          Mostrando {{ demonstrativosDisplay.length }} de
          {{ totalDemonstrativos }}.
        </span>
      </div>
      <div
        v-if="showButtonLoadMore"
        class="text-primary ms-auto text-end similar-pointer d-flex align-items-center"
        @click="loadMore"
      >
        <i
          class="mdi mdi-rotate-3d-variant mdi-18px"
          :class="`${loadingDemonstrativos ? 'mdi-spin' : ''}`"
        ></i>
        <span class="ms-1 fw-bold">Carregar mais registros</span>
      </div>
      <div />
    </div>
  </div>

  <NoDataList
    v-if="!demonstrativosDisplay.length && !loadingDemonstrativos"
    text="Não possui demonstrativo de adiantamento."
  />

  <div v-if="!demonstrativosDisplay.length && loadingDemonstrativos">
    <div class="d-flex flex-column w-100 align-items-center justify-center">
      <div class="spinner-border text-primary font-size-10 m-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { formatMoney } from "@/composables/useFormatMoney";
import { formatDayPtBr } from "@/composables/useFormatDate";
import { useLazyQuery } from "@vue/apollo-composable";
import { getDemosAdiantamentosDisplay } from "@/state/graphql/demonstrativos";
import DownloadDemonstrativo from "./components/DownloadDemonstrativo";
import NoDataList from "./components/NoDataList.vue";

export default {
  name: "AdiantamentoDisplayView",
  components: {
    DownloadDemonstrativo,
    NoDataList,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    /*
    const demonstrativosDisplay = ref([
      {
        dtPagamento: "2022-09-01",
        valorRequerido: 2222,
        valorIrrf: 0,
        liquido: 3333,
      },
    ]);
    */

    const demonstrativosDisplay = ref([]);
    const showButtonLoadMore = ref(false);
    const today = new Date();

    /* Queries */
    const {
      result: resultDemonstrativos,
      onResult: onResultDemonstrativos,
      loading: loadingDemonstrativos,
      load: loadDemonstrativos,
      // error: errorOnDemonstrativos,
      //refetch: refetchDemonstrativos,
      fetchMore,
    } = useLazyQuery(getDemosAdiantamentosDisplay, () => ({}), {
      fetchPolicy: "network-only",
    });

    onResultDemonstrativos(result => {
      let apiDemonstrativos =
        result.data.adiantamentoTrabalhadorDisplayPage.data;
      showButtonLoadMore.value =
        result.data.adiantamentoTrabalhadorDisplayPage.cursor;
      demonstrativosDisplay.value = apiDemonstrativos.map(demo => {
        return Object.assign({ ...demo, id: demo.id });
      });
    });

    const totalDemonstrativos = computed(() => {
      if (resultDemonstrativos.value) {
        return resultDemonstrativos.value.adiantamentoTrabalhadorDisplayPage
          .total;
      }
      return 0;
    });

    function loadMore() {
      fetchMore({
        variables: {
          cursor:
            resultDemonstrativos.value.adiantamentoTrabalhadorDisplayPage
              .cursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            ...previousResult,
            adiantamentoTrabalhadorDisplayPage: {
              ...previousResult.adiantamentoTrabalhadorDisplayPage,
              cursor: fetchMoreResult.adiantamentoTrabalhadorDisplayPage.cursor,
              data: [
                ...previousResult.adiantamentoTrabalhadorDisplayPage.data,
                ...fetchMoreResult.adiantamentoTrabalhadorDisplayPage.data,
              ],
            },
          };
        },
      });
    }

    return {
      demonstrativosDisplay,
      formatMoney,
      today,
      formatDayPtBr,
      totalDemonstrativos,
      loadingDemonstrativos,
      loadDemonstrativos,
      loadMore,
    };
  },
  watch: {
    modelValue(nextValue) {
      this.$emit("update:modelValue", nextValue);
      if (nextValue && !this.demonstrativosDisplay.length)
        this.loadDemonstrativos();
    },
  },
};
</script>
