import { imageUrlToBase64 } from "@/composables/useBase64Img";
import { formatDate } from "@/composables/useFormatDate";
import {
  formatNumber,
  formatPriceWithoutBRL,
} from "@/composables/useFormatMoney";
import { limitString } from "@/composables/useHandleString";
import { usePdfMake } from "@/composables/usePdfMake";
import { apolloClient } from "@/state/client";
import { getReciboDecimoTerceiroDataSource } from "@/state/graphql/data-sources";

export const gerarReciboDecimoTerceiro = async (
  idEmpregador,
  ano,
  parcela,
  typeCreate = "newTab"
) => {
  const customLabel = ({ codigo, descricao }) =>
    `${
      codigo.toString().length < 3 ? ("00" + codigo).slice(-3) : codigo
    } - ${descricao}`;

  const { openPdfNewTab, downloadPDF } = usePdfMake();

  const TABLE_CONFIGS_PDFMAKE = {
    borderColor: "#f2f6f9",
    borderWidth: 1.5,
    fontSizeParagaph: 9,
  };

  let nomeTrabalhador = null;

  let docDefinition = (
    decimoTerceiroDados,
    logoEmpregador
  ) => {
    let pages = []
    let currentPage = null
    let totalEventosPerPage = decimoTerceiroDados.contaBancaria ? 9 : 10

    for (
      let i = 0;
      i < decimoTerceiroDados.eventosDecimoTerceiro.length;
      i += totalEventosPerPage
    ) {
      const eventosDecimoTerceiroOrdered = [
        ...decimoTerceiroDados.eventosDecimoTerceiro
          .filter((item) => item.natureza === 'PROVENTO')
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo),
        ...decimoTerceiroDados.eventosDecimoTerceiro
          .filter((item) => item.natureza === 'DESCONTO')
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo),
        ...decimoTerceiroDados.eventosDecimoTerceiro
          .filter(
            (item) =>
              item.natureza !== 'DESCONTO' &&
              item.natureza !== 'PROVENTO'
          )
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo)
      ]
      let ficArray = []
      currentPage =
        eventosDecimoTerceiroOrdered.length > totalEventosPerPage
          ? 'Página ' + Math.trunc(i / 10 + 1)
          : ' '
      for (
        let j = 0;
        j <
        totalEventosPerPage -
          eventosDecimoTerceiroOrdered.slice(i, i + totalEventosPerPage).length;
        j++
      ) {
        ficArray.push(0)
      }
      pages = [
        ...pages,
        {
          layout: 'noBorders',
          table: {
            widths: ['auto', '*', 160],
            body: [
              [
                logoEmpregador
                  ? {
                      marginBottom: 5,
                      image: logoEmpregador,
                      fit: ['auto', 35]
                    }
                  : {
                      text: ' ',
                      marginBottom: 20
                    },
                {
                  alignment: 'left',
                  text: [
                    {
                      text: limitString(decimoTerceiroDados.localDenominacao, 60) + '\n',
                      bold: true,
                      fontSize: 10
                    },
                    {
                      text: `${decimoTerceiroDados.localInscricao}`,
                      fontSize: 8,
                      bold: false
                    },
                    {
                      text: `\n ${limitString(decimoTerceiroDados.localEndereco, 80)}`,
                      fontSize: 8,
                      bold: false
                    }
                  ]
                },
                {
                  alignment: 'center',
                  text: [
                    {
                      text: 'Recibo de Pagamento de 13º',
                      fontSize: 8,
                      bold: true
                    },
                    {
                      text: `\n ${
                        parcela === 'PRIMEIRA' ? '1º' : '2º'
                      } PARCELA DE ${ano}`,
                      fontSize: 10,
                      bold: true
                    },
                    {
                      text: `\n Data pagamento: ${formatDate(
                        decimoTerceiroDados.dtPagamento
                      )}`,
                      fontSize: 8
                    }
                  ]
                }
              ]
            ]
          }
        },
        {
          layout: {
            hLineWidth: function (i, node) {
              if (
                i === 0 ||
                (i === node.table.body.length && !decimoTerceiroDados.contaBancaria)
              ) {
                return TABLE_CONFIGS_PDFMAKE.borderWidth
              }
              return 0
            },
            vLineWidth: function () {
              return 0
            },
            hLineColor: function () {
              return TABLE_CONFIGS_PDFMAKE.borderColor
            }
          },
          table: {
            widths: ['*', 'auto', 'auto'],
            body: [
              [
                {
                  marginTop: 5,
                  alignment: 'left',
                  colSpan: 3,
                  text: [
                    {
                      lineHeight: 0.8,
                      text: limitString(`${decimoTerceiroDados.matricula} - ${decimoTerceiroDados.nomeTrabalhador}`, 100),
                      bold: true,
                      fontSize: 10
                    }
                  ]
                },
                { text: '' },
                { text: '' }
              ],
              [
                {
                  marginBottom: !decimoTerceiroDados.contaBancaria ? 5 : 0,
                  lineHeight: !decimoTerceiroDados.contaBancaria ? 1 : 0.7,
                  alignment: 'left',
                  text: [
                    {
                      text: limitString(`${decimoTerceiroDados.cboFuncao} - ${decimoTerceiroDados.descricaoFuncao}`, 60),
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  lineHeight: !decimoTerceiroDados.contaBancaria ? 1 : 0.7,
                  alignment: 'right',
                  text: [
                    {
                      text: 'Admissão: ',
                      bold: true,
                      fontSize: 8
                    },
                    {
                      text: `${formatDate(decimoTerceiroDados.dtAdmissao)}`,
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  lineHeight: !decimoTerceiroDados.contaBancaria ? 1 : 0.7,
                  alignment: 'right',
                  text: [
                    {
                      text: 'Salário Base: ',
                      bold: true,
                      fontSize: 8
                    },
                    {
                      text: `${formatPriceWithoutBRL(
                        decimoTerceiroDados.salarioBase
                      )} ${decimoTerceiroDados.undSalario}`,
                      bold: false,
                      fontSize: 8
                    }
                  ]
                }
              ]
            ]
          }
        },
        decimoTerceiroDados.contaBancaria
          ? {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length) {
                    return TABLE_CONFIGS_PDFMAKE.borderWidth
                  }
                  return 0
                },
                vLineWidth: function () {
                  return 0
                },
                hLineColor: function () {
                  return TABLE_CONFIGS_PDFMAKE.borderColor
                }
              },
              table: {
                widths: ['*', '*', 'auto', 'auto', 'auto'],
                body: [
                  [
                    { text: '' },
                    { text: '' },
                    {
                      alignment: 'left',
                      marginBottom: 5,
                      text: [
                        {
                          text: 'Banco: ',
                          bold: true,
                          fontSize: 8
                        },
                        {
                          text: decimoTerceiroDados.banco,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'center',
                      text: [
                        {
                          text: 'Agência: ',
                          bold: true,
                          fontSize: 8
                        },
                        {
                          text: decimoTerceiroDados.bancoAgencia,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: 'Conta: ',
                          bold: true,
                          fontSize: 8
                        },
                        {
                          text: `${decimoTerceiroDados.contaBancaria}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ]
                ]
              }
            }
          : null,
        {
          marginTop: 5,
          marginBottom: decimoTerceiroDados.contaBancaria ? 6 : 3,
          layout: {
            hLineWidth: function () {
              return 0
            },
            vLineWidth: function () {
              return 0
            },
            fillColor: function (i) {
              return i === 0 ? '#f2f6f8' : '#fff'
            }
          },
          table: {
            widths: [250, 100, '*', '*'],
            body: [
              [
                {
                  alignment: 'left',
                  text: [
                    {
                      text: 'DESCRIÇÃO',
                      bold: true,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'left',
                  text: [
                    {
                      text: 'REFERÊNCIA',
                      bold: true,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'right',
                  text: [
                    {
                      text: 'PROVENTOS',
                      bold: true,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'right',
                  text: [
                    {
                      text: 'DESCONTOS',
                      bold: true,
                      fontSize: 8
                    }
                  ]
                }
              ],
              ...eventosDecimoTerceiroOrdered
                .slice(i, i + totalEventosPerPage)
                .map((evento) => [
                  {
                    alignment: 'left',
                    text: [
                      {
                        text: customLabel({
                          codigo: evento.rubricaCodigo,
                          descricao: limitString(evento.rubricaDescricao, 60)
                        }),
                        bold: false,
                        fontSize: 8
                      }
                    ]
                  },
                  {
                    alignment: 'left',
                    text: [
                      {
                        text:
                          evento.natureza === 'INFORMATIVA_DEDUTORA' ||
                          evento.natureza === 'INFORMATIVA'
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : '0,00'
                            : evento.descReferencia
                              ? limitString(evento.descReferencia, 25)
                              : '',
                        bold: false,
                        fontSize: 8
                      }
                    ]
                  },
                  {
                    alignment: 'right',
                    text: [
                      {
                        text:
                          evento.natureza === 'PROVENTO'
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : '0,00'
                            : '',
                        bold: false,
                        fontSize: 8
                      }
                    ]
                  },
                  {
                    alignment: 'right',
                    text: [
                      {
                        text:
                          evento.natureza === 'DESCONTO'
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : '0,00'
                            : '',
                        bold: false,
                        fontSize: 8
                      }
                    ]
                  }
                ]),
              ...ficArray.map(() => [
                {
                  alignment: 'left',
                  text: [
                    {
                      text: ' ',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'left',
                  text: [
                    {
                      text: ' ',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'right',
                  text: [
                    {
                      text: ' ',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'right',
                  text: [
                    {
                      text: ' ',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                }
              ])
            ]
          }
        },
        {
          columns: [
            {
              layout: {
                hLineWidth: function () {
                  return 0
                },
                vLineWidth: function () {
                  return 0
                },
                fillColor: function (i) {
                  return i === 0 ? '#f2f6f8' : '#fff'
                }
              },
              table: {
                widths: [45, 60, 30, 40, 60, 25, 60],
                body: [
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'ENCARGOS',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Base FGTS: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.baseFgts
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatNumber(
                            decimoTerceiroDados.percFgts
                          )}%`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Valor:',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.fgts
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: '',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: '',
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Base INSS: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.baseInss
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatNumber(
                            decimoTerceiroDados.percInss
                          )}%`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Valor:',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.inss
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: '',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: '',
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Base IRRF: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.baseIrrf
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatNumber(
                            decimoTerceiroDados.percIrrf
                          )}%`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Dedução:',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.deduIrrf
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Valor:',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.irrf
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ]
                ]
              }
            },
            {
              width: 150,
              layout: {
                hLineWidth: function () {
                  return 0
                },
                vLineWidth: function () {
                  return 0
                },
                fillColor: function (i) {
                  return i === 0 ? '#f2f6f8' : '#fff'
                }
              },
              table: {
                widths: ['*', '*'],
                body: [
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'TOTAIS',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    { text: '' }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Proventos: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.bruto
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Descontos: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.descontos
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Líquido: ',
                          bold: true,
                          fontSize: 10
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.liquido
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ]
                ]
              }
            }
          ]
        },
        {
          marginTop: 3,
          layout: 'noBorders',
          table: {
            widths: ['*'],
            heights: [20],
            body: [
              [
                {
                  alignment: 'left',
                  text: [
                    {
                      text: decimoTerceiroDados.msgRecibo,
                      bold: false,
                      fontSize: 8
                    }
                  ]
                }
              ]
            ]
          }
        },
        {
          marginTop: 3,
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                {
                  alignment: 'center',
                  text: [
                    {
                      text: 'DECLARO TER RECEBIDO A IMPORTÂNCIA LÍQUIDA DISCRIMINADA NESTE RECIBO.',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                }
              ]
            ]
          }
        },
        {
          columns: [
            {
              width: 150,
              marginTop: 11,
              layout: {
                hLineWidth: function () {
                  return 0
                },
                vLineWidth: function () {
                  return 0
                }
              },
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: '____/____/________',
                          bold: false,
                          fontSize: 12
                        }
                      ]
                    }
                  ]
                ]
              }
            },
            {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length - 1) {
                    return 0.9
                  }
                  return 0
                },
                vLineWidth: function () {
                  return 0
                }
              },
              marginTop: 8,
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: ' ',
                          bold: false,
                          fontSize: 8.5
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'center',
                      text: [
                        {
                          text: `${decimoTerceiroDados.nomeTrabalhador}`,
                          bold: false,
                          fontSize: 8.5
                        }
                      ]
                    }
                  ]
                ]
              }
            }
          ]
        },
        {
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                {
                  alignment: 'right',
                  text: [
                    {
                      text: currentPage,
                      bold: false,
                      fontSize: 10
                    }
                  ]
                }
              ]
            ]
          }
        },
        {
          marginTop: 8,
          marginBottom: 10,
          layout: {
            hLineWidth: function (i, node) {
              if (i === node.table.body.length - 1) {
                return 1.5
              }
              return 0
            },
            hLineColor: function () {
              return '#a0aec0'
            },
            hLineStyle: function () {
              return { dash: { length: 5, space: 3 } }
            },
            vLineWidth: function () {
              return 0
            }
          },
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: '',
                  bold: false,
                  fontSize: 8.5
                }
              ]
            ]
          }
        },
        {
          layout: 'noBorders',
          table: {
            widths: ['auto', '*', 160],
            body: [
              [
                logoEmpregador
                  ? {
                      marginBottom: 5,
                      image: logoEmpregador,
                      fit: ['auto', 35]
                    }
                  : {
                      text: ' ',
                      marginBottom: 20
                    },
                {
                  alignment: 'left',
                  text: [
                    {
                      text: limitString(decimoTerceiroDados.localDenominacao, 60) + '\n',
                      bold: true,
                      fontSize: 10
                    },
                    {
                      text: `${decimoTerceiroDados.localInscricao}`,
                      fontSize: 8,
                      bold: false
                    },
                    {
                      text: `\n ${limitString(decimoTerceiroDados.localEndereco, 80)}`,
                      fontSize: 8,
                      bold: false
                    }
                  ]
                },
                {
                  alignment: 'center',
                  text: [
                    {
                      text: 'Recibo de Pagamento de 13º',
                      fontSize: 8,
                      bold: true
                    },
                    {
                      text: `\n ${
                        parcela === 'PRIMEIRA' ? '1º' : '2º'
                      } PARCELA DE ${ano}`,
                      fontSize: 10,
                      bold: true
                    },
                    {
                      text: `\n Data pagamento: ${formatDate(
                        decimoTerceiroDados.dtPagamento
                      )}`,
                      fontSize: 8
                    }
                  ]
                }
              ]
            ]
          }
        },
        {
          layout: {
            hLineWidth: function (i, node) {
              if (
                i === 0 ||
                (i === node.table.body.length && !decimoTerceiroDados.contaBancaria)
              ) {
                return TABLE_CONFIGS_PDFMAKE.borderWidth
              }
              return 0
            },
            vLineWidth: function () {
              return 0
            },
            hLineColor: function () {
              return TABLE_CONFIGS_PDFMAKE.borderColor
            }
          },
          table: {
            widths: ['*', 'auto', 'auto'],
            body: [
              [
                {
                  marginTop: 5,
                  alignment: 'left',
                  colSpan: 3,
                  text: [
                    {
                      lineHeight: 0.8,
                      text: limitString(`${decimoTerceiroDados.matricula} - ${decimoTerceiroDados.nomeTrabalhador}`, 100),
                      bold: true,
                      fontSize: 10
                    }
                  ]
                },
                { text: '' },
                { text: '' }
              ],
              [
                {
                  lineHeight: !decimoTerceiroDados.contaBancaria ? 1 : 0.7,
                  alignment: 'left',
                  marginBottom: !decimoTerceiroDados.contaBancaria ? 5 : 0,
                  text: [
                    {
                      text: limitString(`${decimoTerceiroDados.cboFuncao} - ${decimoTerceiroDados.descricaoFuncao}`, 60),
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  lineHeight: !decimoTerceiroDados.contaBancaria ? 1 : 0.7,
                  alignment: 'left',
                  text: [
                    {
                      text: 'Admissão: ',
                      bold: true,
                      fontSize: 8
                    },
                    {
                      text: `${formatDate(decimoTerceiroDados.dtAdmissao)}`,
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  lineHeight: !decimoTerceiroDados.contaBancaria ? 1 : 0.7,
                  alignment: 'right',
                  text: [
                    {
                      text: 'Salário Base: ',
                      bold: true,
                      fontSize: 8
                    },
                    {
                      text: `${formatPriceWithoutBRL(
                        decimoTerceiroDados.salarioBase
                      )} ${decimoTerceiroDados.undSalario}`,
                      bold: false,
                      fontSize: 8
                    }
                  ]
                }
              ]
            ]
          }
        },
        decimoTerceiroDados.contaBancaria
          ? {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length) {
                    return TABLE_CONFIGS_PDFMAKE.borderWidth
                  }
                  return 0
                },
                vLineWidth: function () {
                  return 0
                },
                hLineColor: function () {
                  return TABLE_CONFIGS_PDFMAKE.borderColor
                }
              },
              table: {
                widths: ['*', '*', 'auto', 'auto', 'auto'],
                body: [
                  [
                    { text: '' },
                    { text: '' },
                    {
                      alignment: 'left',
                      marginBottom: 5,
                      text: [
                        {
                          text: 'Banco: ',
                          bold: true,
                          fontSize: 8
                        },
                        {
                          text: decimoTerceiroDados.banco,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'center',
                      text: [
                        {
                          text: 'Agência: ',
                          bold: true,
                          fontSize: 8
                        },
                        {
                          text: decimoTerceiroDados.bancoAgencia,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: 'Conta: ',
                          bold: true,
                          fontSize: 8
                        },
                        {
                          text: `${decimoTerceiroDados.contaBancaria}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ]
                ]
              }
            }
          : null,
        {
          marginTop: 5,
          marginBottom: decimoTerceiroDados.contaBancaria ? 6 : 3,
          layout: {
            hLineWidth: function () {
              return 0
            },
            vLineWidth: function () {
              return 0
            },
            fillColor: function (i) {
              return i === 0 ? '#f2f6f8' : '#fff'
            }
          },
          table: {
            widths: [250, 100, '*', '*'],
            body: [
              [
                {
                  alignment: 'left',
                  text: [
                    {
                      text: 'DESCRIÇÃO',
                      bold: true,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'left',
                  text: [
                    {
                      text: 'REFERÊNCIA',
                      bold: true,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'right',
                  text: [
                    {
                      text: 'PROVENTOS',
                      bold: true,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'right',
                  text: [
                    {
                      text: 'DESCONTOS',
                      bold: true,
                      fontSize: 8
                    }
                  ]
                }
              ],
              ...eventosDecimoTerceiroOrdered
                .slice(i, i + totalEventosPerPage)
                .map((evento) => [
                  {
                    alignment: 'left',
                    text: [
                      {
                        text: customLabel({
                          codigo: evento.rubricaCodigo,
                          descricao: limitString(evento.rubricaDescricao, 60)
                        }),
                        bold: false,
                        fontSize: 8
                      }
                    ]
                  },
                  {
                    alignment: 'left',
                    text: [
                      {
                        text:
                          evento.natureza === 'INFORMATIVA_DEDUTORA' ||
                          evento.natureza === 'INFORMATIVA'
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : '0,00'
                            : evento.descReferencia
                              ? limitString(evento.descReferencia, 25)
                              : '',
                        bold: false,
                        fontSize: 8
                      }
                    ]
                  },
                  {
                    alignment: 'right',
                    text: [
                      {
                        text:
                          evento.natureza === 'PROVENTO'
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : '0,00'
                            : '',
                        bold: false,
                        fontSize: 8
                      }
                    ]
                  },
                  {
                    alignment: 'right',
                    text: [
                      {
                        text:
                          evento.natureza === 'DESCONTO'
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : '0,00'
                            : '',
                        bold: false,
                        fontSize: 8
                      }
                    ]
                  }
                ]),
              ...ficArray.map(() => [
                {
                  alignment: 'left',
                  text: [
                    {
                      text: ' ',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'left',
                  text: [
                    {
                      text: ' ',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'right',
                  text: [
                    {
                      text: ' ',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                },
                {
                  alignment: 'right',
                  text: [
                    {
                      text: ' ',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                }
              ])
            ]
          }
        },
        {
          columns: [
            {
              layout: {
                hLineWidth: function () {
                  return 0
                },
                vLineWidth: function () {
                  return 0
                },
                fillColor: function (i) {
                  return i === 0 ? '#f2f6f8' : '#fff'
                }
              },
              table: {
                widths: [45, 60, 30, 40, 60, 25, 60],
                body: [
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'ENCARGOS',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' },
                    { text: '' }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Base FGTS: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.baseFgts
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatNumber(
                            decimoTerceiroDados.percFgts
                          )}%`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Valor:',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.fgts
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: '',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: '',
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Base INSS: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.baseInss
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatNumber(
                            decimoTerceiroDados.percInss
                          )}%`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Valor:',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.inss
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: '',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: '',
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Base IRRF: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.baseIrrf
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatNumber(
                            decimoTerceiroDados.percIrrf
                          )}%`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Dedução:',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.deduIrrf
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Valor:',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.irrf
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ]
                ]
              }
            },
            {
              width: 150,
              layout: {
                hLineWidth: function () {
                  return 0
                },
                vLineWidth: function () {
                  return 0
                },
                fillColor: function (i) {
                  return i === 0 ? '#f2f6f8' : '#fff'
                }
              },
              table: {
                widths: ['*', '*'],
                body: [
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'TOTAIS',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    { text: '' }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Proventos: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.bruto
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Descontos: ',
                          bold: true,
                          fontSize: 8
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.descontos
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: 'Líquido: ',
                          bold: true,
                          fontSize: 10
                        }
                      ]
                    },
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            decimoTerceiroDados.liquido
                          )}`,
                          bold: false,
                          fontSize: 8
                        }
                      ]
                    }
                  ]
                ]
              }
            }
          ]
        },
        {
          marginTop: 3,
          layout: 'noBorders',
          table: {
            widths: ['*'],
            heights: [20],
            body: [
              [
                {
                  alignment: 'left',
                  text: [
                    {
                      text: decimoTerceiroDados.msgRecibo !== null ? decimoTerceiroDados.msgRecibo : '',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                }
              ]
            ]
          }
        },
        {
          marginTop: 3,
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                {
                  alignment: 'center',
                  text: [
                    {
                      text: 'DECLARO TER RECEBIDO A IMPORTÂNCIA LÍQUIDA DISCRIMINADA NESTE RECIBO.',
                      bold: false,
                      fontSize: 8
                    }
                  ]
                }
              ]
            ]
          }
        },
        {
          columns: [
            {
              width: 150,
              marginTop: 11,
              layout: {
                hLineWidth: function () {
                  return 0
                },
                vLineWidth: function () {
                  return 0
                }
              },
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      alignment: 'left',
                      text: [
                        {
                          text: '____/____/________',
                          bold: false,
                          fontSize: 12
                        }
                      ]
                    }
                  ]
                ]
              }
            },
            {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length - 1) {
                    return 0.9
                  }
                  return 0
                },
                vLineWidth: function () {
                  return 0
                }
              },
              marginTop: 8,
              table: {
                widths: ['*'],
                body: [
                  [
                    {
                      alignment: 'right',
                      text: [
                        {
                          text: ' ',
                          bold: false,
                          fontSize: 8.5
                        }
                      ]
                    }
                  ],
                  [
                    {
                      alignment: 'center',
                      text: [
                        {
                          text: `${decimoTerceiroDados.nomeTrabalhador}`,
                          bold: false,
                          fontSize: 8.5
                        }
                      ]
                    }
                  ]
                ]
              }
            }
          ]
        },
        {
          layout: 'noBorders',
          table: {
            widths: ['*'],
            body: [
              [
                {
                  alignment: 'right',
                  text: [
                    {
                      text: currentPage,
                      bold: false,
                      fontSize: 10
                    }
                  ]
                }
              ]
            ]
          }
        }
      ]
    }

    return {
      info: {
        title: `Recibo de Décimo Terceiro de ${nomeTrabalhador} - ${
          parcela === 'PRIMEIRA' ? 'Parcela 1' : 'Parcela 2'
        } de ${ano}`,
        author: 'Calculato Sistemas LTDA',
        keywords: 'Folha Pagamento'
      },
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [18, 17, 18, 18],
      content: pages
    }
  }

  const onOpenWindowPrint = async () => {
    let decimoTerceiroDados = null;
    let logoEmpregador = null;

    const respDecimoTerceiro = await apolloClient.query({
      query: getReciboDecimoTerceiroDataSource,
      variables: {
        idEmpregador: idEmpregador,
        reciboDecimoTerceiroRequest: {
          ano: ano,
          parcela: parcela
        }
      },
      fetchPolicy: "network-only",
    });
    if (respDecimoTerceiro.data) {
      let apiDecimoTerceiro = respDecimoTerceiro.data.reciboDecimoTerceiroDataSource;
      decimoTerceiroDados = {
        ...apiDecimoTerceiro,
        eventosDecimoTerceiro: apiDecimoTerceiro.eventosDecimoTerceiro.map((evfl) => {
          return Object.assign({
            ...evfl,
            edit: false,
            isFixed: false,
            isLoading: false,
            loadingRevertEvento: false,
            tooltipMsg: null,
          });
        }),
      };


      logoEmpregador = await imageUrlToBase64(respDecimoTerceiro.data.reciboDecimoTerceiroDataSource.logoUrl);
    }


    /*
    const resp = await apolloClient.query({
      query: getEmpregadorHeaderPdf,
      variables: { empregadorId: idEmpregador },
      fetchPolicy: "cache-first",
    });
    if (resp.data) {
      empregador = resp.data.empregador;
    }

    const respTrab = await apolloClient.query({
      query: getTrabalhadorPdfRecibo,
      variables: { id: idTrabalhador },
      fetchPolicy: "cache-first",
    });
    if (respTrab.data) {
      trabalhador = respTrab.data.trabalhador;
      nomeTrabalhador = trabalhador.nome;
      local = trabalhador.alocacoes.filter(
        (item) =>
          new Date(formatDateApi(decimoTerceiroDados.dtPagamento)) >=
          new Date(formatDateApi(item.dtInicio))
      );
      local = local.length ? local[local.length - 1] : null;
    }

    */

    if (typeCreate === "newTab") {
      openPdfNewTab(
        docDefinition(
          decimoTerceiroDados,
          logoEmpregador,
        )
      );
    } else {
      downloadPDF(
        `Recibo de Décimo Terceiro de ${nomeTrabalhador} - - ${
          parcela === "PRIMEIRA" ? "Parcela 1" : "Parcela 2"
        } de ${ano}`,
        docDefinition(
          decimoTerceiroDados,
          logoEmpregador
        )
      );
    }
  };

  await onOpenWindowPrint();
};
