<template>
  <div v-if="demonstrativosDisplay.length">
    <div>
      <p class="fs-5 text-dark fw-bold d-md-none">Décimo Terceiro</p>
      <div class="overflow-auto ct-scrollbar-thin-horizontal">
        <div class="accordion-min-width w-100">
          <CtAccordion
            v-for="(accordion, indexAccordion) in demonstrativosDisplay"
            :key="indexAccordion"
            v-model="accordion.visible"
          >
            <template #trigger>
              <div class="d-flex flex-row col-3 me-2">
                <div class="d-flex flex-column">
                  <span href="#" class="fs-6 text-secondary"> Ano </span>
                  <span href="#" class="fs-5 fw-normal text-dark">{{
                    accordion.ano
                  }}</span>
                </div>
              </div>
              <div
                class="d-flex flex-row w-100"
                v-if="accordion.idPrimeiraParcela"
              >
                <div class="d-flex flex-row">
                  <div class="d-flex flex-column me-3">
                    <span href="#" class="fs-6 text-secondary">
                      Primeira Parcela
                    </span>
                    <span href="#" class="fs-5 fw-normal text-dark">{{
                      formatMoney(accordion.liquidoPrimeiraParcela)
                    }}</span>
                  </div>
                  <div class="d-flex flex-row">
                    <DownloadDemonstrativo
                      :id="accordion.idPrimeiraParcela"
                      :periodo="{ ano: accordion.ano, parcela: 'PRIMEIRA' }"
                      tipo-recibo="DECIMO_TERCEIRO"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row w-100" v-else>
                <div class="d-flex flex-row me-8">
                  <div class="d-flex flex-column me-3">
                    <span href="#" class="fs-6 text-secondary">
                      Primeira Parcela
                    </span>
                    <span href="#" class="fs-5 fw-normal text-dark"
                      >Indisponível</span
                    >
                  </div>
                  <div class="d-flex flex-row">
                    <div
                      class="d-flex justify-center align-items-center"
                      @click.stop="() => {}"
                    >
                      <div class="border border-1 border-muted p-1 rounded">
                        <i
                          class="mdi mdi-file-pdf font-size-18 text-muted"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="d-flex flex-row w-100"
                v-if="accordion.idSegundaParcela"
              >
                <div class="d-flex flex-row me-8">
                  <div class="d-flex flex-column me-3">
                    <span href="#" class="fs-6 text-secondary">
                      Segunda Parcela
                    </span>
                    <span href="#" class="fs-5 fw-normal text-dark">{{
                      formatMoney(accordion.liquidoSegundaParcela)
                    }}</span>
                  </div>
                  <div class="d-flex flex-row">
                    <DownloadDemonstrativo
                      :id="accordion.idSegundaParcela"
                      :periodo="{ ano: accordion.ano, parcela: 'SEGUNDA' }"
                      tipo-recibo="DECIMO_TERCEIRO"
                    />
                  </div>
                </div>
              </div>
              <div class="d-flex flex-row w-100" v-else>
                <div class="d-flex flex-row">
                  <div class="d-flex flex-column me-3">
                    <span href="#" class="fs-6 text-secondary">
                      Segunda Parcela
                    </span>
                    <span href="#" class="fs-5 fw-normal text-dark"
                      >Indisponível</span
                    >
                  </div>
                  <div class="d-flex flex-row">
                    <div
                      class="d-flex justify-center align-items-center"
                      @click.stop="() => {}"
                    >
                      <div class="border border-muted p-1 rounded">
                        <i
                          class="mdi mdi-file-pdf font-size-18 text-muted"
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #content>
              <div v-if="accordion.idPrimeiraParcela">
                <ViewQuadroDemonstrativoDecimoTerceiro
                  v-model="accordion.visible"
                  :tipo-parcela="'primeira'"
                  :id-decimo-terceiro="accordion.idPrimeiraParcela"
                />
              </div>
              <div v-if="accordion.idSegundaParcela" class="mt-4">
                <ViewQuadroDemonstrativoDecimoTerceiro
                  v-model="accordion.visible"
                  :tipo-parcela="'segunda'"
                  :id-decimo-terceiro="accordion.idSegundaParcela"
                />
              </div>
            </template>
          </CtAccordion>
        </div>
      </div>
    </div>
    <div class="d-flex mb-2 mt-4 w-100">
      <div class="fw-bold fs-6">
        <span>
          Mostrando {{ demonstrativosDisplay.length }} de
          {{ totalDemonstrativos }}.
        </span>
      </div>
      <div
        v-if="showButtonLoadMore"
        class="text-primary ms-auto text-end similar-pointer d-flex align-items-center"
        @click="loadMore"
      >
        <i
          class="mdi mdi-rotate-3d-variant mdi-18px"
          :class="`${loadingDemonstrativos ? 'mdi-spin' : ''}`"
        ></i>
        <span class="ms-1 fw-bold">Carregar mais registros</span>
      </div>
      <div />
    </div>
  </div>

  <NoDataList
    v-if="!demonstrativosDisplay.length && !loadingDemonstrativos"
    text="Não possui demonstrativo de décimo terceiro."
  />

  <div v-if="!demonstrativosDisplay.length && loadingDemonstrativos">
    <div class="d-flex flex-column w-100 align-items-center justify-center">
      <div class="spinner-border text-primary font-size-10 m-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import CtAccordion from "@/components/CtAccordion/CtAccordion.vue";
import { formatMoney } from "@/composables/useFormatMoney";
import { ref, computed } from "vue";
import { useLazyQuery } from "@vue/apollo-composable";
import { getDemosDecimoTerceiroDisplay } from "@/state/graphql/demonstrativos";
import ViewQuadroDemonstrativoDecimoTerceiro from "./components/ViewQuadroDemonstrativoDecimoTerceiro";
import DownloadDemonstrativo from "./components/DownloadDemonstrativo";
import NoDataList from "./components/NoDataList.vue";

export default {
  name: "DecimoTerceiroDisplayView",
  components: {
    CtAccordion,
    ViewQuadroDemonstrativoDecimoTerceiro,
    DownloadDemonstrativo,
    NoDataList,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const demonstrativosDisplay = ref([]);
    const showButtonLoadMore = ref(false);

    /*
    const demonstrativosDisplay = ref([
      {
        idPrimeiraParcela: "be290f76-9415-4988-8de0-7e5f43cff2d5",
        idSegundaParcela: null,
        liquidoPrimeiraParcela: 999,
        liquidoSegundaParcela: 999,
        visible: false,
        ano: 2022,
      },
    ]);
    */

    /* Queries */
    const {
      result: resultDemonstrativos,
      onResult: onResultDemonstrativos,
      loading: loadingDemonstrativos,
      load: loadDemonstrativos,
      // error: errorOnDemonstrativos,
      //refetch: refetchDemonstrativos,
      fetchMore,
    } = useLazyQuery(getDemosDecimoTerceiroDisplay, () => ({}), {
      fetchPolicy: "network-only",
    });

    onResultDemonstrativos(result => {
      let apiDemonstrativos =
        result.data.decimoTerceiroTrabalhadorDisplayPage.data;
      showButtonLoadMore.value =
        result.data.decimoTerceiroTrabalhadorDisplayPage.cursor;
      demonstrativosDisplay.value = apiDemonstrativos.map(demo => {
        return Object.assign({ ...demo, id: demo.id, visible: false });
      });
    });

    const totalDemonstrativos = computed(() => {
      if (resultDemonstrativos.value) {
        return resultDemonstrativos.value.decimoTerceiroTrabalhadorDisplayPage
          .total;
      }
      return 0;
    });

    function loadMore() {
      fetchMore({
        variables: {
          cursor:
            resultDemonstrativos.value.decimoTerceiroTrabalhadorDisplayPage
              .cursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            ...previousResult,
            decimoTerceiroTrabalhadorDisplayPage: {
              ...previousResult.decimoTerceiroTrabalhadorDisplayPage,
              cursor:
                fetchMoreResult.decimoTerceiroTrabalhadorDisplayPage.cursor,
              data: [
                ...previousResult.decimoTerceiroTrabalhadorDisplayPage.data,
                ...fetchMoreResult.decimoTerceiroTrabalhadorDisplayPage.data,
              ],
            },
          };
        },
      });
    }

    return {
      demonstrativosDisplay,
      formatMoney,
      loadDemonstrativos,
      totalDemonstrativos,
      loadingDemonstrativos,
      showButtonLoadMore,
      loadMore,
    };
  },
  watch: {
    modelValue(nextValue) {
      this.$emit("update:modelValue", nextValue);
      if (nextValue && !this.demonstrativosDisplay.length)
        this.loadDemonstrativos();
    },
  },
};
</script>
