import { formatDayPtBr, formatMonthAndYearPtBr } from '@/composables/useFormatDate'
import { formatMoney } from '@/composables/useFormatMoney'
import { usePdfMake } from '@/composables/usePdfMake'
import { apolloClient } from '@/state/client'
import { getTermoRescisaoSource } from '@/state/graphql/data-sources'

const createArrayVerbasRescisoriasPdfMake = (verbasRescisorias, sizeTotal = 10) => {
  const resultado = []
  let contador = 0

  while (resultado.length < sizeTotal) {
    const novoArray = []
    while (novoArray.length < 6 && contador < verbasRescisorias.length) {
      novoArray.push(
        {
          border: [true, false, true, true],
          text: verbasRescisorias[contador].rubricaDescricao,
          style: 'rubricaDescricao'
          // _maxWidth: 170
        },
        {
          border: [true, false, true, true],
          text: `${formatMoney(verbasRescisorias[contador].valor)}`,
          style: 'rubricaValor'
          // _maxWidth: 60
        }
      )
      contador++
    }
    while (novoArray.length < 6) {
      novoArray.push(
        { text: '', style: 'rubricaDescricao' },
        { text: '', style: 'rubricaValor' }
      )
    }
    resultado.push(novoArray)
  }

  return resultado
}

export const pdfMakeTermoRescisao = async (periodo, idEmpregador, typeCreate) => {
  let docDefinition = (
    termosRescisaoDataSource
  ) => {
  const verbasRescisoriasProventos = createArrayVerbasRescisoriasPdfMake(termosRescisaoDataSource.verbasRescisoriasProventos, 10)
  const verbasRescisoriasDescontos = createArrayVerbasRescisoriasPdfMake(termosRescisaoDataSource.verbasRescisoriasDescontos, 9)

  const layoutRemovePadding = {
    paddingTop: function () {
      return 0.1
    },
    paddingLeft: function () {
      return 2
    },
    paddingBottom: function () {
      return 0
    }
  }
  const heightEventosRubricas = 16

  let termoRescisaoDoc = {
    pageOrientation: 'portrait',
    pageSize: 'A4',
    pageMargins: [35, 25, 35, 25],
    defaultStyle: {
      font: 'Arial'
    },
    images: {
      logoCalculato:
        'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD//gAfQ29tcHJlc3NlZCBieSBqcGVnLXJlY29tcHJlc3P/2wCEAAQEBAQEBAQEBAQGBgUGBggHBwcHCAwJCQkJCQwTDA4MDA4MExEUEA8QFBEeFxUVFx4iHRsdIiolJSo0MjRERFwBBAQEBAQEBAQEBAYGBQYGCAcHBwcIDAkJCQkJDBMMDgwMDgwTERQQDxAUER4XFRUXHiIdGx0iKiUlKjQyNEREXP/CABEIAMAAwAMBIgACEQEDEQH/xAAZAAEAAwEBAAAAAAAAAAAAAAAAAwcICQL/2gAIAQEAAAAA38AAqDFAB02AAU9iYA6dAAKcxQAdOgAFOYoAOnQACnMUAHToABTmKADp0AApzFAB06AAU5igA6dAAKcxQAdOgAFOYoAOnQACnMUAHToABTmKADp0AApzFAB06AAU5igA234AArvNYBJGAAAEsQAAASxAAABLEAAAEsQAAASxAAABLEAAAEsQAAASxAAAB//EABkBAQADAQEAAAAAAAAAAAAAAAABBgcIBf/aAAgBAhAAAAABt19HKgG3X4cpgbdfhymBt1+HKYG3X4cpgbdfhymBt1+FBAtHrCJACJACJACJAD//xAAZAQEAAwEBAAAAAAAAAAAAAAAABQYHBAH/2gAIAQMQAAAAAVSJGggVSIGhAVSIGhAVSIGhAVSIGhAVSIGhAVSIEv4Dh5wAAAAAAAB//8QAKhAAAAIHCQEBAAMAAAAAAAAAAwQAAQIGQFOxBxMXMzZydJKUMAUhIyT/2gAIAQEAAT8A+1ui2mXCGaZWtX+0vVL4aa32Wl8NNb7LS+Gmt9lpfDTW+y0vhprfZaXw01vstL4aa32Wl8NNb7LS+Gmt9l/e3XQA/OLxFuugzHNLViLddBmOaWrEW66DMc0tWIt10GY5pasRbroMxzS1Yi3XQZjmlqxFuugzHNLViLddBmOaWrEW66DMc0tWIt10GY5pasRbroMxzS1Yi3XQZjmlq/Jq3VwlNLZaHO+daY6uDPPedaY6uFPO+daY6uFPO+daY6uFPO+daY6uFPO+daY6uFPO+daY6uFPO+daY6uFPO+daY6uFPO+daY6uFPO+daY6uFPO+daY6uFPO+daY6uFPO+daWoWoOq9jqi/kfkCmGjTRkET+wFbH8MfIbOF3tViBs4Xe1WIGzhd7VYgbOF3tViBs4Xe1WIGzhd7VYgbOF3tViBs4Xe1WIGzhd7VYj/xAArEQABAQMLBQADAAAAAAAAAAABAgAEBQMHEhcwNVN0krHRESAyUnETIkT/2gAIAQIBAT8A75uwDA5XqP6l7BqKfUNRT6hqKfUNRT6iwm7uKUzS9haTd3FKZpewtJu7ilM0vYWk3dxSmaXsLSbu4pTNL2FpN3cUpml7DtE3cCIB/K9axw1XcCxHrWOGq7gWI9axw1XcCxHrWOGq7gWI9axw1XcCxHrWOGq7gWI9axw0Hg7rBHUujoVmTKyv9z1PU9qfFPy0T4p+WifFPy0T4p+Wn//EACMRAAEACwEBAQEAAAAAAAAAAAEAAgQFERUwNFFykQMgEyH/2gAIAQMBAT8A+31djQJE5SJykTlInNB9XY0FR9XY0FR9XY0FR9XY0FR9XY0FR9XY0HzOWvCnEnTXhTiTprwpxJ014U4k6a8KcSdNeFOJOmvCnEaWn0avT9PSEYQ/nyahqGoan//Z'
    },
    styles: {
      title: {
        bold: true,
        fontSize: 13,
        margin: 0,
        color: '#333333',
        lineHeight: 0.9,
        alignment: 'center'
        // marginTop: -1,
      },
      subtitle: {
        fontSize: 9,
        bold: true,
        marginTop: -0.4,
        color: '#050000',
        // lineHeight: 0.9,
        fillColor: '#D3D3D3'
      },
      semiBold: {
        bold: true,
        italics: true
      },
      eventoTitle: {
        fontSize: 10,
        bold: true
        // italics: true,
        // marginTop: -2,
        // lineHeight: 0.9
      },
      rubricaDescricao: {
        fontSize: 7
      },
      rubricaValor: {
        fontSize: 8,
        marginTop: 0,
        alignment: 'right'
      },
      label: {
        fontSize: 7,
        bold: false
      },
      value: {
        fontSize: 10,
        bold: false,
        color: '#050007'
      }
    },
    content: [
      {
        layout: {
          hLineWidth: function () {
            return 0
          },
          vLineWidth: function () {
            return 0
          },
          fillColor: function () {
            return '#cccccc'
          },
          paddingTop: function () {
            return 0
          },
          paddingBottom: function () {
            return 0
          }
        },
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: 'TERMO DE RESCISÃO DE CONTRATO DE TRABALHO',
                style: 'title'
              }
            ]
          ]
        },
        marginBottom: 10
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [110, '*'],
          body: [
            [
              {
                text: 'IDENTIFICAÇÃO DO EMPREGADOR',
                colSpan: 2,
                style: 'subtitle',
                alignment: 'center'
              },
              {}
            ],
            [
              {
                text: [
                  { text: '01-CNPJ/CEI/CPF \n', style: 'label' },
                  { text: termosRescisaoDataSource.localInscricao, style: 'value' }
                ]
              },
              {
                text: [
                  {
                    text: '02 - Razão Social/Nome: \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.localDenominacao, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*', 150],
          body: [
            [
              {
                border: [true, false, false, false],
                text: [
                  {
                    text: '03 - Endereço (logradouro, nº, andar, apartamento) \n',
                    style: 'label'
                  },
                  {
                    text: `${termosRescisaoDataSource.localEndereco}`,
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, false],
                text: [
                  { text: '04 - Bairro \n', style: 'label' },
                  { text: termosRescisaoDataSource.localBairro, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*', 45, 60, 65, 120],
          body: [
            [
              {
                text: [
                  {
                    border: [true, false, true, true],
                    text: '05 - Município \n',
                    style: 'label'
                  },
                  {
                    border: [true, false, true, true],
                    text: termosRescisaoDataSource.localMunicipio,
                    style: 'value'
                  }
                ]
              },
              {
                text: [
                  { text: '06-UF \n', style: 'label' },
                  { text: termosRescisaoDataSource.localUf, style: 'value' }
                ]
              },
              {
                text: [
                  { text: '07-CEP \n', style: 'label' },
                  { text: termosRescisaoDataSource.enderecoCep, style: 'value' }
                ]
              },
              {
                text: [
                  { text: '08-CNAE \n', style: 'label' },
                  { text: termosRescisaoDataSource.localCnae, style: 'value' }
                ]
              },
              {
                text: [
                  { text: '09-CNPJ/CEI Tomador/Obra \n', style: 'label' },
                  { text: termosRescisaoDataSource.tomadorServicoInscricao, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [110, '*'],
          body: [
            [
              {
                border: [true, false, true, true],
                text: 'IDENTIFICAÇÃO DO TRABALHADOR',
                colSpan: 2,
                style: 'subtitle',
                alignment: 'center'
              },
              {}
            ],
            [
              {
                text: [
                  { text: '10-PIS/PASEP \n', style: 'label' },
                  { text: termosRescisaoDataSource.nis, style: 'value' }
                ]
              },
              {
                text: [
                  {
                    text: '11- Nome \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.nomeTrabalhador, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*', 150],
          body: [
            [
              {
                border: [true, false, false, false],
                text: [
                  {
                    text: '12 - Endereço (logradouro, n°, andar, apartamento) \n',
                    style: 'label'
                  },
                  {
                    text: `${termosRescisaoDataSource.enderecoLogradouro}, ${termosRescisaoDataSource.enderecoNumero}, ${termosRescisaoDataSource.enderecoComplemento}`,
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, false],
                text: [
                  { text: '13 - Bairro \n', style: 'label' },
                  { text: termosRescisaoDataSource.enderecoBairro, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [140, 45, 60, '*'],
          body: [
            [
              {
                text: [
                  {
                    border: [false, false, false, false],
                    text: '14 - Município \n',
                    style: 'label'
                  },
                  {
                    border: [false, false, false, false],
                    text: termosRescisaoDataSource.enderecoMunicipio,
                    style: 'value'
                  }
                ]
              },
              {
                text: [
                  { text: '15 - UF \n', style: 'label' },
                  { text: termosRescisaoDataSource.enderecoUf, style: 'value' }
                ]
              },
              {
                text: [
                  { text: '16 - CEP \n', style: 'label' },
                  { text: termosRescisaoDataSource.enderecoCep, style: 'value' }
                ]
              },
              {
                text: [
                  {
                    text: '17 - Carteira de Trabalho (n.º, série, UF) \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.carteiraTrabalho, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [140, 125, '*'],
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '18 - CPF \n',
                    style: 'label'
                  },
                  {
                    text: termosRescisaoDataSource.cpf,
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '19 - Data de nascimento \n',
                    style: 'label'
                  },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtNascimento),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '20 - Nome da mãe \n',
                    style: 'label'
                  },
                  {
                    text: termosRescisaoDataSource.nomeMae,
                    style: 'value'
                  }
                ]
              }
            ]
          ]
        }
      },

      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*'],
          body: [
            [
              {
                border: [true, false, true, true],
                text: 'DADOS DO CONTRATO',
                style: 'subtitle',
                alignment: 'center'
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*'],
          heights: 30,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  { text: '21 - Tipo de Contrato \n', style: 'label' },
                  { text: termosRescisaoDataSource.tipoContratoTrabalho, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*'],
          heights: 30,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  { text: '22 - Causa do Afastamento \n', style: 'label' },
                  {
                    text: termosRescisaoDataSource.motivoRescisao,
                    style: 'value'
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*', 80, 85, 85, 90],
          heights: 18.5,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  { text: '23 Remuneração Mês Ant. \n', style: 'label' },
                  {
                    text: termosRescisaoDataSource.remuneracaoMesAnterior,
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '24 - Data de admissão \n', style: 'label' },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtAdmissao),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '25 - Data do aviso prévio \n', style: 'label' },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtAvisoPrevio),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '26 - Data do afastamento \n', style: 'label' },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtRescisao),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '27 - Cód afastamento \n', style: 'label' },
                  {
                    text: termosRescisaoDataSource.codAfastamento,
                    style: 'value'
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [144, 135, '*'],
          heights: 18,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '28 - Pensão Alimentícia(%) \n',
                    style: 'label'
                  },
                  {
                    text: `${termosRescisaoDataSource.percPensaoAlimenticia}%`,
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '29 - Pensão Alimentícia(%)(Saque FGTS) \n',
                    style: 'label'
                  },
                  {
                    text: `${termosRescisaoDataSource.percPensaoAlimenticiaSaqueFgts}%`,
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '30 - Categoria do trabalhador \n',
                    style: 'label'
                  },
                  {
                    text: termosRescisaoDataSource.categoriaClt,
                    style: 'value'
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [144, '*'],
          heights: 18,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  { text: '31 - Código Sindical \n', style: 'label' },
                  { text: termosRescisaoDataSource.codSindicato, style: 'value' }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '32 - CNPJ e Nome da Entidade Sindical Laboral \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.cnpjSindicato, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*', 60, '*', 60, '*', 60],
          // heights: 18,
          body: [
            [
              {
                text: 'DISCRIMINAÇÃO DAS VERBAS RESCISÓRIAS',
                border: [true, false, true, true],
                colSpan: 6,
                style: 'subtitle',
                alignment: 'center'
              },
              {},
              {},
              {},
              {},
              {}
            ],
            [
              {
                text: 'VERBAS RESCISÓRIAS',
                border: [true, false, true, true],
                colSpan: 6,
                style: 'eventoTitle'
              },
              {},
              {},
              {},
              {},
              {}
            ],
            [
              {
                text: 'Rubrica',
                style: 'eventoTitle'
              },
              {
                text: 'Valor',
                style: 'eventoTitle'
              },
              {
                text: 'Rubrica',
                style: 'eventoTitle'
              },
              {
                text: 'Valor',
                style: 'eventoTitle'
              },
              {
                text: 'Rubrica',
                style: 'eventoTitle'
              },
              {
                text: 'Valor',
                style: 'eventoTitle'
              }
            ]
          ]
        }
      },

      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*', 60, '*', 60, '*', 60],
          heights: heightEventosRubricas,
          body:
          [
            ...verbasRescisoriasProventos.map((evento) => {
              return evento
            }),
            [
              {
                text: '',
                style: 'rubricaDescricao'
              },
              {
                text: '',
                style: 'rubricaValor'
              },
              {
                text: '',
                style: 'rubricaDescricao'
              },
              {
                text: ' ',
                style: 'rubricaValor'
              },
              {
                text: 'TOTAL BRUTO',
                fontSize: 8.7,
                bold: true,
                alignment: 'center',
                fillColor: '#D3D3D3',
                marginTop: 3,
                lineHeight: 1.3
              },
              {
                text: formatMoney(termosRescisaoDataSource.bruto),
                fillColor: '#D3D3D3',
                fontSize: 8.7,
                alignment: 'right',
                bold: true,
                marginTop: 3,
                lineHeight: 1.3
              }
            ]
          ]

        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*', 60, '*', 60, '*', 60],
          body: [
            [
              {
                text: 'DEDUÇÕES',
                border: [true, false, true, true],
                colSpan: 6,
                marginTop: -0.5,
                style: 'eventoTitle'
              },
              {},
              {},
              {},
              {},
              {}
            ],
            [
              {
                text: 'Rubrica',
                style: 'eventoTitle'
              },
              {
                text: 'Valor',
                style: 'eventoTitle'
              },
              {
                text: 'Rubrica',
                style: 'eventoTitle'
              },
              {
                text: 'Valor',
                style: 'eventoTitle'
              },
              {
                text: 'Rubrica',
                style: 'eventoTitle'
              },
              {
                text: 'Valor',
                style: 'eventoTitle'
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*', 60, '*', 60, '*', 60],
          heights: heightEventosRubricas,
          body: [
            ...verbasRescisoriasDescontos.map((evento) => {
              return evento
            }),
            [
              {
                text: '',
                style: 'rubricaDescricao'
              },
              {
                text: '',
                style: 'rubricaValor'
              },
              {
                text: '',
                style: 'rubricaDescricao'
              },
              {
                text: ' ',
                style: 'rubricaValor'
              },
              {
                text: 'TOTAL DAS DEDUÇÕES',
                fontSize: 8.7,
                bold: true,
                alignment: 'center',
                fillColor: '#D3D3D3',
                marginTop: 3,
                lineHeight: 1.3
              },
              {
                text: formatMoney(termosRescisaoDataSource.descontos),
                fillColor: '#D3D3D3',
                fontSize: 8.7,
                alignment: 'right',
                bold: true,
                marginTop: 3,
                lineHeight: 1.3
              }
            ],
            [
              {
                text: '',
                style: 'rubricaDescricao'
              },
              {
                text: '',
                style: 'rubricaValor'
              },
              {
                text: '',
                style: 'rubricaDescricao'
              },
              {
                text: ' ',
                style: 'rubricaValor'
              },
              {
                text: 'VALOR RESCISÓRIO',
                fontSize: 8.7,
                bold: true,
                alignment: 'center',
                fillColor: '#D3D3D3',
                marginTop: 3,
                lineHeight: 1.3
              },
              {
                text: formatMoney(termosRescisaoDataSource.liquido),
                fillColor: '#D3D3D3',
                fontSize: 8.7,
                alignment: 'right',
                bold: true,
                marginTop: 3,
                lineHeight: 1.3
              }
            ]
          ]
        }
      },
      {
        layout: {
          hLineWidth: function () {
            return 0
          },
          vLineWidth: function () {
            return 0
          },
          fillColor: function () {
            return '#cccccc'
          },
          paddingTop: function () {
            return 0
          },
          paddingBottom: function () {
            return 0
          }
        },
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: 'TERMO DE QUITAÇÃO DE RESCISÃO DO CONTRATO DE TRABALHO',
                style: 'title'
              }
            ]
          ]
        },
        marginTop: 10,
        marginBottom: 10
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [110, '*'],
          body: [
            [
              {
                text: 'EMPREGADOR',
                colSpan: 2,
                style: 'subtitle',
                alignment: 'left'
              },
              {}
            ],
            [
              {
                text: [
                  { text: '01-CNPJ/CEI/CPF \n', style: 'label' },
                  { text: termosRescisaoDataSource.localInscricao, style: 'value' }
                ]
              },
              {
                text: [
                  {
                    text: '02 - Razão Social/Nome: \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.localDenominacao, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [110, '*'],
          body: [
            [
              {
                border: [true, false, true, true],
                text: 'TRABALHADOR',
                colSpan: 2,
                style: 'subtitle',
                alignment: 'left'
              },
              {}
            ],
            [
              {
                text: [
                  { text: '10 - PIS/PASEP \n', style: 'label' },
                  { text: termosRescisaoDataSource.nis, style: 'value' }
                ]
              },
              {
                text: [
                  {
                    text: ' 11 - Nome: \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.nomeTrabalhador, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [110, 95, 95, '*'],
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '17 - CTPS (nº, série, UF) \n',
                    style: 'label'
                  },
                  {
                    text: '',
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: ' 18 - CPF \n', style: 'label' },
                  { text: termosRescisaoDataSource.cpf, style: 'value' }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: ' 19 - Data de nascimento \n', style: 'label' },
                  { text: formatDayPtBr(termosRescisaoDataSource.dtNascimento), style: 'value' }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: ' 20 - Nome da mãe \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.nomeMae, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*'],
          body: [
            [
              {
                border: [true, false, true, true],
                text: 'CONTRATO',
                style: 'subtitle',
                alignment: 'left'
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*'],
          heights: 30,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  { text: '22 - Causa do Afastamento \n', style: 'label' },
                  {
                    text: termosRescisaoDataSource.motivoRescisao,
                    style: 'value'
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [100, 100, 100, 100, '*'],
          heights: 18.5,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  { text: '24 - Data de admissão \n', style: 'label' },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtAdmissao),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '25 - Data do aviso prévio \n', style: 'label' },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtAvisoPrevio),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '26 - Data do afastamento \n', style: 'label' },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtRescisao),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '27 - Cód afastamento \n', style: 'label' },
                  {
                    text: termosRescisaoDataSource.codAfastamento,
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '29 - Pensão Alimentícia(%) \n', style: 'label' },
                  {
                    text: `${termosRescisaoDataSource.percPensaoAlimenticia}%`,
                    style: 'value'
                  }
                ]
              }
            ],
            [
              {
                colSpan: 5,
                border: [true, false, true, true],
                text: [
                  { text: '30 - Categoria do trabalhado \n', style: 'label' },
                  {
                    text: termosRescisaoDataSource.categoriaClt,
                    style: 'value'
                  }
                ]
              },
              {},
              {},
              {},
              {}
            ]
          ]
        }
      },
      {
        marginTop: 20,
        style: 'value',
        alignment: 'justify',
        text: 'Foi realizada a rescisão do contrato de trabalho do trabalhador acima qualificado, nos termos do artigo n.°477 da Consolidação das leis do Trabalho(CLT). A Assitência à rescisão prevista no § 1.º do art. n.°477 da CLT não é devida, tendo em vista a duração do contrato de trabalho não ser superior a um ano de seviço e não existir previsão de assistência à rescisão contratual em Acordo ou Convenção Coletiva de Trabalho da categoria a qual pertence o trabalhador.'
      },
      {
        marginTop: 10,
        style: 'value',
        alignment: 'justify',
        text: `
        No dia ______/______/__________ foi realizado, nos termos do art. 23 da Instrução Normativa/SRT n.° 15/2010, o efetivo pagamento das verbas rescisórias espicificadas no corpo do TRCT, o valor líquido de ${formatMoney(termosRescisaoDataSource.liquido)} o qual, devidamente rubricado pelas partes, é parte integrante do presente Termo de Quitação.
        `
      },
      {
        marginTop: 20,
        style: 'value',
        alignment: 'justify',
        text: '_________________/_____, _____de_________________de_________'
      },
      {
        marginTop: 30,
        text: [
          {
            fontSize: 10,
            text: '___________________________________________ \n'
          },
          {
            fontSize: 9,
            text: '150 - Assinatura do Empregador ou Preposto'
          }
        ]
      },
      {
        marginTop: 30,
        layout: {
          paddingTop: function () {
            return 0.1
          },
          paddingLeft: function () {
            return 0
          },
          paddingBottom: function () {
            return 0
          }
        },
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                border: [false, false, false, false],
                text: [
                  {
                    fontSize: 10,
                    text: '___________________________________________ \n'
                  },
                  {
                    fontSize: 9,
                    text: '151 - Assinatura do Trabalhador'
                  }
                ]
              },
              {
                marginLeft: 8,
                border: [false, false, false, false],
                text: [
                  {
                    fontSize: 10,
                    text: '___________________________________________ \n'
                  },
                  {
                    fontSize: 9,
                    text: '152 - Assinatura do Responsável Legal do Trabalhador'
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        marginTop: 230,
        layout: {
          paddingTop: function () {
            return 1.5
          },
          paddingLeft: function () {
            return 2
          },
          paddingBottom: function () {
            return 1.5
          }
        },
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: [
                  {
                    text: '156 - Informações a Caixa: \n',
                    fontSize: 8
                  },
                  {
                    text: 'Para saque do FGTS, se devido, o trabalhador deverá comparecer a uma das agências da CAIXA cinco dias úteis após o pagamento do DAE rescisório, portando a CTPS, documento de identificação com foto e TQRCT.',
                    fontSize: 8
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        marginTop: 10,
        layout: {
          paddingTop: function () {
            return 1.5
          },
          paddingLeft: function () {
            return 0
          },
          paddingBottom: function () {
            return 1.5
          }
        },
        table: {
          widths: ['*'],
          heights: 30,
          body: [
            [
              {
                fillColor: '#D3D3D3',
                border: [false, false, false, false],
                alignment: 'center',
                bold: true,
                fontSize: 8,
                text: `A ASSISTÊNCIA NO ATO DE RESCISÃO CONTRATUAL É GRATUITA.
Pode o trabalhador iniciar ação judicial quanto aos créditos resultantes das relações de trabalho até o limite de dois anos após a extinção do contrato de trabalho (Inc. XXIX, Art. 7º da Constituição Federal/1988).`
              }
            ]
          ]
        }
      },
      {
        layout: {
          hLineWidth: function () {
            return 0
          },
          vLineWidth: function () {
            return 0
          },
          fillColor: function () {
            return '#cccccc'
          },
          paddingTop: function () {
            return 0
          },
          paddingBottom: function () {
            return 0
          }
        },
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: 'TERMO DE HOMOLOGAÇÃO DE RESCISÃO DE CONTRATO DE TRABALHO',
                style: 'title'
              }
            ]
          ]
        },
        marginTop: 10,
        marginBottom: 10
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [110, '*'],
          body: [
            [
              {
                text: 'EMPREGADOR',
                colSpan: 2,
                style: 'subtitle',
                alignment: 'left'
              },
              {}
            ],
            [
              {
                text: [
                  { text: '01-CNPJ/CEI/CPF \n', style: 'label' },
                  { text: termosRescisaoDataSource.localInscricao, style: 'value' }
                ]
              },
              {
                text: [
                  {
                    text: '02 - Razão Social/Nome: \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.localDenominacao, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [110, '*'],
          body: [
            [
              {
                border: [true, false, true, true],
                text: 'TRABALHADOR',
                colSpan: 2,
                style: 'subtitle',
                alignment: 'left'
              },
              {}
            ],
            [
              {
                text: [
                  { text: '10 - PIS/PASEP \n', style: 'label' },
                  { text: termosRescisaoDataSource.nis, style: 'value' }
                ]
              },
              {
                text: [
                  {
                    text: ' 11 - Nome: \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.nomeTrabalhador, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [110, 95, 95, '*'],
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '17 - CTPS (nº, série, UF) \n',
                    style: 'label'
                  },
                  {
                    text: '',
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: ' 18 - CPF \n', style: 'label' },
                  { text: termosRescisaoDataSource.cpf, style: 'value' }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: ' 19 - Data de nascimento \n', style: 'label' },
                  { text: formatDayPtBr(termosRescisaoDataSource.dtNascimento), style: 'value' }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: ' 20 - Nome da mãe \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.nomeMae, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*'],
          body: [
            [
              {
                border: [true, false, true, true],
                text: 'CONTRATO',
                style: 'subtitle',
                alignment: 'left'
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: ['*'],
          heights: 30,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  { text: '22 - Causa do Afastamento \n', style: 'label' },
                  {
                    text: termosRescisaoDataSource.motivoRescisao,
                    style: 'value'
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [100, 100, 100, 100, '*'],
          heights: 18.5,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  { text: '24 - Data de admissão \n', style: 'label' },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtAdmissao),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '25 - Data do aviso prévio \n', style: 'label' },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtAvisoPrevio),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '26 - Data do afastamento \n', style: 'label' },
                  {
                    text: formatDayPtBr(termosRescisaoDataSource.dtRescisao),
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '27 - Cód afastamento \n', style: 'label' },
                  {
                    text: termosRescisaoDataSource.codAfastamento,
                    style: 'value'
                  }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  { text: '29 - Pensão Alimentícia(%) \n', style: 'label' },
                  {
                    text: `${termosRescisaoDataSource.percPensaoAlimenticia}%`,
                    style: 'value'
                  }
                ]
              }
            ],
            [
              {
                colSpan: 5,
                border: [true, false, true, true],
                text: [
                  { text: '30 - Categoria do trabalhado \n', style: 'label' },
                  {
                    text: termosRescisaoDataSource.categoriaClt,
                    style: 'value'
                  }
                ]
              },
              {},
              {},
              {},
              {}
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          widths: [120, '*'],
          heights: 18,
          body: [
            [
              {
                border: [true, false, true, true],
                text: [
                  { text: '31 - Código Sindical \n', style: 'label' },
                  { text: termosRescisaoDataSource.codSindicato, style: 'value' }
                ]
              },
              {
                border: [true, false, true, true],
                text: [
                  {
                    text: '32 - CNPJ e Nome da Entidade Sindical Laboral \n',
                    style: 'label'
                  },
                  { text: termosRescisaoDataSource.cnpjSindicato, style: 'value' }
                ]
              }
            ]
          ]
        }
      },
      {
        marginTop: 20,
        style: 'value',
        alignment: 'justify',
        text: `Foi prestada, gratuitamente, assistência ao trabalhador, nos termos do art. 477, $ 1º, da Consolidação das Leis do Trabalho - CLT, sendo comprovado, neste ato, o efetivo pagamento das verbas rescisórias especificadas do corpo do TRCT, o valor líquido de ${formatMoney(termosRescisaoDataSource.liquido)} o qual, devidademente rubricado pelas partes, é parte integrante do presente Termo de Homologação.`
      },
      {
        marginTop: 10,
        style: 'value',
        alignment: 'justify',
        text: 'As partes assistidas no presente ato de rescisão contratual foram identificadas como legítimas conforme previsto na intituição normativa /SRT n° 15/2010.'
      },
      {
        marginTop: 10,
        style: 'value',
        alignment: 'justify',
        text: 'Fica ressalvo o direito do trabalhador pleitar judicialmente os direitos informados no verso do Termo de Rescisão do Contrato de Trabalho - TRTC Correspondente.'
      },
      {
        marginTop: 20,
        style: 'value',
        alignment: 'justify',
        text: '_________________/_____, _____de_________________de_________'
      },
      {
        marginTop: 30,
        text: [
          {
            fontSize: 10,
            text: '___________________________________________ \n'
          },
          {
            fontSize: 9,
            text: '150 - Assinatura do Empregador ou Preposto'
          }
        ]
      },
      {
        marginTop: 30,
        layout: {
          paddingTop: function () {
            return 0.1
          },
          paddingLeft: function () {
            return 0
          },
          paddingBottom: function () {
            return 0
          }
        },
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                border: [false, false, false, false],
                text: [
                  {
                    fontSize: 10,
                    text: '___________________________________________ \n'
                  },
                  {
                    fontSize: 9,
                    text: '151 - Assinatura do Trabalhador'
                  }
                ]
              },
              {
                marginLeft: 8,
                border: [false, false, false, false],
                text: [
                  {
                    fontSize: 10,
                    text: '___________________________________________ \n'
                  },
                  {
                    fontSize: 9,
                    text: '152 - Assinatura do Responsável Legal do Trabalhador'
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        marginTop: 30,
        layout: {
          paddingTop: function () {
            return 0.1
          },
          paddingLeft: function () {
            return 0
          },
          paddingBottom: function () {
            return 0
          }
        },
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                border: [false, false, false, false],
                text: [
                  {
                    fontSize: 10,
                    text: '___________________________________________ \n'
                  },
                  {
                    fontSize: 9,
                    text: '153 - Assinatura e Carimbo do Assistente'
                  }
                ]
              },
              {
                marginLeft: 8,
                border: [false, false, false, false],
                text: [
                  {
                    fontSize: 10,
                    text: '___________________________________________ \n'
                  },
                  {
                    fontSize: 9,
                    text: '154 - Nome do Orgão Homologador'
                  }
                ]
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        marginTop: 20,
        table: {
          heights: 150,
          widths: ['*'],
          body: [
            [
              {
                text: '155 - Ressalvas \n',
                fontSize: 9
                // border: [true, false, true, true],
              }
            ]
          ]
        }
      },
      {
        layout: layoutRemovePadding,
        table: {
          heights: 30,
          widths: ['*'],
          body: [
            [
              {
                text: '156 - Informações a Caixa: \n',
                fontSize: 9,
                border: [true, false, true, true]
              }
            ]
          ]
        }
      },
      {
        marginTop: 10,
        layout: {
          paddingTop: function () {
            return 1.5
          },
          paddingLeft: function () {
            return 0
          },
          paddingBottom: function () {
            return 1.5
          }
        },
        table: {
          widths: ['*'],
          heights: 30,
          body: [
            [
              {
                fillColor: '#D3D3D3',
                border: [false, false, false, false],
                alignment: 'center',
                bold: true,
                fontSize: 8,
                text: `A ASSISTÊNCIA NO ATO DE RESCISÃO CONTRATUAL É GRATUITA.
Pode o trabalhador iniciar ação judicial quanto aos créditos resultantes das relações de trabalho até o limite de dois anos após a extinção do contrato de trabalho (Inc. XXIX, Art. 7º da Constituição Federal/1988).`
              }
            ]
          ]
        }
      }
      // {
      //   marginBottom: 10,
      //   layout: {
      //     hLineWidth: function () {
      //       return 0
      //     },
      //     vLineWidth: function () {
      //       return 0
      //     },
      //     fillColor: function () {
      //       return '#cccccc'
      //     },
      //     paddingTop: function () {
      //       return 0
      //     },
      //     paddingBottom: function () {
      //       return 0
      //     }
      //   },
      //   table: {
      //     widths: ['*'],
      //     body: [
      //       [
      //         {
      //           text: 'TERMO DE RESCISÃO DE CONTRATO DE TRABALHO',
      //           style: 'title'
      //         }
      //       ]
      //     ]
      //   }
      // }
    ]
  }

  return termoRescisaoDoc
}

  const onOpenWindowPrint = async () => {
    const { openPdfNewTab, downloadPDF } = usePdfMake();

    let termoRescisaoDados = null
    const respRescisao = await apolloClient.query({
          query: getTermoRescisaoSource,
          variables: {
            termoRescisaoRequest: {
              dtRescisao: periodo
            },
            idEmpregador: idEmpregador
          },
          fetchPolicy: "cache-first",
        });
        if (respRescisao.data) {
          termoRescisaoDados = respRescisao.data.termoRescisaoDataSource;
        }
        if (typeCreate === "newTab") {
              openPdfNewTab(docDefinition(termoRescisaoDados));
            } else {
              downloadPDF(
                `Termo de rescisão de ${
                  termoRescisaoDados.nomeTrabalhador
                } - ${formatMonthAndYearPtBr(periodo)}`,
                docDefinition(termoRescisaoDados)
              );
            }
    }

  await onOpenWindowPrint()
}
