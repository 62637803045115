<template>
  <CtExpanded v-model="accordion" title="Anexos">
    <template #content>
      <div class="row">
        <div v-if="anexos.length > 0" class="pb-0 col-6 col-md-12 col-xxl-6">
          <div
            v-for="(anexo, index) in anexos"
            :key="index"
            class="d-flex align-items-center justify-content-between mb-3 w-100 pb-3 border-bottom"
          >
            <span class="me-3">
              {{ anexo.name }}
            </span>
            <button
              type="button"
              class="btn btn-primary waves-effect waves-light me-2"
              title="Baixar Arquivo"
              @click="downloadAnexo(anexo.name, index)"
            >
              <i
                class="bx bx-loader bx-spin font-size-16 align-middle"
                v-if="anexo.isLoading"
              ></i>
              <i
                v-else
                class="mdi mdi-arrow-collapse-down font-size-16 align-middle"
              ></i>
            </button>
          </div>
        </div>
        <div v-else class="p-2">Nenhum anexo disponível</div>
      </div>
    </template>
  </CtExpanded>
</template>
<script>
import { ref } from "vue";
import CtExpanded from "@/components/CtExpanded/CtExpanded.vue";
import { apolloClient } from "@/state/client";
import { getSolicitacaoAnexoUrl } from "@/state/graphql/solicitacoes";

export default {
  components: {
    CtExpanded,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    anexos: {
      type: Array,
      default: () => [],
    },
    solicitacaoId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const accordion = ref(!!props.modelValue);
    const isUploading = ref(false);

    return {
      accordion,
      isUploading,
    };
  },
  methods: {
    async downloadAnexo(nameFile, index) {
      this.$emit("setLoadingAnexos", index, true);
      try {
        const resp = await apolloClient.query({
          query: getSolicitacaoAnexoUrl,
          variables: {
            solicitacaoAnexoUrlId: this.solicitacaoId,
            nomeArquivo: nameFile,
            tipo: "DOWNLOAD", // UPLOAD | DOWNLOAD
          },
          fetchPolicy: "no-cache",
        });
        fetch(resp.data.solicitacaoAnexoUrl)
          .then(response => response.blob())
          .then(blob => {
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = nameFile;
            link.click();
          });
      } catch {
        //
      } finally {
        this.$emit("setLoadingAnexos", index, false);
      }
    },
  },
};
</script>
