<template>
  <div v-if="trabalhador" style="max-height: 400px">
    <div class="card">
      <div class="card-body">
        <div class="d-flex flex-column align-items-center pb-1">
          <div class="upload-foto-trabalhador">
            <div
              tabindex="0"
              class="position-relative foto-trabalhador"
              @keypress.enter="() => {} /*openModalUpload*/"
            >
              <ct-avatar
                :size="90"
                :avatar-url="
                  trabalhador.urlFoto !== null
                    ? trabalhador.urlFoto
                    : 'assets/images/unknow-image.svg'
                "
                :name="trabalhador.nome"
              >
                <div
                  class="hover-foto-trabalhador text-white cursor-pointer"
                  @click.self="() => {} /*openModalUpload*/"
                >
                  <a
                    v-if="trabalhador.urlFoto !== null"
                    class="me-2 focus-on-logo"
                    tabindex="-1"
                    title="Editar Foto"
                    data-bs-toggle="modal"
                    data-bs-target="#modalUploadFotoTrabalhador"
                    @click="() => {} /*openModalUpload*/"
                  >
                    <i class="text-white fas fa-pencil-alt"></i>
                  </a>

                  <a
                    v-if="trabalhador.urlFoto !== null"
                    title="Remover Foto"
                    tabindex="-1"
                    class="focus-on-logo"
                    data-bs-toggle="modal"
                    data-bs-target="#modalConfirmDelete"
                    @click="() => {} /*modalConfirmDelete = true*/"
                  >
                    <i class="text-white fas fa-trash-alt"></i>
                  </a>
                  <a
                    v-if="trabalhador.urlFoto === null"
                    tabindex="-1"
                    class="focus-on-logo"
                    title="Subir Logo"
                    data-bs-toggle="modal"
                    data-bs-target="#modalUploadFotoTrabalhador"
                    @click="() => {} /*openModalUpload*/"
                  >
                    <i class="text-white bx bxs-image-add bx-sm"></i>
                  </a>
                </div>
              </ct-avatar>
            </div>
          </div>
          <span class="mt-2 text-center"
            ><strong>{{ trabalhador.nome }}</strong></span
          >
          <span>{{ trabalhador.matricula }}</span>
        </div>
        <div
          class="nav flex-column nav-pills mt-5 d-block d-sm-none"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/demonstrativos" role="button">
                <a
                  class="nav-link"
                  :class="getCurrentRoute() === 3 ? 'active' : ''"
                  style="font-size: 15px"
                >
                  <span><i class="mdi mdi-playlist-edit"></i></span>
                  <span v-if="getCurrentRoute() === 3" class="ms-1"
                    >Demonstrativos</span
                  >
                </a>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/documentos" role="button">
                <a
                  class="nav-link"
                  :class="getCurrentRoute() === 5 ? 'active' : ''"
                  style="font-size: 15px"
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-paperclip"
                    >
                      <path
                        d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"
                      ></path>
                    </svg>
                  </span>
                  <span v-if="getCurrentRoute() === 5" class="ms-1"
                    >Documentos</span
                  >
                </a>
              </router-link>
            </li>
            <!--
            <li class="nav-item">
              <router-link to="/trabalhador" role="button">
                <a
                  class="nav-link"
                  :class="getCurrentRoute() === 4 ? 'active' : ''"
                  style="font-size: 15px"
                >
                  <span class="position-relative"
                    ><i class="mdi mdi-text-box-outline"></i
                    ><span
                      v-if="statusTrabalhador === 'NAO_ENVIADO'"
                      class="position-absolute top-0 start-100 ms-1 mt-1 translate-middle p-05 bg-danger border border-light rounded-circle"
                    >
                      <span class="visually-hidden">New alerts</span>
                    </span></span
                  >
                </a>
              </router-link>
            </li>
            -->
            <li class="nav-item">
              <router-link to="/solicitacoes" role="button">
                <a
                  class="nav-link"
                  :class="getCurrentRoute() === 6 ? 'active' : ''"
                  style="font-size: 15px"
                >
                  <span>
                    <span class="position-relative"
                      ><i class="dripicons-document-edit mt-1"></i
                      ><span v-if="getCurrentRoute() === 6" class="ms-1"
                        >Solicitações</span
                      ><span
                        v-if="existeSolicitacaoPendente"
                        class="position-absolute top-0 start-100 ms-1 mt-1 translate-middle p-05 bg-danger border border-light rounded-circle"
                      >
                        <span class="visually-hidden">New alerts</span>
                      </span></span
                    >
                  </span>
                </a>
              </router-link>
            </li>
          </ul>
        </div>
        <div
          class="nav flex-column nav-pills mt-5 d-none d-sm-block"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <a
            class="nav-link mb-2"
            :class="getCurrentRoute() === 3 ? 'active' : ''"
          >
            <router-link
              to="/demonstrativos"
              role="button"
              :class="getCurrentRoute() === 3 ? '' : 'text-gray-700'"
            >
              <i
                style="font-size: 15px"
                class="mdi mdi-playlist-edit mt-1 me-2"
              ></i
              ><span>Demonstrativos</span>
            </router-link>
          </a>
          <a
            class="nav-link mb-2"
            :class="getCurrentRoute() === 5 ? 'active' : ''"
          >
            <router-link
              to="/documentos"
              role="button"
              :class="getCurrentRoute() === 5 ? '' : 'text-gray-700'"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="me-2 feather feather-paperclip"
              >
                <path
                  d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"
                ></path></svg
              ><span>Documentos</span>
            </router-link>
          </a>
          <a
            v-if="statusTrabalhador === 'APROVADO'"
            class="nav-link mb-2"
            :class="getCurrentRoute() === 4 ? 'active' : ''"
          >
            <router-link
              to="/trabalhador"
              role="button"
              :class="getCurrentRoute() === 4 ? '' : 'text-gray-700'"
            >
              <i
                style="font-size: 15px"
                class="mdi mdi-text-box-outline mt-1 me-2"
              ></i
              ><span class="position-relative">Informações Pessoais</span>
            </router-link>
          </a>
          <a
            class="nav-link mb-2"
            :class="getCurrentRoute() === 6 ? 'active' : ''"
          >
            <router-link
              to="/solicitacoes"
              role="button"
              :class="getCurrentRoute() === 6 ? '' : 'text-gray-700'"
            >
              <i class="dripicons-document-edit mt-1 me-2"></i>
              <span class="position-relative"
                >Solicitações
                <span
                  v-if="existeSolicitacaoPendente"
                  class="position-absolute top-0 start-100 ms-1 mt-1 translate-middle p-05 bg-danger border border-light rounded-circle"
                >
                  <span class="visually-hidden">New alerts</span>
                </span>
              </span>
            </router-link>
          </a>
        </div>
      </div>
    </div>
    <ModalUploadFotoTrabalhador
      :id-trabalhador="trabalhador.id"
      :on-refetch-trabalhador="onRefetchTrabalhador"
    />
    <ModalConfirmDelete
      title="Tem certeza que deseja excluir?"
      description="A remoção desta imagem irá excluí-la permanentemente do sistema."
      :mutation-delete="mutationDeleteFotoPerfil"
      :loading="deletingFotoPerfil"
    />
  </div>
</template>
<script>
import CtAvatar from "@/components/Avatar/CtAvatar";
import ModalUploadFotoTrabalhador from "./ModalUploadFotoTrabalhador";
import ModalConfirmDelete from "@/components/Modal/ModalConfirmDelete";
import { useMutation, useQuery } from "@vue/apollo-composable";
import { TYPE, useToast } from "vue-toastification";
import Toast from "@/components/Toast/Toast.vue";
import {
  getStatusInfoSocial,
  mutationDeleteTrabalhadorFoto,
} from "@/state/graphql/trabalhador";
import {
  getColorPrimaria,
  getColorSecondary,
} from "@/state/modules/themeColor.store";
import { computed } from "vue";
import { getters, mutations } from "@/state/modules/trabalhador.store";
import { getExisteSolicitacaoPendente } from "@/state/graphql/solicitacoes";
import { useRouter } from "vue-router";
export default {
  components: {
    CtAvatar,
    ModalUploadFotoTrabalhador,
    ModalConfirmDelete,
  },
  props: {
    trabalhador: {
      type: Object,
      default: () => ({ id: null, urlFoto: null }),
    },
    onRefetchTrabalhador: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const {
      mutate: mutationDeleteFotoPerfil,
      loading: deletingFotoPerfil,
      onDone: onDeleteFotoPerfil,
    } = useMutation(mutationDeleteTrabalhadorFoto, () => ({
      variables: { id: props.trabalhador.id },
    }));

    const router = useRouter();

    const statusTrabalhador = computed(() => getters.currentInfoStatus.value);

    const toast = useToast();

    const successToast = () => {
      toast(
        {
          component: Toast,
          props: {
            title: "Solicitação Concluída",
            text: "A sua foto foi excluída com sucesso!",
          },
        },
        { type: TYPE.SUCCESS }
      );
    };

    onDeleteFotoPerfil(() => {
      props.onRefetchTrabalhador();
      successToast();
      $("#modalConfirmDelete").modal("hide");
      //showModalExcluir.value = false
    });

    const { onResult: onResultInfoPessoal } = useQuery(
      getStatusInfoSocial,
      {},
      { fetchPolicy: "network-only" }
    );
    const { result: resultSolicitacaoStatusPendente } = useQuery(
      getExisteSolicitacaoPendente,
      {},
      { fetchPolicy: "network-only" }
    );

    const existeSolicitacaoPendente = computed(() => {
      if (resultSolicitacaoStatusPendente.value) {
        return resultSolicitacaoStatusPendente.value.existeSolicitacaoPendente;
      }
      return false;
    });

    onResultInfoPessoal((resp) => {
      if (resp.data.infoPessoal) {
        mutations.setCurrentStatus(resp.data.infoPessoal.status);
        if (resp.data.infoPessoal.status !== "APROVADO") {
          router.push({ name: "solicitacoes" });
        }
      } else {
        router.push({ name: "solicitacoes" });
        mutations.setCurrentStatus("NAO_ENVIADO");
      }
    });

    return {
      mutationDeleteFotoPerfil,
      deletingFotoPerfil,
      existeSolicitacaoPendente,
      getColorPrimaria,
      getColorSecondary,
      statusTrabalhador,
    };
  },
  methods: {
    getCurrentRoute() {
      if (this.$route.name === "dashboard") {
        return 1;
      } else if (this.$route.name === "demonstrativos") {
        return 3;
      } else if (this.$route.name === "trabalhador") {
        return 4;
      } else if (this.$route.name === "documentos") {
        return 5;
      } else if (this.$route.name === "solicitacoes") {
        return 6;
      } else {
        return 0;
      }
    },
  },
};
</script>
<style lang="scss">
.upload-foto-trabalhador {
  width: 90px;
  height: 90px;
}
.upload-foto-trabalhador .hover-foto-trabalhador {
  position: absolute;
  top: 0;
  opacity: 0;
  z-index: 30;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  background-color: rgba(26, 32, 44, 0.5);
}
.upload-foto-trabalhador {
  .foto-trabalhador:focus .hover-foto-trabalhador,
  .foto-trabalhador:hover .hover-foto-trabalhador {
    opacity: 1;
  }
}
.upload-foto-trabalhador .foto-trabalhador {
  width: 90px;
  height: 90px;
}
.text-gray-700 {
  color: #4a5568;
}
.p-05 {
  padding: 0.2rem;
}
</style>
