<template>
  <div>
    <div v-if="demonstrativosDisplay.length">
      <div>
        <p class="fs-5 text-dark fw-bold d-md-none">Folha Mensal</p>
        <div class="overflow-auto ct-scrollbar-thin-horizontal">
          <div class="accordion-min-width w-100">
            <CtAccordion
              v-for="(accordion, indexAccordion) in demonstrativosDisplay"
              :key="indexAccordion"
              v-model="accordion.visible"
            >
              <template #trigger>
                <div class="d-flex flex-row w-100">
                  <div class="d-flex flex-column col-3">
                    <span href="#" class="fs-6 text-secondary"> Período </span>
                    <span
                      class="fs-5 fw-normal text-dark"
                      v-if="accordion.periodo"
                    >
                      {{ formatMonthAndYearPtBr(accordion.periodo) }}
                    </span>
                  </div>
                  <div class="d-flex flex-column col-3">
                    <span class="fs-6 text-secondary"> Líquido a receber </span>
                    <span
                      class="fs-5 fw-normal text-dark"
                      v-if="accordion.liquido !== null"
                    >
                      {{ formatMoney(accordion.liquido) }}
                    </span>
                  </div>

                  <div class="d-flex flex-row w-100 justify-content-end pe-5">
                    <DownloadDemonstrativo
                      :id="accordion.idFolha"
                      :periodo="accordion.periodo"
                      tipo-recibo="FOLHA"
                    />
                  </div>
                </div>
              </template>
              <template #content>
                <ViewQuadroDemonstrativoFolha
                  v-model="accordion.visible"
                  :id-folha="accordion.idFolha"
                />
              </template>
            </CtAccordion>
          </div>
        </div>
      </div>
      <div class="d-flex mb-2 mt-4 w-100">
        <div class="fw-bold fs-6">
          <span>
            Mostrando {{ demonstrativosDisplay.length }} de
            {{ totalDemonstrativos }}.
          </span>
        </div>
        <div
          v-if="showButtonLoadMore"
          class="text-primary ms-auto text-end similar-pointer d-flex align-items-center"
          @click="loadMore"
        >
          <i
            class="mdi mdi-rotate-3d-variant mdi-18px"
            :class="`${loadingDemonstrativos ? 'mdi-spin' : ''}`"
          ></i>
          <span class="ms-1 fw-bold">Carregar mais registros</span>
        </div>
      </div>
    </div>

    <NoDataList
      v-if="!demonstrativosDisplay.length && !loadingDemonstrativos"
      text="Não possui demonstrativo de folha mensal."
    />

    <div v-if="!demonstrativosDisplay.length && loadingDemonstrativos">
      <div class="d-flex flex-column w-100 align-items-center justify-center">
        <div class="spinner-border text-primary font-size-10 m-1" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CtAccordion from "@/components/CtAccordion/CtAccordion.vue";
import ViewQuadroDemonstrativoFolha from "./components/ViewQuadroDemonstrativoFolha";
import { useQuery } from "@vue/apollo-composable";
import { getDemonstrativosDisplay } from "@/state/graphql/demonstrativos";
import { formatMoney } from "@/composables/useFormatMoney";
import { ref, computed } from "vue";
import { formatMonthAndYearPtBr } from "@/composables/useFormatDate";
import DownloadDemonstrativo from "./components/DownloadDemonstrativo";
import NoDataList from "./components/NoDataList.vue";
export default {
  name: "FolhaDisplayView",
  components: {
    CtAccordion,
    NoDataList,
    ViewQuadroDemonstrativoFolha,
    DownloadDemonstrativo,
  },
  setup() {
    const demonstrativosDisplay = ref([]);
    const showButtonLoadMore = ref(false);

    /* Queries */
    const {
      result: resultDemonstrativos,
      onResult: onResultDemonstrativos,
      loading: loadingDemonstrativos,
      fetchMore,
    } = useQuery(getDemonstrativosDisplay, () => ({}), {
      fetchPolicy: "network-only",
    });

    onResultDemonstrativos(result => {
      let apiDemonstrativos = result.data.folhaTrabalhadorDisplayPage.data;
      showButtonLoadMore.value = result.data.folhaTrabalhadorDisplayPage.cursor;
      demonstrativosDisplay.value = apiDemonstrativos.map(demo => {
        return Object.assign({ ...demo, id: demo.id, visible: false });
      });
    });

    const totalDemonstrativos = computed(() => {
      if (resultDemonstrativos.value) {
        return resultDemonstrativos.value.folhaTrabalhadorDisplayPage.total;
      }
      return 0;
    });

    function loadMore() {
      fetchMore({
        variables: {
          cursor: resultDemonstrativos.value.folhaTrabalhadorDisplayPage.cursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            ...previousResult,
            folhaTrabalhadorDisplayPage: {
              ...previousResult.folhaTrabalhadorDisplayPage,
              cursor: fetchMoreResult.folhaTrabalhadorDisplayPage.cursor,
              data: [
                ...previousResult.folhaTrabalhadorDisplayPage.data,
                ...fetchMoreResult.folhaTrabalhadorDisplayPage.data,
              ],
            },
          };
        },
      });
    }

    return {
      demonstrativosDisplay,
      totalDemonstrativos,
      loadingDemonstrativos,
      formatMoney,
      formatMonthAndYearPtBr,
      showButtonLoadMore,
      loadMore,
    };
  },
};
</script>
<style type="scss">
.similar-pointer {
  cursor: pointer;
}
</style>
