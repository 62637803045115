<template>
  <div id="content-demonstrativo" v-if="folhaDados">
    <table class="table table-hover mb-0">
      <thead>
        <tr>
          <th scope="col">EVENTO</th>
          <th scope="col">REF.</th>
          <th scope="col"><p class="text-end my-0">PROVENTOS</p></th>
          <th scope="col text-end"><p class="text-end my-0">DESCONTOS</p></th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, indexTr) in folhaDados.eventosFolha" :key="indexTr">
          <th scope="row">
            <dropdown-quadro
              class="w-100"
              :fixed="tr.isFixed"
              position="bottom"
              :disabled="tr.origem.value === 'EDITADO'"
              @onEnterDropdown="getTooltip(tr.id, indexTr)"
            >
              <template #trigger>
                <div
                  class="d-flex align-items-center justify-content-start rubrica-content"
                >
                  <span class="cursor-default me-1">{{
                    tr.rubricaDescricao
                  }}</span>
                  <div
                    class="circle-demonstrativo-rosa ms-1"
                    v-if="tr.indBaseInss"
                  >
                    &nbsp;
                  </div>
                  <div
                    class="circle-demonstrativo-amarelo ms-1"
                    v-if="tr.indBaseIrrf"
                  >
                    &nbsp;
                  </div>
                  <div
                    class="circle-demonstrativo-verde ms-1"
                    v-if="tr.indBaseFgts"
                  >
                    &nbsp;
                  </div>
                </div>
              </template>
              <div class="conteudo-dropdown">
                <div v-if="tr.tooltipMsg === null" class="d-block mx-auto my-2">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
                <div v-else>
                  <button
                    class="d-block mx-auto mb-2 btn btn-soft-light waves-effect waves-light"
                    @click="pinDropdown(indexTr)"
                  >
                    <img
                      v-if="tr.isFixed"
                      srcset="@/assets/images/unpin.png"
                      alt="pino_50x50"
                      loading="lazy"
                      width="20"
                      height="20"
                    />
                    <img
                      v-else
                      srcset="@/assets/images/pin.png"
                      alt="pino_50x50"
                      loading="lazy"
                      width="20"
                      height="20"
                    />
                  </button>

                  <p class="mt-2 fw-normal" v-html="tr.tooltipMsg" />
                </div>
              </div>
            </dropdown-quadro>
          </th>
          <td style="max-width: 200px">
            {{ tr.descReferencia }}
          </td>
          <td class="text-end">
            {{ tr.natureza.value === "PROVENTO" ? formatMoney(tr.valor) : "" }}
          </td>
          <td class="text-end">
            {{ tr.natureza.value === "DESCONTO" ? formatMoney(tr.valor) : "" }}
          </td>
          <td class="fw-bolder">&nbsp;</td>
        </tr>
        <tr class="table-light">
          <th scope="row" class="fw-bolder">Total</th>
          <td></td>
          <td class="fw-bolder text-end">
            {{ formatMoney(folhaDados.bruto) }}
          </td>
          <td class="fw-bolder text-end">
            {{ formatMoney(folhaDados.descontos) }}
          </td>
          <td class="fw-bolder">&nbsp;</td>
        </tr>
      </tbody>
    </table>
    <div
      class="d-flex px-3 flex-row col-md-12 justify-content-between border-bottom py-3"
    >
      <span class="fw-bolder">
        Valor do FGTS: {{ formatMoney(folhaDados.fgts) }}
      </span>
      <span class="fw-bolder text-primary">
        Líquido a receber:
        {{ formatMoney(folhaDados.liquido) }}
      </span>
    </div>
    <div class="d-flex justify-content-center pt-3">
      <div class="d-flex justify-content-between">
        <p>Base de Cálculo:</p>
        <div class="d-flex justify-content-start ms-10">
          <div class="pe-2">
            <p class="mt-1 circle-demonstrativo-rosa">&nbsp;</p>
          </div>
          <div>
            <b class="text-gray-700 pr-2">INSS:</b>
            <span>{{ formatMoney(folhaDados.baseInss) }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-start ms-10">
          <div class="pe-2">
            <p class="mt-1 circle-demonstrativo-amarelo">&nbsp;</p>
          </div>
          <div>
            <b class="text-gray-700 pe-2">IRRF</b>
            <span>{{ formatMoney(folhaDados.baseIrrf) }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-start ms-10">
          <div class="pe-2">
            <p class="mt-1 circle-demonstrativo-verde">&nbsp;</p>
          </div>
          <div>
            <b class="text-gray-700 pe-2">FGTS</b>
            <span>{{ formatMoney(folhaDados.baseFgts) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div class="d-flex flex-column w-100 align-items-center justify-center">
      <div class="spinner-border text-primary font-size-10 m-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import MarkdownIt from "markdown-it";
import { formatMoney } from "@/composables/useFormatMoney";
import DropdownQuadro from "./DropdownQuadro";
import { useLazyQuery } from "@vue/apollo-composable";
import {
  getDemonstrativoFolha,
  getInfoCalculo,
} from "@/state/graphql/demonstrativos";
import { apolloClient } from "@/state/client";
import { debounce } from "throttle-debounce";

export default {
  name: "ViewQuadroDemonstrativoFolha",
  components: {
    DropdownQuadro,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    idFolha: {
      type: String,
    },
  },
  setup(props) {
    const folhaDados = ref(null);
    const {
      onResult: onResultFolha,
      load: loadFolha,
      loading: loadingFolha,
    } = useLazyQuery(
      getDemonstrativoFolha,
      { folhaId: props.idFolha },
      { fetchPolicy: "network-only" }
    );

    onResultFolha(result => {
      let apiFolha = result.data.folha;
      folhaDados.value = {
        ...apiFolha,
        eventosFolha: apiFolha.eventosFolha.map(evfl => {
          return Object.assign({
            ...evfl,
            edit: false,
            isFixed: false,
            isLoading: false,
            tooltipMsg: null,
          });
        }),
      };
    });
    /*
    const eventosFolha = ref([
      {
        id: 1,
        evento: "099 - Pró-labore sócio",
        indBaseInss: true,
        indBaseIrrf: true,
        indBaseFgts: true,
        descReferencia: "30 dias",
        valor: 3500,
        isFixed: false,
        tooltipMsg: null,
        natureza: {
          displayName: "Provento",
          value: "PROVENTO",
        },
      },
      {
        id: 2,
        evento: "134 - DSR s/ eventos variáveis",
        indBaseInss: false,
        indBaseIrrf: true,
        indBaseFgts: true,
        descReferencia: "4 dias",
        valor: 461.54,
        isFixed: false,
        tooltipMsg: null,
        natureza: {
          displayName: "Provento",
          value: "PROVENTO",
        },
      },
      {
        id: 3,
        evento: "145 - IRRF",
        descReferencia: "15%",
        valor: 112.45,
        isFixed: false,
        tooltipMsg: null,
        natureza: {
          displayName: "Desconto",
          value: "DESCONTO",
        },
      },
    ]);
    */

    function pinDropdown(indextr) {
      folhaDados.value.eventosFolha[indextr].isFixed =
        !folhaDados.value.eventosFolha[indextr].isFixed;
    }

    return {
      folhaDados,
      pinDropdown,
      formatMoney,
      loadFolha,
      loadingFolha,
    };
  },
  watch: {
    modelValue(nextValue) {
      this.$emit("update:modelValue", nextValue);
      if (nextValue && this.folhaDados === null) this.loadFolha();
    },
  },
  methods: {
    async onGetTooltip(idItem, indextr) {
      if (!this.folhaDados.eventosFolha[indextr].isFixed) {
        this.folhaDados.eventosFolha[indextr].isLoading = true;
        const resp = await apolloClient.query({
          query: getInfoCalculo,
          variables: { idEvento: idItem },
          fetchPolicy: "network-only",
        });
        const convert = new MarkdownIt();
        if (resp.data.infoCalculo !== null) {
          this.folhaDados.eventosFolha[indextr].tooltipMsg =
            await Promise.resolve(
              String(convert.render(resp.data.infoCalculo))
                .replace(/\n+$/, "")
                .replace(/(?:\r\n|\r|\n)/g, "<br>")
            );
        } else {
          this.folhaDados.eventosFolha[indextr].tooltipMsg = "Sem Informação";
        }
        this.folhaDados.eventosFolha[indextr].isLoading = false;
      }
    },
  },
  data() {
    return {
      getTooltip: debounce(800, (idItem, indextr) => {
        if (this.folhaDados.eventosFolha[indextr].tooltipMsg === null) {
          this.onGetTooltip(idItem, indextr);
        }
      }),
    };
  },
};
</script>
<style lang="postcss">
#content-demonstrativo .circle-demonstrativo-rosa {
  width: 8px;
  height: 8px;
  background-color: #d911a1;
  border-radius: 8px;
}
#content-demonstrativo .circle-demonstrativo-amarelo {
  width: 8px;
  height: 8px;
  background-color: #e6d437;
  border-radius: 8px;
}
#content-demonstrativo .circle-demonstrativo-verde {
  width: 8px;
  height: 8px;
  background-color: #0babab;
  border-radius: 8px;
}

.rubrica-content {
  max-width: 250px;
}

@media (min-width: 992px) {
  .rubrica-content {
    max-width: 350px;
  }
}
</style>
