export const useGetMoney = (str, validIsANumber = true) => {
  if (str) {
    let removeSuffix = str.replace(/[' R$.]+/g, "").replace(",", ".");
    if (isNaN(removeSuffix) && validIsANumber) {
      return 0;
    }
    return Number(removeSuffix);
  } else {
    return str;
  }
};
export const formatMoney = (value = 0) => {
  if (value) {
    return `R$ ${value.toLocaleString("pt-br", { minimumFractionDigits: 2 })}`;
  }
  return "R$ 0,00";
};
export const unformattedMoney = value => {
  return parseFloat(value.replace(",", "."));
};

export function formatPrice(price) {
  if (typeof price === "number") {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    }).format(price);
  }
  return "R$ 0,00";
}

export function formatPriceWithoutBRL(price) {
  if (typeof price === "number") {
    return formatPrice(price).replace("R$", "").trim();
  }
  return "0,00";
}
function FormatData(dec, group, neg) {
  this.dec = dec;
  this.group = group;
  this.neg = neg;
}

function formatCodes(locale) {
  // default values
  let dec = ".";
  let group = ",";
  let neg = "-";

  if (
    locale === "us" ||
    locale === "ae" ||
    locale === "eg" ||
    locale === "il" ||
    locale === "jp" ||
    locale === "sk" ||
    locale === "th" ||
    locale === "cn" ||
    locale === "hk" ||
    locale === "tw" ||
    locale === "au" ||
    locale === "ca" ||
    locale === "gb" ||
    locale === "in"
  ) {
    dec = ".";
    group = ",";
  } else if (
    locale === "de" ||
    locale === "vn" ||
    locale === "es" ||
    locale === "dk" ||
    locale === "at" ||
    locale === "gr" ||
    locale === "br"
  ) {
    dec = ",";
    group = ".";
  } else if (
    locale === "cz" ||
    locale === "fr" ||
    locale === "fi" ||
    locale === "ru" ||
    locale === "se"
  ) {
    group = " ";
    dec = ",";
  } else if (locale === "ch") {
    group = "'";
    dec = ".";
  }

  return new FormatData(dec, group, neg);
}
export const formatNumberPriceBrMask = (options, inputValue) => {
  let formatData = formatCodes(options.locale);

  let dec = formatData.dec;
  let group = formatData.group;
  let neg = formatData.neg;

  let validFormat = "0#-,.";

  //   let element = document.getElementById("preco");
  // this ====> seria o value se pa
  //   return element.forEach(function () {
  let text = inputValue;

  // strip all the invalid characters at the beginning and the end
  // of the format, and we'll stick them back on at the end
  // make a special case for the negative sign "-" though, so
  // we can have formats like -document.querySelector23.32
  let prefix = "";
  let negativeInFront = false;
  for (let i = 0; i < options.format.length; i++) {
    if (validFormat.indexOf(options.format.charAt(i)) === -1) {
      prefix = prefix + options.format.charAt(i);
    } else if (i === 0 && options.format.charAt(i) === "-") {
      negativeInFront = true;
      continue;
    } else break;
  }
  let suffix = "";
  for (let i = options.format.length - 1; i >= 0; i--) {
    if (validFormat.indexOf(options.format.charAt(i)) === -1) {
      suffix = options.format.charAt(i) + suffix;
    } else break;
  }

  options.format = options.format.substring(prefix.length);
  options.format = options.format.substring(
    0,
    options.format.length - suffix.length
  );

  // now we need to convert it into a number
  while (text.indexOf(group) > -1) text = text.replace(group, "");
  let number = new Number(text.replace(dec, ".").replace(neg, "-"));

  // special case for percentages
  if (suffix === "%") number = number * 100;

  let returnString = "";

  let decimalValue = number % 1;
  if (options.format.indexOf(".") > -1) {
    let decimalPortion = dec;
    let decimalFormat = options.format.substring(
      options.format.lastIndexOf(".") + 1
    );
    let decimalString = new String(decimalValue.toFixed(decimalFormat.length));
    decimalString = decimalString.substring(decimalString.lastIndexOf(".") + 1);
    for (let i = 0; i < decimalFormat.length; i++) {
      if (decimalFormat.charAt(i) === "#" && decimalString.charAt(i) !== "0") {
        decimalPortion += decimalString.charAt(i);
        continue;
      } else if (
        decimalFormat.charAt(i) === "#" &&
        decimalString.charAt(i) === "0"
      ) {
        let notParsed = decimalString.substring(i);
        if (notParsed.match("[1-9]")) {
          decimalPortion += decimalString.charAt(i);
          continue;
        } else {
          break;
        }
      } else if (decimalFormat.charAt(i) === "0") {
        decimalPortion += decimalString.charAt(i);
      }
    }
    returnString += decimalPortion;
  } else number = Math.round(number);

  let ones = Math.floor(number);
  if (number < 0) ones = Math.ceil(number);

  let onePortion = "";
  if (ones === 0) {
    onePortion = "0";
  } else {
    // find how many digits are in the group
    let onesFormat = "";
    if (options.format.indexOf(".") === -1) onesFormat = options.format;
    else onesFormat = options.format.substring(0, options.format.indexOf("."));
    let oneText = new String(Math.abs(ones));
    let groupLength = 9999;
    if (onesFormat.lastIndexOf(",") !== -1) {
      groupLength = onesFormat.length - onesFormat.lastIndexOf(",") - 1;
    }
    let groupCount = 0;
    for (let i = oneText.length - 1; i > -1; i--) {
      onePortion = oneText.charAt(i) + onePortion;

      groupCount++;

      if (groupCount === groupLength && i !== 0) {
        onePortion = group + onePortion;
        groupCount = 0;
      }
    }
  }
  returnString = onePortion + returnString;

  // handle special case where negative is in front of the invalid
  // characters
  if (number < 0 && negativeInFront && prefix.length > 0) {
    prefix = neg + prefix;
  } else if (number < 0) {
    returnString = neg + returnString;
  }

  if (!options.decimalSeparatorAlwaysShown) {
    if (returnString.lastIndexOf(dec) === returnString.length - 1) {
      returnString = returnString.substring(0, returnString.length - 1);
    }
  }
  returnString = prefix + returnString + suffix;

  return returnString;
  //   });
};

export const formatInputPrice = inputValue => {
  let dec = 2;
  let sDec = "";
  let i = 1;
  for (i = 1; i <= dec; i++) {
    sDec += "0";
  }

  let result = "";
  if (inputValue) {
    result = formatNumberPriceBrMask(
      {
        format: "#,###." + sDec,
        decimalSeparatorAlwaysShown: false,
        locale: "br",
      },
      inputValue
    );
    if (isNaN(parseInt(result))) {
      result = "";
    }

    let novoValor = result.replace(/[^\d.,-]/g, "").replace(/^(.+)-/g, "$1");
    if (novoValor !== result) {
      return novoValor;
    }
    return novoValor;
  } else {
    return "0,00";
  }
};


export const formatNumber = (value = 0) => {
  if (value) {
    return `${value.toLocaleString('pt-br', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
  }
  return value
}

/**
 ---- How to use  ----
 ```vue
const salario = 'R$ 3.400,40';
formatPrice(salario)

```

```js
import { useGetMoney, formatMoney } from '@composables/useFormatMoney'
```

See More in `useFormatMoney.spec.js`
*/
