import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "@/utils/vfs_fonts.js";

export const usePdfMake = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  pdfMake.fonts = {
    Arial: {
      normal: "Arial-Regular.ttf",
      bold: "Arial-Bold.ttf",
      italics: "Arial-Italic.ttf",
      bolditalics: "Arial-BoldItalic.ttf",
    },
    Roboto: {
      normal: "Roboto-Regular.ttf",
      bold: "Roboto-Bold.ttf",
      italics: "Roboto-Italic.ttf",
      bolditalics: "Roboto-BoldItalic.ttf",
    },
    Inconsolata: {
      normal: "Inconsolata-Regular.ttf",
      bold: "Inconsolata-Bold.ttf",
      italics: "Inconsolata-SemiBold.ttf",
      bolditalics: "Inconsolata-SemiBold.ttf",
    },
    NotoSans: {
      normal: "NotoSans-Regular.ttf",
      bold: "NotoSans-Bold.ttf",
      italics: "NotoSans-Italic.ttf",
      bolditalics: "NotoSans-SemiBold.ttf",
    },
  };

  const previewPDFOnIframe = (idIframe, docDefinition) => {
    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    pdfDocGenerator.getDataUrl(dataUrl => {
      const iframe = document.getElementById(idIframe);
      iframe.src = dataUrl;
    });
  };

  const downloadPDF = (name, docDefinition) => {
    pdfMake.createPdf(docDefinition).download(`${name}.pdf`);
  };

  const openPdfNewTab = docDefinition => {
    return pdfMake.createPdf(docDefinition).open();
  };

  return { previewPDFOnIframe, downloadPDF, openPdfNewTab };
};

/* HOW TO USE
import { usePdfMake } from '@composables/usePdfMake'

setup() {
  const { previewPDFOnIframe: previewPDF, downloadPDF } = usePdfMake()
  return {  previewPDF, downloadPDF }
}
*/
