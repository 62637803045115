<template>
  <div
    v-if="avatarUrl"
    :style="getImageStyle"
    :title="name"
    :class="classes.root"
  />
  <div
    v-else
    :style="getLetterStyle"
    class="letter"
    :title="name"
    :class="classes.root"
  >
    <span>
      {{ initials }}
    </span>
  </div>
  <slot />
</template>

<script>
import { computed } from "vue";
const avatarColors = [
  "#DA7657",
  "#6ADA57",
  "#5784DA",
  "#003DA7",
  "#3a0ca3",
  "#AA57DA",
  "#DA5757",
  "#DA5792",
  "#57DACA",
  "#57A5DA",
];
const getInitials = name => {
  let [firstName, lastName] = name.split(" ");

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`;
  } else {
    return firstName.charAt(0);
  }
};
export default {
  name: "CtAvatar",
  props: {
    avatarUrl: {
      type: String,
      default: null,
    },
    name: {
      type: [String, Array],
      default: "",
    },
    size: {
      type: Number,
      default: 32,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    classes: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const getImageStyle = computed(() => ({
      display: "inline-block",
      width: `${props.size}px`,
      height: `${props.size}px`,
      "border-radius": props.rounded ? "100%" : "3px",
      backgroundImage: `url('${props.avatarUrl}')`,
      backgroundPosition: "50% 50%",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
    }));
    const getColorFromName = computed(
      () =>
        avatarColors[
          props.name.toLocaleLowerCase().charCodeAt(0) % avatarColors.length
        ]
    );
    const getLetterStyle = computed(() => ({
      width: `${props.size}px`,
      height: `${props.size}px`,
      background: getColorFromName.value,
      fontSize: `${Math.round(props.size / 3)}px`,
    }));
    const initials = computed(() => getInitials(props.name));
    return {
      getImageStyle,
      getLetterStyle,
      initials,
    };
  },
};
</script>

<style lang="postcss">
.letter {
  @apply bg-primary inline-block rounded-full uppercase text-white;
}
.letter > span {
  @apply flex items-center justify-center h-full;
}
</style>
