<template>
  <div class="card" v-if="getters.currentInfoStatus.value === 'PENDENTE'">
    <div class="card-body d-flex">
      <img srcset="@/assets/images/complete-form.svg" />
      <div class="d-flex flex-column ms-3">
        <span class="fs-5"
          ><b>Suas informações foram enviadas com sucesso.</b></span
        >
        <span
          >Por favor aguarde a aprovação do gestor, você será notificado por
          e-mail assim que a verificação for concluída</span
        >
      </div>
    </div>
  </div>
  <div class="card" v-if="dataOptions.dataOptions">
    <div class="card-header align-items-center d-flex">
      <div class="flex-shrink-0">
        <ul
          class="nav justify-content-start nav-tabs-custom rounded card-header-tabs"
          role="tablist"
        >
          <!-- :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor};`" -->
          <li class="nav-item">
            <a
              class="nav-link active"
              data-bs-toggle="tab"
              href="#home2"
              role="tab"
            >
              <span>Informações Pessoais</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-bs-toggle="tab"
              href="#profile2"
              role="tab"
            >
              <span>Outras Informações</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="tab-content text-muted">
        <div class="tab-pane active" id="home2" role="tabpanel">
          <div class="row">
            <div>
              <SectionTitle title="Nascimento" class="mb-6" />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">País Nascimento*</label>
              <Multiselect
                :classes="{
                  container: v$.paisNascimento.$error
                    ? 'multiselect input-is-invalid'
                    : 'multiselect',
                }"
                v-model="paisNascimento"
                :filterResults="false"
                :resolveOnLoad="false"
                noOptionsText="A Lista esta Vazia"
                noResultsText="Nenhum resultado encontrado"
                :searchable="true"
                :minChars="3"
                trackBy="value"
                :can-clear="false"
                :clearOnSearch="true"
                :object="true"
                label="label"
                mode="single"
                :disabled="workerIsApproved"
                :delay="0"
                :resolve-on-load="false"
                placeholder="Selecione o País de Nacionalidade"
                @close="v$.paisNascimento.$touch"
                :canDeselect="false"
                :closeOnSelect="true"
                :options="
                  async query => {
                    return await getPaises(query);
                  }
                "
              />

              <div v-show="v$.paisNascimento.$error" class="error-text">
                O Campo <b>País Nascimento</b> é de preenchimento obrigatório.
              </div>
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">Nacionalidade*</label>
              <Multiselect
                :classes="{
                  container: v$.nacionalidade.$error
                    ? 'multiselect input-is-invalid'
                    : 'multiselect',
                }"
                v-model="nacionalidade"
                :filterResults="false"
                :resolveOnLoad="false"
                noOptionsText="A Lista esta Vazia"
                noResultsText="Nenhum resultado encontrado"
                :searchable="true"
                :minChars="3"
                :delay="300"
                :object="true"
                :canClear="false"
                :closeOnSelect="true"
                :disabled="workerIsApproved"
                placeholder="Selecione o País de Nacionalidade"
                @close="v$.nacionalidade.$touch"
                :canDeselect="false"
                :options="
                  async query => {
                    return await getPaises(query);
                  }
                "
              />
              <div v-show="v$.nacionalidade.$error" class="error-text">
                O campo <b>Nacionalidade</b> é de preenchimento obrigatório.
              </div>
            </div>

            <div
              class="mb-6 col-lg-4"
              v-if="paisNascimento.value !== 105 || nacionalidade.value !== 105"
            >
              <label class="form-label">Imigrante*</label>
              <Multiselect
                :classes="{
                  container: v$.form.indImigrante.$error
                    ? 'multiselect input-is-invalid'
                    : 'multiselect',
                }"
                @close="v$.form.indImigrante.$touch"
                v-model="form.indImigrante"
                :filterResults="false"
                :resolveOnLoad="false"
                noOptionsText="A Lista esta Vazia"
                noResultsText="Nenhum resultado encontrado"
                :searchable="true"
                :minChars="3"
                :disabled="workerIsApproved"
                :delay="300"
                :canClear="false"
                :closeOnSelect="true"
                :canDeselect="false"
                trackBy="value"
                label="displayName"
                placeholder="Selecione uma Opção"
                :options="dataOptions.dataOptions.indicativoImigrante"
              />
              <div v-show="v$.form.indImigrante.$error" class="error-text">
                O campo <b>Imigrante</b> é de preenchimento obrigatório.
              </div>
            </div>

            <div
              v-if="form.indImigrante !== 'NAO_IMIGRANTE'"
              class="mb-6 col-lg-4"
            >
              <label class="form-label">Condição Imigrante</label>
              <Multiselect
                :classes="{ container: 'multiselect' }"
                v-model="form.codImigrante"
                :filterResults="false"
                :resolveOnLoad="false"
                :disabled="workerIsApproved"
                noOptionsText="A Lista esta Vazia"
                noResultsText="Nenhum resultado encontrado"
                :closeOnSelect="true"
                :canClear="false"
                trackBy="value"
                label="displayName"
                placeholder="Selecione uma Opção"
                :options="codImigrantes"
              />
            </div>
            <div class="mb-6 col-lg-4">
              <label class="form-label">Raça*</label>
              <Multiselect
                v-model="form.raca"
                :closeOnSelect="true"
                trackBy="value"
                :disabled="workerIsApproved"
                :caret="true"
                :canClear="false"
                :canDeselect="false"
                label="displayName"
                :options="dataOptions.dataOptions.raca"
              />
            </div>
            <div class="mb-6 col-lg-4">
              <label class="form-label">Sexo*</label>
              <Multiselect
                v-model="form.sexo"
                :classes="{
                  container: v$.form.sexo.$error
                    ? 'multiselect input-is-invalid'
                    : 'multiselect',
                }"
                @close="v$.form.sexo.$touch"
                trackBy="value"
                :canClear="false"
                :closeOnSelect="true"
                :canDeselect="false"
                :disabled="workerIsApproved"
                label="displayName"
                placeholder="Selecione uma Opção"
                :options="dataOptions.dataOptions.sexo"
              />
              <div v-show="v$.form.sexo.$error" class="error-text">
                O campo <b>sexo</b> é de preenchimento obrigatório.
              </div>
            </div>
            <div>
              <SectionTitle title="Residência" class="mb-6" />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">País*</label>
              <Multiselect
                :classes="{
                  container: v$.endPais.$error
                    ? 'multiselect input-is-invalid'
                    : 'multiselect',
                }"
                @close="v$.endPais.$touch"
                v-model="endPais"
                :filterResults="false"
                :disabled="workerIsApproved"
                :resolveOnLoad="false"
                noOptionsText="A Lista esta Vazia"
                noResultsText="Nenhum resultado encontrado"
                :searchable="true"
                :canDeselect="false"
                :object="true"
                :minChars="3"
                :delay="300"
                :canClear="false"
                :closeOnSelect="true"
                placeholder="Selecione o País"
                :options="
                  async query => {
                    return await getPaises(query);
                  }
                "
              />
              <div v-show="v$.endPais.$error" class="error-text">
                O campo <b>País</b> é de preenchimento obrigatório.
              </div>
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">{{
                endPais.value === 105 ? "CEP*" : "ZIP*"
              }}</label>
              <input
                :class="
                  v$.form.endCep.$error
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                type="text"
                :disabled="workerIsApproved"
                :data-maska="showMaskCep"
                v-maska
                :placeholder="
                  endPais.value === 105
                    ? 'Informe o CEP'
                    : 'Informe o Código ZIP'
                "
                @blur="v$.form.endCep.$touch"
                v-model="form.endCep"
              />
              <div v-show="v$.form.endCep.$error" class="error-text">
                O Campo <b>{{ endPais.value === 105 ? "CEP" : "ZIP" }}</b> é de
                preenchimento obrigatório.
              </div>
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">UF*</label>
              <Multiselect
                :classes="{
                  container: v$.form.endUf.$error
                    ? 'multiselect input-is-invalid'
                    : 'multiselect',
                }"
                @close="v$.form.endUf.$touch"
                v-model="form.endUf"
                trackBy="value"
                :closeOnSelect="true"
                :disabled="workerIsApproved"
                :canDeselect="false"
                :searchable="true"
                placeholder="Selecione um Estado"
                :canClear="false"
                label="displayName"
                :options="dataOptions.dataOptions.unidadeFederativa"
              />

              <div v-show="v$.form.endUf.$error" class="error-text">
                O campo <b>UF</b> é de preenchimento obrigatório.
              </div>
            </div>
            <div class="mb-6 col-lg-8">
              <label class="form-label">Logradouro*</label>
              <input
                :class="
                  v$.form.endLogradouro.$error
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                type="text"
                placeholder="Logradouro"
                :disabled="workerIsApproved"
                v-model="form.endLogradouro"
                @blur="v$.form.endLogradouro.$touch"
              />
              <div v-show="v$.form.endLogradouro.$error" class="error-text">
                O Campo <b>Logradouro</b> é de preenchimento obrigatório.
              </div>
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">Número*</label>
              <input
                :class="
                  v$.form.endNumero.$error
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                @blur="v$.form.endNumero.$touch"
                type="text"
                :disabled="workerIsApproved"
                placeholder="Informe o Número"
                v-model="form.endNumero"
              />
              <div v-show="v$.form.endNumero.$error" class="error-text">
                O Campo <b>Número</b> é de preenchimento obrigatório.
              </div>
            </div>

            <div class="mb-6 col-lg-6">
              <label class="form-label">Complemento</label>
              <input
                class="form-control"
                type="text"
                :disabled="workerIsApproved"
                placeholder="Informe o Complemento"
                v-model="form.endComplemento"
              />
            </div>

            <div class="mb-6 col-lg-6">
              <label class="form-label">Bairro*</label>
              <input
                :class="
                  v$.form.endBairro.$error
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                @blur="v$.form.endBairro.$touch"
                type="text"
                placeholder="Informe o seu Bairro"
                :disabled="workerIsApproved"
                v-model="form.endBairro"
              />
              <div v-show="v$.form.endBairro.$error" class="error-text">
                O Campo <b>Bairro</b> é de preenchimento obrigatório.
              </div>
            </div>

            <div class="mb-6 col-lg-6">
              <label class="form-label">Nome do Município*</label>
              <input
                :class="
                  v$.form.endMunicipioNome.$error
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                @blur="v$.form.endMunicipioNome.$touch"
                class="form-control"
                :disabled="workerIsApproved"
                type="text"
                :placeholder="
                  workerIsApproved
                    ? 'Não informado'
                    : 'Informe o Nome do Município'
                "
                v-model="form.endMunicipioNome"
              />
              <div v-show="v$.form.endMunicipioNome.$error" class="error-text">
                O Campo <b>Nome do Município</b> é de preenchimento obrigatório.
              </div>
            </div>

            <div class="mb-6 col-lg-6">
              <label class="form-label">Código do Município*</label>
              <input
                :class="
                  v$.form.endMunicipio.$error
                    ? 'form-control is-invalid'
                    : 'form-control'
                "
                @blur="v$.form.endMunicipio.$touch"
                type="text"
                :disabled="workerIsApproved"
                placeholder="Informe o Código do Município"
                v-model="form.endMunicipio"
              />
              <div v-show="v$.form.endMunicipio.$error" class="error-text">
                O Campo <b>Código do Município</b> é de preenchimento
                obrigatório.
              </div>
            </div>
            <div>
              <SectionTitle title="Atribuições" class="mb-6" />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">Estado Civil*</label>
              <Multiselect
                v-model="form.estadoCivil"
                :closeOnSelect="true"
                trackBy="value"
                :caret="true"
                :disabled="workerIsApproved"
                :canDeselect="false"
                :canClear="false"
                label="displayName"
                :options="dataOptions.dataOptions.estadoCivil"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label for="example-text-input" class="form-label"
                >Nome Social</label
              >
              <input
                class="form-control"
                type="text"
                :disabled="workerIsApproved"
                :placeholder="
                  workerIsApproved ? 'Não informado' : 'Informe o Nome Social'
                "
                v-model="form.nomeSocial"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">Grau de Instrução*</label>
              <Multiselect
                v-model="form.grauInstrucao"
                trackBy="value"
                :disabled="workerIsApproved"
                :closeOnSelect="true"
                :canDeselect="false"
                :canClear="false"
                label="displayName"
                :options="dataOptions.dataOptions.grauInstrucao"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label for="example-text-input" class="form-label"
                >Carteira CNH</label
              >
              <input
                class="form-control"
                :disabled="workerIsApproved"
                type="text"
                :placeholder="workerIsApproved ? 'Não informado' : 'Número CNH'"
                v-model="form.cnh"
              />
            </div>
            <div class="mb-6 col-lg-2">
              <label for="example-text-input" class="form-label"
                >Categoria CNH</label
              >
              <input
                class="form-control"
                :disabled="workerIsApproved"
                type="text"
                :placeholder="
                  workerIsApproved ? 'Não informado' : 'Categoria CNH'
                "
                v-model="form.cnhCategoria"
              />
            </div>
            <div class="mb-6 col-lg-2">
              <label for="example-text-input" class="form-label"
                >Venc. CNH</label
              >
              <Datepicker
                v-model="form.cnhVencimento"
                menuClassName="mt-8"
                :autoApply="true"
                :textInput="true"
                :enableTimePicker="false"
                format="dd/MM/yyyy"
                :disabled="workerIsApproved"
                locale="pt-BR"
                :placeholder="
                  workerIsApproved ? 'Não informado' : 'Vencimento CNH'
                "
                :format-locale="ptBR"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">CNH UF</label>
              <Multiselect
                v-model="form.cnhUf"
                trackBy="value"
                :disabled="workerIsApproved"
                :searchable="true"
                placeholder="Selecione um Estado"
                :canClear="false"
                label="displayName"
                :options="dataOptions.dataOptions.unidadeFederativa"
              />
            </div>

            <div>
              <SectionTitle title="Possui Alguma Deficiência?" class="mb-6" />
            </div>

            <div class="row">
              <div class="mb-6 col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="defFisica"
                    v-model="form.defFisica"
                    :disabled="workerIsApproved"
                  />
                  <label class="form-check-label" for="defFisica">
                    Física
                  </label>
                </div>
              </div>

              <div class="mb-6 col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :disabled="workerIsApproved"
                    id="defVisual"
                    v-model="form.defVisual"
                  />
                  <label class="form-check-label" for="defVisual">
                    Visual
                  </label>
                </div>
              </div>

              <div class="mb-6 col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :disabled="workerIsApproved"
                    id="defMental"
                    v-model="form.defMental"
                  />
                  <label class="form-check-label" for="defMental">
                    Mental
                  </label>
                </div>
              </div>

              <div class="mb-6 col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :disabled="workerIsApproved"
                    id="defAuditiva"
                    v-model="form.defAuditiva"
                  />
                  <label class="form-check-label" for="defAuditiva">
                    Auditiva
                  </label>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="mb-6 col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    :disabled="workerIsApproved"
                    id="defIntelectual"
                    v-model="form.defIntelectual"
                  />
                  <label class="form-check-label" for="defIntelectual">
                    Intelectual
                  </label>
                </div>
              </div>
              <div class="mb-6 col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    :disabled="workerIsApproved"
                    type="checkbox"
                    id="defReabilitado"
                    v-model="form.defReabilitado"
                  />
                  <label class="form-check-label" for="defReabilitado">
                    Reabilitado
                  </label>
                </div>
              </div>
              <div class="mb-6 col-md-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="defCota"
                    :disabled="workerIsApproved"
                    v-model="form.defCota"
                  />
                  <label class="form-check-label" for="defCota"> Cota </label>
                </div>
              </div>
            </div>
          </div>

          <div
            class="d-flex flex-column-reverse flex-md-row align-items-center mt-7"
            v-if="!workerIsApproved"
          >
            <button
              type="submit"
              class="btn btn-primary waves-effect waves-light"
              @click="onSave"
            >
              <i
                class="bx bx-loader bx-spin font-size-16 align-middle me-2"
                v-if="isSaving"
              ></i>
              Salvar
            </button>
            <span class="ms-2 mb-3 mb-md-0 text-center">
              Por favor, verifique a aba outras informações antes de salvar.
            </span>
          </div>
          <div class="mt-3" v-if="workerIsApproved">
            *Informações apenas para visualização, algo errado?
            <router-link
              :to="{
                query: { solicitacao: 'outros' },
                name: 'nova-solicitacao',
              }"
              style="
                color: #0d6efd;
                cursor: pointer;
                background: none;
                border: none;
              "
            >
              Solicite correção ao RH
            </router-link>
          </div>
        </div>
        <div class="tab-pane" id="profile2" role="tabpanel">
          <div v-if="!workerIsApproved || dependentes.length > 0">
            <SectionTitle title="Dependentes" class="mb-6" />
          </div>
          <div class="row">
            <div v-if="dependentes.length > 0">
              <div
                v-for="(dep, index) in dependentes"
                :key="index"
                class="row item-dependente text-center text-md-start rounded mb-4 pt-3 pb-3 pt-md-2 pb-md-2"
              >
                <div class="col-md-6 mb-4 mb-md-0">
                  <h5
                    class="text-uppercase font-size-10 text-dark"
                    v-if="dep.tipoDependente || dep.tipoDependente.displayName"
                  >
                    <!-- {{ getDisplayName(dep.tipoDependente) }} -->
                    {{ dep.tipoDependente.displayName }}
                  </h5>
                  <p class="mb-0">
                    {{ dep.nome }}
                  </p>
                </div>
                <div class="col-md-3 mb-4 mb-md-0">
                  <h5 class="text-uppercase font-size-10 text-dark">Idade</h5>
                  <p class="mb-0">{{ currentAge(dep.dtNascimento) }} anos</p>
                </div>
                <div class="col-md-3 mb-4 mb-md-0">
                  <h5 class="text-uppercase font-size-10 text-dark">Sexo</h5>
                  <p class="mb-0">{{ dep.sexo.displayName }}</p>
                </div>
                <!-- <div class="col-md-2 text-md-end">
                  <button
                    @click="openModalDeleteDependente(index)"
                    :disabled="workerIsApproved"
                    class="btn btn-exclude-dependente font-size-14"
                  >
                    <i class="bx bx-trash"></i>
                  </button>
                </div> -->
              </div>
            </div>
            <div class="col-md-3 mb-6" v-if="!workerIsApproved">
              <button
                type="button"
                class="btn btn-outline-primary btn-with-icon waves-effect waves-light"
                data-bs-toggle="modal"
                data-bs-target="#modalCreateDependente"
                :disabled="workerIsApproved"
              >
                <i class="bx bx-plus font-size-16 align-middle me-2"></i>
                Dependente
              </button>
            </div>
            <!-- sample modal content -->
            <div class="grid-col-6">
              <div
                id="modalCreateDependente"
                class="modal fade"
                tabindex="-1"
                aria-labelledby="myModalLabel"
                aria-hidden="true"
                data-bs-scroll="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="myModalLabel">
                        Novo Dependente
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <p>
                        Todos os dados dos dependentes poderão ser visualizados
                        posteriormente no perfil do trabalhador.
                        <!-- TODO -->
                        <a
                          href="https://google.com.br"
                          class="ct-link"
                          target="_blank"
                          >Saiba mais!</a
                        >
                      </p>
                      <div class="mb-3">
                        <label class="form-label">Tipo de Dependente*</label>
                        <Multiselect
                          v-model="dependente.tipoDependente"
                          trackBy="value"
                          placeholder="Selecione uma Opção"
                          :canClear="false"
                          label="displayName"
                          :options="dataOptions.dataOptions.tipoDependente"
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Nome*</label>
                        <input
                          class="form-control"
                          type="text"
                          :placeholder="
                            workerIsApproved
                              ? 'Não informado'
                              : 'Informe o Nome do Dependente'
                          "
                          v-model="dependente.nome"
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Data de Nascimento*</label>
                        <!-- type="text" -->
                        <!-- id="datepicker-basic" -->
                        <Datepicker
                          v-model="dependente.dtNascimento"
                          :autoApply="true"
                          menuClassName="mt-calendar"
                          :textInput="true"
                          :enableTimePicker="false"
                          format="dd/MM/yyyy"
                          locale="pt-BR"
                          placeholder="Data de Nascimento"
                          :format-locale="ptBR"
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-label">Sexo*</label>
                        <Multiselect
                          v-model="dependente.sexo"
                          trackBy="value"
                          :canClear="false"
                          :closeOnSelect="true"
                          :canDeselect="false"
                          :disabled="workerIsApproved"
                          label="displayName"
                          placeholder="Selecione uma Opção"
                          :options="dataOptions.dataOptions.sexo"
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-label">CPF</label>
                        <input
                          v-maska
                          data-maska="###.###.###-##"
                          class="form-control"
                          type="text"
                          placeholder="Informe o CPF do Dependente"
                          v-model="dependente.cpf"
                        />
                      </div>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary waves-effect"
                        data-bs-dismiss="modal"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor}; color: ${getTextMenuColor};`"
                        class="btn btn-primary waves-effect waves-light"
                        @click="saveAndResetDependente"
                      >
                        Salvar
                      </button>
                    </div>
                  </div>
                  <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
              </div>
              <!-- /.modal -->

              <div
                id="modalDeleteDependente"
                class="modal fade"
                tabindex="-1"
                aria-hidden="true"
                data-bs-scroll="true"
              >
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">Excluir Dependente</h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      Esta ação não pode ser desfeita. Isso excluirá
                      permanentemente o dependente que você criou.
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary waves-effect"
                        data-bs-dismiss="modal"
                      >
                        Cancelar
                      </button>
                      <button
                        type="button"
                        :style="`background-color: ${getBGMenuColor}; border-color: ${getBGMenuColor}; color: ${getTextMenuColor};`"
                        class="btn btn-primary waves-effect waves-light"
                        @click="deleteDependente"
                      >
                        Excluir
                      </button>
                    </div>
                  </div>
                  <!-- /.modal-content -->
                </div>
                <!-- /.modal-dialog -->
              </div>
            </div>
          </div>
          <div>
            <SectionTitle title="Outras Informações" class="mb-6" />
          </div>
          <div class="row">
            <div class="mb-6 col-lg-4">
              <label class="form-label">Nome da Mãe</label>
              <input
                class="form-control"
                type="text"
                :placeholder="
                  workerIsApproved
                    ? 'Não informado'
                    : 'Informe o Nome da Sua Mãe'
                "
                :disabled="workerIsApproved"
                v-model="form.nomeMae"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">NIS</label>
              <input
                class="form-control"
                type="text"
                :placeholder="
                  workerIsApproved ? 'Não informado' : 'Informe o NIS'
                "
                :disabled="workerIsApproved"
                v-model="form.nis"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">Número de Reservista</label>
              <input
                class="form-control"
                type="text"
                :placeholder="
                  workerIsApproved
                    ? 'Não informado'
                    : 'Informe o Número de Reservista'
                "
                :disabled="workerIsApproved"
                v-model="form.numeroReservista"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">Título de Eleitor</label>
              <input
                class="form-control"
                type="text"
                :placeholder="
                  workerIsApproved
                    ? 'Não informado'
                    : 'Informe o Título de Eleitor'
                "
                :disabled="workerIsApproved"
                v-model="form.tituloEleitor"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">CTPS Número</label>
              <input
                class="form-control"
                type="text"
                :placeholder="
                  workerIsApproved ? 'Não informado' : 'Informe a o Número CTPS'
                "
                :disabled="workerIsApproved"
                v-model="form.ctpsNumero"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">CTPS Série</label>
              <input
                class="form-control"
                type="text"
                :placeholder="
                  workerIsApproved ? 'Não informado' : 'Informe a Série da CTPS'
                "
                :disabled="workerIsApproved"
                v-model="form.ctpsSerie"
              />
            </div>

            <div class="mb-6 col-lg-4">
              <label class="form-label">CTPS UF</label>
              <Multiselect
                v-model="form.ctpsUf"
                trackBy="value"
                :placeholder="
                  workerIsApproved ? 'Não informado' : 'Selecione uma Opção'
                "
                :canClear="false"
                label="displayName"
                :disabled="workerIsApproved"
                :options="dataOptions.dataOptions.unidadeFederativa"
              />
            </div>
          </div>

          <div class="form-group mt-7" v-if="!workerIsApproved">
            <button
              type="submit"
              class="btn btn-primary waves-effect waves-light"
              @click="onSave"
            >
              <i
                class="bx bx-loader bx-spin font-size-16 align-middle me-2"
                v-if="isSaving"
              ></i>
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import SectionTitle from "@/components/Title/SectionTitle.vue";
import {
  getDataOptions,
  getInfoPessoal,
  getOptionsPaises,
  mutationAddInfoPessoal,
} from "@/state/graphql/trabalhador.js";
import { useQuery, useMutation } from "@vue/apollo-composable";
import { ref, computed, reactive } from "vue";
import Multiselect from "@vueform/multiselect";
import { apolloClient } from "@/state/client.js";
import { vMaska } from "maska";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
const { cpf } = require("cpf-cnpj-validator");
import { format, differenceInYears } from "date-fns";
import { ptBR } from "date-fns/locale";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import Toast from "@/components/Toast/Toast.vue";
import {
  getBGMenuColor,
  getTextMenuColor,
} from "@/state/modules/themeColor.store";
import { useToast, TYPE } from "vue-toastification";
import { errorMsg } from "@/state/modules/error.store";
import { getters, mutations } from "@/state/modules/trabalhador.store";

export default {
  components: {
    Multiselect,
    SectionTitle,
    Datepicker,
    Toast,
  },
  directives: { maska: vMaska },
  setup() {
    const files = ref([]);
    const upload = ref("");
    const accept =
      "image/*, text/html, text/plain, text/csv, application/pdf, application/zip, application/x-7z-compressed, application/x-rar-compressed, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/msword";
    const extensions = "pdf,zip,7z,rar,csv,xlsx,ppt,txt,gif,jpg,jpeg,png,webp";
    const filesPermitted = ref(
      /\.(gif|jpg|jpeg|png|webp|pdf|txt|csv|zip|rar|xlsx|docx|doc)$/i
    );
    const drop = ref(true);
    const size = ref(1024 * 1024 * 10);
    const isUploading = ref(false);
    // const currentUrlUpload = ref(null);
    const documentos = ref([]);

    const dependente = reactive({
      tipoDependente: "CONJUGE",
      nome: null,
      dtNascimento: null,
      sexo: null,
      cpf: null,
    });
    const dependentes = ref([]);
    const paisNascimento = ref({ value: 105, label: "Brasil" });
    const nacionalidade = ref({ value: 105, label: "Brasil" });
    const endPais = ref({ value: 105, label: "Brasil" });
    const hasDataTrabalhador = ref(false);
    const form = reactive({
      nomeSocial: null,
      sexo: null,
      raca: "NAO_INFORMADO",
      indImigrante: "NAO_IMIGRANTE",
      codImigrante: null,
      estadoCivil: "SOLTEIRO",
      grauInstrucao: "MEDIO_COMPLETO",
      cnh: null,
      cnhCategoria: null,
      cnhVencimento: null,
      cnhUf: null,
      endUf: null,
      endCep: null,
      endLogradouro: null,
      endNumero: null,
      endComplemento: null,
      endBairro: null,
      endMunicipio: null,
      endMunicipioNome: null,
      defFisica: false,
      defVisual: false,
      defMental: false,
      defAuditiva: false,
      defIntelectual: false,
      defReabilitado: false,
      defCota: false,
      nomeMae: "",
      nis: null,
      numeroReservista: null,
      tituloEleitor: null,
      ctpsNumero: null,
      ctpsSerie: null,
      ctpsUf: null,
    });
    const isSaving = ref(false);
    const indexDependente = ref(0);
    const workerIsApproved = computed(() => {
      return getters.currentInfoStatus.value === "APROVADO" ||
        getters.currentInfoStatus.value === "PENDENTE"
        ? true
        : false;
    });
    // const workerIsApproved = ref("APROVADO");
    const toast = useToast();

    const {
      result: resultTrabalhadorDataOptions,
      loading: loadingDataOptions,
    } = useQuery(getDataOptions, null, {
      context: { clientName: "endpoint1" },
      fetchPolicy: "network-only",
    });

    // const {
    //   loading: loadingDocumentos,
    //   onResult: onResultDocumentos,
    //   refetch: refetchDocumentos,
    // } = useQuery(getDocumentos, null, {
    //   context: { clientName: "endpoint1" },
    //   fetchPolicy: "network-only",
    // });

    // onResultDocumentos((dados) => {
    //   if (dados.data.documentos) {
    //     documentos.value = dados.data.documentos.map((doc) => {
    //       return {
    //         id: doc.id,
    //         dtAlteracao: doc?.dtAlteracao,
    //         grupoDocumento: doc?.grupoDocumento,
    //         nome: doc?.nome,
    //         tamanho: doc?.tamanho,
    //         tipoArquivo: doc?.tipoArquivo,
    //         url: doc?.url,
    //         visualizacoes: doc?.visualizacoes,
    //         downloadingDocument: false,
    //       };
    //     });
    //   }
    // });

    // const selectedDocument = reactive({ id: "0", nome: null });

    // function openModalExcludeDocumento(documento) {
    //   $("#modalDeleteDocumento").modal("show");
    //   selectedDocument.id = documento.id;
    //   selectedDocument.nome = documento.nome;
    // }

    // const {
    //   mutate: mutationDeleteDocumento,
    //   loading: deletingTrabalhadorDocumento,
    // } = useMutation(mutationDeleteTrabalhadorDocumento);

    // async function deleteDocumento() {
    //   try {
    //     await mutationDeleteDocumento({
    //       deleteTrabalhadorDocumentoId: selectedDocument.id,
    //     });
    //     await refetchDocumentos();
    //     toast(
    //       {
    //         component: Toast,
    //         props: {
    //           title: "O arquivo foi excluído!",
    //           text: `O arquivo: ${selectedDocument.nome} foi excluído permanentemente.`,
    //         },
    //       },
    //       { type: TYPE.SUCCESS }
    //     );
    //     // await root.$ctoast.success({ title: 'O arquivo foi excluído!', message: `O arquivo: ${selectedDocument.nome} foi excluído permanentemente.` })
    //     $("#modalDeleteDocumento").modal("hide");
    //   } catch {
    //     errorToast("Não foi possível excluir", errorMsg.value);
    //   }
    // }

    // function uploadDocument(nome) {
    //   selectedDocument.nome = nome;
    //   let input = upload.value.$el.querySelector("input");
    //   input.onclick = null;
    //   input.click();
    // }

    // const documentos = computed(() => {
    //   if (resultDocumentos.value) {
    //     return resultDocumentos.value.documentos;
    //   }
    //   return [];
    // });

    const getPaises = async searchParam => {
      try {
        const resp = await apolloClient.query({
          query: getOptionsPaises,
          variables: { search: searchParam ? searchParam : "Brasil" },
          context: { clientName: "endpoint1" },
          fetchPolicy: "no-cache",
        });
        if (resp.data.paises) {
          return resp.data.paises.map(pais => {
            return {
              value: pais.codigo,
              label: pais.nome,
            };
          });
        } else {
          return [
            {
              value: 105,
              label: "Brasil",
            },
          ];
        }
      } catch (error) {
        return Promise.reject(error);
      }
    };

    const dataOptions = computed(
      () => resultTrabalhadorDataOptions.value ?? []
    );

    const showMaskCep = computed(() => {
      return endPais.value.value === 105
        ? "#####-###"
        : "#######################";
    });

    const codImigrantes = computed(() => {
      if (form.indImigrante === "POR_PRAZO_INDETERMINADO") {
        // form.codImigrante = null;
        return resultTrabalhadorDataOptions.value.dataOptions.condicaoImigrante.filter(
          codImigra => {
            return (
              codImigra.value !== "REFUGIADO" &&
              codImigra.value !== "DEPENDENTE_CONSULAR"
            );
          }
        );
      }
      return (
        resultTrabalhadorDataOptions.value.dataOptions?.condicaoImigrante ?? []
      );
    });

    async function fetchCep(cepValue) {
      if (cepValue.length === 9 && endPais.value.value === 105) {
        let search = cepValue.replace("-", "");
        const url = `https://viacep.com.br/ws/${search}/json/`;
        fetch(url);
        const dados = await fetch(url);
        const endereco = await dados.json();
        // eslint-disable-next-line no-prototype-builtins
        if (endereco.hasOwnProperty("erro")) {
          console.error("erro");
        } else {
          preencherEndereco(endereco);
        }
      }
    }

    function preencherEndereco(endereco) {
      form.endBairro = endereco.bairro;
      form.endLogradouro = endereco.logradouro;
      form.endMunicipioNome = endereco.localidade ? endereco.localidade : null;
      form.endMunicipio = endereco.ibge;
      form.endComplemento = endereco.complemento ? endereco.complemento : null;
      const currentUf = dataOptions.value.dataOptions.unidadeFederativa.filter(
        uf => uf.value === endereco.uf
      );
      form.endUf = currentUf[0].value;
    }

    function clearEndereco() {
      Object.assign(form, {
        endNumero: null,
        // endUf: null,
        endMunicipioNome: null,
        endLogradouro: null,
        endMunicipio: null,
        endComplemento: null,
        endBairro: null,
      });
    }

    const formatDay = day => {
      if (day) {
        return format(new Date(day), "yyyy-MM-dd");
      }
      return null;
    };

    const datePtBr = day => {
      if (day) {
        return format(new Date(day), "dd/MM/yyyy");
      }
      return null;
    };

    const currentAge = date => {
      return differenceInYears(new Date(), new Date(date));
    };

    const getDisplayName = tipoDepValue => {
      let findDependente = dataOptions.value.dataOptions.tipoDependente.find(
        depedente => depedente.value === tipoDepValue
      );
      if (findDependente.displayName) {
        return findDependente.displayName;
      }
    };

    const { mutate: addInfoPessoal } = useMutation(mutationAddInfoPessoal);

    const { onResult: onResultInfoPessoal } = useQuery(
      getInfoPessoal,
      {},
      { fetchPolicy: "no-cache" }
    );

    onResultInfoPessoal(resp => {
      if (resp.data.infoPessoal) {
        hasDataTrabalhador.value = true;
        Object.assign(form, {
          nomeSocial: resp.data.infoPessoal.nomeSocial,
          codImigrante: resp.data.infoPessoal.codImigrante?.value,
          endCep: resp.data.infoPessoal.endCep,
          indImigrante: resp.data.infoPessoal.indImigrante?.value,
          sexo: resp.data.infoPessoal.sexo.value,
          raca: resp.data.infoPessoal.raca.value,
          estadoCivil: resp.data.infoPessoal.estadoCivil.value,
          nacionalidade: resp.data.infoPessoal.nacionalidade.value,
          endUf: resp.data.infoPessoal.endUf?.value,
          grauInstrucao: resp.data.infoPessoal.grauInstrucao.value,
          endComplemento: resp.data.infoPessoal.endComplemento,
          endMunicipioNome: resp.data.infoPessoal.endMunicipioNome,
          endLogradouro: resp.data.infoPessoal.endLogradouro,
          endMunicipio: resp.data.infoPessoal.endMunicipio,
          endBairro: resp.data.infoPessoal.endBairro,
          endNumero: resp.data.infoPessoal.endNumero,
          cnh: resp.data.infoPessoal.cnh,
          cnhCategoria: resp.data.infoPessoal.cnhCategoria,
          cnhVencimento: resp.data.infoPessoal.cnhVencimento,
          cnhUf: resp.data.infoPessoal.cnhUf?.value,
          nomeMae: resp.data.infoPessoal.nomeMae,
          nis: resp.data.infoPessoal.nis,
          numeroReservista: resp.data.infoPessoal.numeroReservista,
          tituloEleitor: resp.data.infoPessoal.tituloEleitor,
          ctpsNumero: resp.data.infoPessoal.ctpsNumero,
          ctpsUf: resp.data.infoPessoal.ctpsUf?.value,
          ctpsSerie: resp.data.infoPessoal.ctpsSerie,
          defCota: resp.data.infoPessoal.defCota,
          defFisica: resp.data.infoPessoal.defFisica,
          defIntelectual: resp.data.infoPessoal.defIntelectual,
          defMental: resp.data.infoPessoal.defMental,
          defReabilitado: resp.data.infoPessoal.defReabilitado,
          defVisual: resp.data.infoPessoal.defVisual,
          defAuditiva: resp.data.infoPessoal.defAuditiva,
        });
        // delete form["idTrabalhador"];
        dependentes.value = resp.data.infoPessoal.dependentes;
        // mutations.setCurrentStatus(resp.data.infoPessoal.status);
        // workerIsApproved.value = resp.data.infoPessoal.status;

        paisNascimento.value = {
          value: resp.data.infoPessoal.paisNascimento.codigo,
          label: resp.data.infoPessoal.paisNascimento.nome,
        };
        endPais.value = {
          value: resp.data.infoPessoal.endPais.codigo,
          label: resp.data.infoPessoal.endPais.nome,
        };
        nacionalidade.value = {
          value: resp.data.infoPessoal.nacionalidade.codigo,
          label: resp.data.infoPessoal.nacionalidade.nome,
        };
      } else {
        hasDataTrabalhador.value = false;
      }
    });

    // function uploadDocument() {
    //   let input = upload.value.$el.querySelector("input");
    //   input.onclick = null;
    //   input.click();
    // }

    // async function putToS3(fileObject, presignedUrl) {
    //   const requestOptions = {
    //     method: "PUT",
    //     headers: {
    //       "Content-Type": fileObject.type,
    //     },
    //     body: fileObject,
    //   };
    //   const response = await fetch(presignedUrl, requestOptions);
    //   return await response;
    // }

    // async function getUrlDocumento(nome) {
    //   await apolloClient
    //     .query({
    //       query: getUploadDocumentoUrl,
    //       variables: { nomeArquivo: nome },
    //       context: { clientName: "endpoint1" },
    //       fetchPolicy: "network-only",
    //     })
    //     .then((resp) => {
    //       let url = resp.data.uploadDocumentoUrl;
    //       currentUrlUpload.value = resp.data.uploadDocumentoUrl;
    //       return url;
    //       // documentos.value[indexDoc].visualizacoes += 1
    //     })
    //     .catch(() => {
    //       // root.$ctoast.error({ title: 'Não foi possível baixar', message: 'Desculpe, houve um erro na requisição, por favor tente novamente.' })
    //     });
    // }

    // const { mutate: mutationUploadDocumento, loading: uploadingDocumento } =
    //   useMutation(mutationCreateTrabalhadorDocumento);

    // const uploadDocumento = async (file) => {
    //   isUploading.value = true;
    //   try {
    //     let newTrabalhadorDocumento = {
    //       nome: file.name,
    //       tipoArquivo: file.type,
    //       tamanho: file.size,
    //       grupoDocumento: "PESSOAIS",
    //     };
    //     await mutationUploadDocumento({
    //       newTrabalhadorDocumento: newTrabalhadorDocumento,
    //     });
    //     await getUrlDocumento(file.name);
    //     await putToS3(file, currentUrlUpload.value);
    //     await refetchDocumentos();
    //     toast(
    //       {
    //         component: Toast,
    //         props: {
    //           title: `Solicitação Concluída`,
    //           text: "O(s) arquivo(s) foram salvos",
    //         },
    //       },
    //       { type: TYPE.SUCCESS }
    //     );
    //   } catch {
    //     errorToast(
    //       "Não foi possível salvar",
    //       "Desculpe, houve um erro na requisição, por favor tente novamente."
    //     );
    //   } finally {
    //     isUploading.value = false;
    //   }
    // };

    // async function downloadDocumento(documento, indexDoc) {
    //   documentos.value[indexDoc].downloadingDocument = true;
    //   await apolloClient
    //     .query({
    //       query: getTrabalhadorDocumentoUrl,
    //       variables: { trabalhadorDocumentoUrlId: documento.id },
    //       context: { clientName: "endpoint1" },
    //       fetchPolicy: "network-only",
    //     })
    //     .then((resp) => {
    //       let url = resp.data.trabalhadorDocumentoUrl;
    //       let a = document.createElement("a");
    //       a.setAttribute("href", url);
    //       a.setAttribute("download", documento.nome);
    //       a.setAttribute("target", "_blank");
    //       document.body.appendChild(a);
    //       a.click();
    //       a.remove();
    //       documentos.value[indexDoc].visualizacoes += 1;
    //     })
    //     .catch(() => {
    //       errorToast("Não foi possível baixar", errorMsg.value);
    //     })
    //     .finally(() => {
    //       documentos.value[indexDoc].downloadingDocument = false;
    //     });
    // }

    return {
      form,
      clearEndereco,
      indexDependente,
      formatDay,
      currentAge,
      // statusTrabalhador,
      workerIsApproved,
      dataOptions,
      getPaises,
      getBGMenuColor,
      documentos,
      getTextMenuColor,
      codImigrantes,
      datePtBr,
      isSaving,
      fetchCep,
      getDisplayName,
      dependente,
      dependentes,
      ptBR,
      showMaskCep,
      hasDataTrabalhador,
      loadingDataOptions,
      addInfoPessoal,
      toast,
      v$: useVuelidate(),
      paisNascimento,
      nacionalidade,
      endPais,
      errorMsg,
      files,
      upload,
      accept,
      filesPermitted,
      extensions,
      isUploading,
      drop,
      size,
      getters,
    };
  },
  validations() {
    return {
      paisNascimento: { required },
      endPais: { required },
      nacionalidade: { required },
      form: {
        indImigrante: {
          requiredIndImigrante: requiredIf(() => {
            return (
              this.nacionalidade.value !== 105 ||
              this.paisNascimento.value !== 105
            );
          }),
        },
        sexo: { required },
        // nomeMae: { required },
        endCep: { required },
        endLogradouro: { required },
        endNumero: { required },
        endBairro: { required },
        endMunicipioNome: { required },
        endMunicipio: { required },
        endUf: { required },
      },
      dependente: {
        tipoDependente: {
          required,
        },
        dtNascimento: {
          required,
        },
        sexo: {
          required,
        },
        nome: {
          required,
        },
        cpf: {
          cpf: function (value) {
            if (value !== null && value !== "") {
              return cpf.isValid(value);
            }
            return true;
          },
        },
      },
    };
  },
  mounted() {
    this.$watch("form.endCep", function (value) {
      if (value && value.length === 9 && !this.hasDataTrabalhador) {
        this.fetchCep(value);
      }
      if (value && value.length === 8) {
        this.clearEndereco();
      }
    });
  },
  methods: {
    saveAndResetDependente() {
      this.v$.dependente.$touch();
      if (this.v$.dependente.$invalid) {
        this.errorToast();
      } else {
        const dependente = {
          nome: this.dependente.nome,
          dtNascimento: this.formatDay(this.dependente.dtNascimento),
          tipoDependente: this.dependente.tipoDependente,
          sexo: this.dependente.sexo,
          cpf: this.dependente.cpf,
        };
        this.dependentes.push(dependente);
        $("#modalCreateDependente").modal("hide");
        this.successToast(
          "Solicitação Concluída",
          "Dependente salvo com sucesso!"
        );
      }
    },
    async saveDependente() {
      const isFormCorrect = await this.v$.dependente.$validate();
      if (isFormCorrect) {
        this.errorToast();
      } else {
        const dependente = {
          nome: this.dependente.nome,
          dtNascimento: this.formatDay(this.dependente.dtNascimento),
          tipoDependente: this.dependente.tipoDependente,
          sexo: this.dependente.sexo,
          cpf: this.dependente.cpf,
        };
        this.dependentes.push(dependente);
        this.successToast(
          "Solicitação Concluída",
          "Dependente salvo com sucesso!"
        );
        this.resetDependenteForm();
        this.showModalCreateDependente = false;
      }
    },
    openModalDeleteDependente(index) {
      $("#modalDeleteDependente").modal("show");
      this.indexDependente = index;
    },
    deleteDependente() {
      this.dependentes.splice(this.indexDependente, 1);
      $("#modalDeleteDependente").modal("hide");
      this.successToast(
        "Solicitação Concluída",
        "Dependente excluído com sucesso!"
      );
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (newFile.size >= 10000000) {
          this.errorToast(
            "Não foi possível salvar",
            "O tamanho máximo permitido é de 10 MB."
          );
          return prevent();
        }
        if (!this.filesPermitted.test(newFile.name)) {
          this.errorToast(
            "Não foi possível salvar",
            "Essa extensão de arquivo não é permitido."
          );
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = "";
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file);
          this.uploadDocumento(newFile.file);
        }
      }
    },
    async onSave() {
      try {
        const isFormCorrect = await this.v$.form.$validate();

        if (isFormCorrect) {
          this.isSaving = true;
          const newInfoPessoal = {
            ...this.form,
            paisNascimento: this.paisNascimento.value,
            nacionalidade: this.nacionalidade.value,
            endPais: this.endPais.value,
            indImigrante: this.form.indImigrante,
            cnhUf: this.form.cnh ? this.form.cnhUf : null,
            ctpsUf: this.form.cnh ? this.form.ctpsUf : null,
            cnhVencimento: this.form.cnh
              ? this.formatDay(this.form.cnhVencimento)
              : null,
            dependentes: this.dependentes,
          };
          await this.addInfoPessoal({ newInfoPessoal });
          mutations.setCurrentStatus("PENDENTE");
          this.successToast(
            "Cadastro enviado com sucesso!",
            "Todas as Informações Pessoais do Trabalhador foram enviadas com sucesso!"
          );
        } else {
          this.errorToast();
        }
      } catch (error) {
        this.errorToast(
          this.errorMsg
            ? this.errorMsg
            : "Ocorreu algo inesperado, tente novamente mais tarde!"
        );
      } finally {
        this.isSaving = false;
      }
    },
    errorToast(msg) {
      this.toast(
        {
          component: Toast,
          props: {
            title: `Não foi possível salvar!`,
            text: msg
              ? msg
              : "Por favor verifique se você preencheu o campo corretamente!",
          },
        },
        { type: TYPE.ERROR }
      );
    },
    successToast(title, msg) {
      this.toast(
        {
          component: Toast,
          props: {
            title: title ? title : "Campo alterado com sucesso!",
            text: msg ? msg : "O campo foi alterado com sucesso!",
          },
        },
        { type: TYPE.SUCCESS }
      );
    },
  },
};
</script>

<style lang="scss">
@import "/src/assets/scss/custom/plugins/datepicker/main.scss";
.btn-with-icon {
  padding: 0.47rem 0.95rem 0.47rem 0.55rem;
}
.item-dependente {
  background-color: #f4f5f8;
}

@media (min-width: 992px) {
  .btn-exclude-dependente {
    opacity: 0;
  }
  .item-dependente:hover .btn-exclude-dependente {
    opacity: 100;
  }
}
.upload-anexos .drop-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-left: 1rem /* 16px */;
  padding-right: 1rem /* 16px */;
  height: 200px;
}
.upload-anexos .drop-content::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border: 2px;
  border-style: dashed;
  border-color: gray;
  width: 100%;
  height: 100%;
  border-image: initial;
  transition: border-color 0.3s cubic-bezier(0.19, 1, 0.22, 1) 0s;
}
.upload-anexos .drop-active {
  z-index: 50;
  @apply bg-primaria-200;
  &::after {
    @apply border-primary;
    animation: 8s linear 0s infinite normal none running rotation;
  }
}
.upload-anexos .link {
  color: #0d6efd;
}
.form-control:disabled,
.form-control[readonly] {
  background-color: #f3f4f6 !important;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  background-color: #f3f4f6 !important;
  border-color: #f3f4f6 !important;
  color: #515152 !important;
  cursor: not-allowed !important;
  opacity: 1 !important;
}

.mt-calendar {
  margin-top: -80px;
}
</style>
