<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex justify-content-md-end">
              <router-link
                to="/nova-solicitacao"
                class="btn btn-primary waves-effect waves-light"
              >
                Criar Novo Solicitação
              </router-link>
            </div>

            <LoadingCircle
              v-if="loadingSolicitacoes && solicitacoesDisplay.length === 0"
            />
            <div class="overflow-auto ct-scrollbar-thin-horizontal">
              <div
                v-if="solicitacoesDisplay.length"
                class="accordion-min-width w-100"
              >
                <CtAccordion
                  v-for="(accordion, indexAccordion) in solicitacoesDisplay"
                  :key="indexAccordion"
                  @change="onOpenAccordion($event, indexAccordion)"
                  v-model="accordion.visible"
                >
                  <template #trigger>
                    <div
                      class="align-items-center w-100"
                      style="
                        display: grid;
                        grid-template-columns: 1fr 0.7fr 1.2fr 1fr;
                        grid-gap: 1rem;
                      "
                    >
                      <div class="d-flex flex-column">
                        <span class="fs-6 text-secondary">
                          Tipo de Solicitação
                        </span>
                        <span class="fs-5 fw-normal text-dark">{{
                          accordion.tipo.displayName
                        }}</span>
                      </div>
                      <div class="d-flex flex-column">
                        <span class="fs-6 text-secondary"> Origem</span>
                        <span class="fs-5 fw-normal text-dark">{{
                          accordion.origem.displayName
                        }}</span>
                      </div>
                      <div class="d-flex flex-column justify-content-end">
                        <span class="fs-6 text-secondary">
                          Data de Criação</span
                        >
                        <span class="fs-5 fw-normal text-dark">{{
                          formatDateWithHours(accordion.dtCriacao)
                        }}</span>
                      </div>
                      <div
                        class="d-flex justify-content-end align-items-center"
                      >
                        <span
                          :class="
                            badgeClass(accordion.status.value) +
                            ' fs-6 rounded-pill me-2'
                          "
                          :style="`width: ${
                            accordion.status.value === 'AGUARDANDO_VALIDACAO'
                              ? 'auto;'
                              : 'auto;'
                          }
                            padding: 0.45rem 0.65rem;
                            margin-bottom: 0;
                            max-width: 172px;
                          `"
                        >
                          {{ accordion.status.displayName }}
                        </span>
                      </div>
                    </div>
                  </template>
                  <template #content>
                    <div>
                      <AccordionFeriasContent
                        v-model="accordion.renderAccordion"
                        v-if="
                          accordion.tipo.value === 'FERIAS' &&
                          accordion.renderAccordion
                        "
                        :solicitacao-id="accordion.id"
                      />
                      <AccordionAdiantamentoContent
                        v-model="accordion.renderAccordion"
                        v-if="
                          accordion.tipo.value === 'ADIANTAMENTO' &&
                          accordion.renderAccordion
                        "
                        :solicitacao-id="accordion.id"
                      />
                      <AccordionFaltasContent
                        v-model="accordion.renderAccordion"
                        :solicitacaoId="accordion.id"
                        v-if="
                          accordion.tipo.value === 'FALTA' &&
                          accordion.renderAccordion
                        "
                      />
                      <AccordionOutrosContent
                        v-model="accordion.renderAccordion"
                        :solicitacaoId="accordion.id"
                        v-if="
                          accordion.tipo.value === 'OUTRO' &&
                          accordion.renderAccordion
                        "
                        @setStatusSolicitacao="setStatusSolicitacao"
                      />
                      <AccordionInfoTrabContent
                        v-model="accordion.renderAccordion"
                        :solicitacaoId="accordion.id"
                        v-if="
                          (accordion.tipo.value === 'INFO_PESSOAL' ||
                            accordion.tipo.value ===
                              'ALTERACAO_INFO_PESSOAL') &&
                          accordion.renderAccordion
                        "
                        @setStatusSolicitacao="setStatusSolicitacao"
                      />
                    </div>
                  </template>
                </CtAccordion>
              </div>
              <NoDataList
                v-if="!solicitacoesDisplay.length && !loadingSolicitacoes"
                text="Você não possui nenhuma solicitação."
              />

              <div class="d-flex mb-2 mt-4 w-100">
                <div class="fw-bold fs-6" v-if="solicitacoesDisplay.length">
                  <span>
                    {{ solicitacoesDisplay.length }}
                    {{
                      solicitacoesDisplay.length === 1
                        ? "Solicitação"
                        : "Solicitações"
                    }}
                  </span>
                </div>
                <div
                  v-if="showButtonLoadMore"
                  class="text-primary ms-auto text-end similar-pointer d-flex align-items-center"
                  @click="loadMore"
                >
                  <i
                    class="mdi mdi-rotate-3d-variant mdi-18px"
                    :class="`${loadingSolicitacoes ? 'mdi-spin' : ''}`"
                  ></i>
                  <span class="ms-1 fw-bold">Carregar mais registros</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useQuery } from "@vue/apollo-composable";
import { getSolicitacoesDisplay } from "@/state/graphql/solicitacoes";
import { ref } from "vue";
import CtAccordion from "@/components/CtAccordion/CtAccordion.vue";
import LoadingCircle from "@/components/Loading/LoadingCircle.vue";
import { formatDateWithHours } from "@/composables/useFormatDate";
import AccordionInfoTrabContent from "./components/accordions/AccordionInfoTrabContent.vue";
import AccordionFeriasContent from "./components/accordions/AccordionFeriasContent.vue";
import AccordionAdiantamentoContent from "./components/accordions/AccordionAdiantamentoContent.vue";
import AccordionFaltasContent from "./components/accordions/AccordionFaltasContent.vue";
import AccordionOutrosContent from "./components/accordions/AccordionOutrosContent.vue";
// import FormFaltas from "./components/Forms/FormFaltas.vue
import NoDataList from "../Demonstrativos/components/NoDataList.vue";

export default {
  name: "Solicitacoes",
  components: {
    CtAccordion,
    AccordionInfoTrabContent,
    AccordionAdiantamentoContent,
    AccordionFeriasContent,
    AccordionFaltasContent,
    AccordionOutrosContent,
    LoadingCircle,
    NoDataList,
  },
  setup() {
    const solicitacoesDisplay = ref([]);
    const showButtonLoadMore = ref(false);

    /* Queries */
    const {
      // result: resultSolicitacoes,
      onResult: onResultSolicitacoes,
      loading: loadingSolicitacoes,
      fetchMore,
    } = useQuery(
      getSolicitacoesDisplay,
      () => ({
        cursor: null,
      }),
      {
        fetchPolicy: "network-only",
      }
    );

    // const setObject = (tipoSolicitacao) => {
    //   if (tipoSolicitacao === "FALTA") {
    //     return objectFalta;
    //   }
    //   return objectFalta;
    // };

    onResultSolicitacoes(result => {
      let apiSolicitacoes = result.data.solicitacoesDisplay;
      if (apiSolicitacoes.length) {
        showButtonLoadMore.value = apiSolicitacoes.length > 9;
        solicitacoesDisplay.value = apiSolicitacoes.map(solicitacao => {
          return Object.assign({
            ...solicitacao,
            visible: false,
            renderAccordion: false,
            // objeto: setObject(solicitacao.tipo),
          });
        });
      }
    });

    function onOpenAccordion(isOpen, indexAccordion) {
      if (isOpen) {
        solicitacoesDisplay.value[indexAccordion].renderAccordion = true;
      }
    }

    function loadMore() {
      fetchMore({
        variables: {
          cursor:
            solicitacoesDisplay.value[solicitacoesDisplay.value.length - 1].id,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            solicitacoesDisplay: [
              ...previousResult.solicitacoesDisplay,
              ...fetchMoreResult.solicitacoesDisplay,
            ],
          };
        },
      });
    }

    const badgeClass = status => {
      if (status === "PENDENTE") {
        return "alert alert-warning";
      }
      if (status === "APROVADA") {
        return "alert alert-success";
      }
      if (status === "RECUSADA") {
        return "alert alert-danger";
      }
      if (status === "AGUARDANDO_VALIDACAO") {
        return "alert alert-secondary";
      }
      return "alert alert-primary ";
    };

    function setStatusSolicitacao(idSolicitacao, newStatus) {
      const indexSolictacao = solicitacoesDisplay.value.findIndex(
        item => item.id === idSolicitacao
      );
      if (indexSolictacao !== -1) {
        solicitacoesDisplay.value[indexSolictacao].status = newStatus;
      }
    }

    return {
      solicitacoesDisplay,
      loadingSolicitacoes,
      badgeClass,
      loadMore,
      onOpenAccordion,
      showButtonLoadMore,
      setStatusSolicitacao,
      formatDateWithHours,
    };
  },
};
</script>
