import { gql } from "@apollo/client/core";

export const getTrabalhadorPdfRecibo = gql`
  query getTrabalhadorPdfRecibo {
    trabalhador {
      id
      idEmpregador
      nome
      telefone
      urlFoto
      matricula
    }
  }
`;

export const getEmpregadorHeaderPdf = gql`
  query getEmpregadorHeaderPdf($idEmpregador: ID!) {
    trabalhadorAppEmpregadorConfig(idEmpregador: $idEmpregador) {
      urlLogo
    }
    empregadorNome(idEmpregador: $idEmpregador)
  }
`;

export const getInformeRendimentoDataSource = gql`
query getInformeRendimentoDataSource(
  $idEmpregador: ID!
  $informeRendimentoRequest: InformeRendimentoRequest!
) {
  informeRendimentoDataSource(
    idEmpregador: $idEmpregador
    informeRendimentoRequest: $informeRendimentoRequest
  ) {
    empregadorNome
    inscricao
    endereco
    cidade
    estadoUf
    telefone
    data
    ano
    cpf
    nome
    naturezaRendimentos
    totalRendimentos
    contribuicaoPrevidenciariaOficial
    contribuicaoPrevidenciariaPrivada
    pensaoAlimenticia
    irrf
    percelaIsenta
    diariasAjudaCusto
    pensao
    indenizacaoRescisao
    outrosIsentos
    decimoTerceiro
    irrfDecimoTerceiro
    plr
    despesasMedicas
    beneficiarioPensao {
      nome
      cpf
    }
}
}`

export const getReciboDecimoTerceiroDataSource = gql`
query ReciboDecimoTerceiroDataSource($idEmpregador: ID!, $reciboDecimoTerceiroRequest: ReciboDecimoTerceiroRequest!) {
  reciboDecimoTerceiroDataSource(idEmpregador: $idEmpregador, reciboDecimoTerceiroRequest: $reciboDecimoTerceiroRequest) {
    localDenominacao
    localTipoInscricao
    localInscricao
    localEndereco
    dtPagamento
    logoUrl
    msgRecibo
    matricula
    nomeTrabalhador
    cboFuncao
    descricaoFuncao
    dtAdmissao
    salarioBase
    undSalario
    banco
    bancoAgencia
    contaBancaria
    bruto
    descontos
    liquido
    baseInss
    percInss
    inss
    baseFgts
    percFgts
    fgts
    baseIrrf
    deduIrrf
    percIrrf
    irrf
    eventosDecimoTerceiro {
      descReferencia
      natureza
      rubricaCodigo
      rubricaDescricao
      valor
    }
    qtdEventos
  }
}
`

export const getReciboFeriasSource = gql`
query ReciboFeriasDataSource($reciboFeriasRequest: ReciboFeriasRequest!, $idEmpregador: ID!) {
  reciboFeriasDataSource(reciboFeriasRequest: $reciboFeriasRequest, idEmpregador: $idEmpregador) {
    localDenominacao
    localTipoInscricao {
      displayName
      value
    }
    localInscricao
    localEndereco
    localCidadeUf
    logoUrl
    dtPagamento
    matricula
    nomeTrabalhador
    cboFuncao
    descricaoFuncao
    dtInicioPeriodoAquisitivo
    dtFimPeriodoAquisitivo
    dtInicio
    dtFim
    qntDias
    diasAbono
    dtRetorno
    salarioBase
    undSalario
    bruto
    descontos
    liquido
    liquidoPorExtenso
    eventosFerias {
      rubricaCodigo
      rubricaDescricao
      natureza {
        displayName
        value
      }
      descReferencia
      valor
      mes1Descricao
      mes1Dias
      mes1Valor
      mes2Descricao
      mes2Dias
      mes2Valor
      mes3Descricao
      mes3Dias
      mes3Valor
    }
    qtdPeriodos
    qtdEventos
  }
}`

export const getTermoRescisaoSource = gql`
query TermoRescisaoDataSource($termoRescisaoRequest: TermoRescisaoRequest!, $idEmpregador: ID!) {
  termoRescisaoDataSource(termoRescisaoRequest: $termoRescisaoRequest, idEmpregador: $idEmpregador) {
    localDenominacao
    localTipoInscricao {
      displayName
      value
    }
    localInscricao
    localEndereco
    localBairro
    localMunicipio
    localUf
    localCep
    localCnae
    tomadorServicoInscricao
    nis
    nomeTrabalhador
    enderecoCep
    enderecoLogradouro
    enderecoNumero
    enderecoComplemento
    enderecoBairro
    enderecoMunicipio
    enderecoUf
    carteiraTrabalho
    cpf
    dtNascimento
    nomeMae
    tipoContratoTrabalho
    motivoRescisao
    remuneracaoMesAnterior
    dtAdmissao
    dtAvisoPrevio
    dtRescisao
    codAfastamento
    percPensaoAlimenticia
    percPensaoAlimenticiaSaqueFgts
    categoriaClt
    codSindicato
    cnpjSindicato
    nomeSindicato
    verbasRescisoriasProventos {
      rubricaDescricao
      tipoRubrica {
        displayName
        value
      }
      valor
    }
    verbasRescisoriasDescontos {
      rubricaDescricao
      valor
    }
    bruto
    descontos
    liquido
  }
}`

export const getReciboAdiantamentoSource = gql`
query ReciboAdiantamentoDataSource($idEmpregador: ID!, $reciboAdiantamentoRequest: ReciboAdiantamentoRequest!) {
  reciboAdiantamentoDataSource(idEmpregador: $idEmpregador, reciboAdiantamentoRequest: $reciboAdiantamentoRequest) {
    periodoDescricao
    logoUrl
    msgRecibo
    localDenominacao
    localTipoInscricao
    localInscricao
    localEndereco
    dtPagamento
    matricula
    nomeTrabalhador
    cboFuncao
    descricaoFuncao
    dtAdmissao
    salarioBase
    undSalario
    banco
    bancoAgencia
    contaBancaria
    valorRequerido
    descontos
    liquido
    baseIrrf
    deduIrrf
    percIrrf
    irrf
    eventosAdiantamento {
      rubricaCodigo
      rubricaDescricao
      natureza
      descReferencia
      valor
    }
  }
}`

export const getReciboFolhaMensalDataSource = gql`
query ReciboFolhaDataSource($reciboFolhaRequest: ReciboFolhaRequest!, $idEmpregador: ID!) {
  reciboFolhaDataSource(reciboFolhaRequest: $reciboFolhaRequest, idEmpregador: $idEmpregador) {
    logoUrl
    msgRecibo
    localDenominacao
    localTipoInscricao
    localInscricao
    localEndereco
    localCidade
    localUF
    indAutonomo
    cpf
    pis
    trabalhadorEndereco
    matricula
    nomeTrabalhador
    cboFuncao
    descricaoFuncao
    dtAdmissao
    salarioBase
    undSalario
    banco
    bancoAgencia
    contaBancaria
    bruto
    descontos
    liquido
    baseInss
    percInss
    inss
    baseFgts
    percFgts
    fgts
    baseIrrf
    deduIrrf
    percIrrf
    irrf
    iss
    percentualIss
    eventosFolha {
      rubricaCodigo
      rubricaDescricao
      natureza
      referencia
      descReferencia
      valor
    }
    qtdEventos
  }
}`
