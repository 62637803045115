import { imageUrlToBase64 } from "@/composables/useBase64Img";
import {
  formatDate,
  formatMonthAndYearPtBr,
} from "@/composables/useFormatDate";
import {
  formatNumber,
  formatPriceWithoutBRL,
} from "@/composables/useFormatMoney";
import { limitString } from "@/composables/useHandleString";
import { usePdfMake } from "@/composables/usePdfMake";
import { apolloClient } from "@/state/client";
import { getReciboAdiantamentoSource } from "@/state/graphql/data-sources";

export const pdfMakeReciboAdiantamentos = async (
  periodo,
  idEmpregador,
  typeCreate = "newTab"
) => {
  const docDefinition = (adiantamentoDados, logoEmpregador) => {
    const TABLE_CONFIGS_PDFMAKE = {
      borderColor: "#f2f6f9",
      borderWidth: 1.5,
      fontSizeParagaph: 9,
    };

    const customLabel = ({ codigo, descricao }) =>
      `${
        codigo.toString().length < 3 ? ("00" + codigo).slice(-3) : codigo
      } - ${limitString(descricao, 60)}`;

    const tableWithoutLines = {
      hLineWidth: function () {
        return 0;
      },
      vLineWidth: function () {
        return 0;
      },
    };

    const TOTAIS_TABLE = () => ({
      width: 150,
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        fillColor: function (i) {
          return i === 0 ? "#f2f6f8" : "#fff";
        },
      },
      table: {
        widths: ["*", "*"],
        body: [
          [
            {
              alignment: "left",
              text: [
                {
                  text: "TOTAIS",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            { text: "" },
          ],
          [
            {
              alignment: "left",
              text: [
                {
                  text: "Proventos: ",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.bruto)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
          ],
          [
            {
              alignment: "left",
              text: [
                {
                  text: "Descontos: ",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.descontos)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
          ],
          [
            {
              alignment: "left",
              text: [
                {
                  text: "Líquido: ",
                  bold: true,
                  fontSize: 10,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.liquido)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
          ],
        ],
      },
    });

    const ENCARGOS_TABLE = () => ({
      layout: {
        hLineWidth: function () {
          return 0;
        },
        vLineWidth: function () {
          return 0;
        },
        fillColor: function (i) {
          return i === 0 ? "#f2f6f8" : "#fff";
        },
      },
      table: {
        widths: [45, 60, 30, 40, 60, 25, 60],
        body: [
          [
            {
              alignment: "left",
              text: [
                {
                  text: "ENCARGOS",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            { text: "" },
            { text: "" },
            { text: "" },
            { text: "" },
            { text: "" },
            { text: "" },
          ],
          [
            {
              alignment: "left",
              text: [
                {
                  text: "Base FGTS: ",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.baseFgts)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatNumber(adiantamentoDados.percFgts)}%`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "left",
              text: [
                {
                  text: "Valor:",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.fgts)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "left",
              text: [
                {
                  text: "",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: "",
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
          ],
          [
            {
              alignment: "left",
              text: [
                {
                  text: "Base INSS: ",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.baseInss)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatNumber(adiantamentoDados.percInss)}%`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "left",
              text: [
                {
                  text: "Valor:",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.inss)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "left",
              text: [
                {
                  text: "",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: "",
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
          ],
          [
            {
              alignment: "left",
              text: [
                {
                  text: "Base IRRF: ",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.baseIrrf)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatNumber(adiantamentoDados.percIrrf)}%`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "left",
              text: [
                {
                  text: "Dedução:",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.deduIrrf)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "left",
              text: [
                {
                  text: "Valor:",
                  bold: true,
                  fontSize: 8,
                },
              ],
            },
            {
              alignment: "right",
              text: [
                {
                  text: `${formatPriceWithoutBRL(adiantamentoDados.irrf)}`,
                  bold: false,
                  fontSize: 8,
                },
              ],
            },
          ],
        ],
      },
    });

    let pages = [];
    let currentPage = null;
    let totalEventosPerPage = adiantamentoDados.contaBancaria ? 9 : 10;

    for (
      let i = 0;
      i < adiantamentoDados.eventosAdiantamento.length;
      i += totalEventosPerPage
    ) {
      const eventosAdiantamentoOrdered = [
        ...adiantamentoDados.eventosAdiantamento
          .filter(item => item.natureza === "PROVENTO")
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo),
        ...adiantamentoDados.eventosAdiantamento
          .filter(item => item.natureza === "DESCONTO")
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo),
        ...adiantamentoDados.eventosAdiantamento
          .filter(
            item => item.natureza !== "DESCONTO" && item.natureza !== "PROVENTO"
          )
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo),
      ];
      let ficArray = [];
      currentPage =
        eventosAdiantamentoOrdered.length > totalEventosPerPage
          ? "Página " + Math.trunc(i / 10 + 1)
          : " ";
      for (
        let j = 0;
        j <
        totalEventosPerPage -
          eventosAdiantamentoOrdered.slice(i, i + totalEventosPerPage).length;
        j++
      ) {
        ficArray.push(0);
      }

      pages = [
        ...pages,
        {
          layout: "noBorders",
          table: {
            widths: ["auto", "*", 160],
            body: [
              [
                logoEmpregador
                  ? {
                      marginBottom: 5,
                      image: logoEmpregador,
                      fit: ["auto", 35],
                    }
                  : {
                      text: " ",
                      marginBottom: 20,
                    },
                {
                  alignment: "left",
                  text: [
                    {
                      text: `${limitString(adiantamentoDados.localDenominacao, 60)} \n`,
                      bold: true,
                      fontSize: 10,
                    },
                    {
                      text: `${adiantamentoDados.localInscricao}`,
                      fontSize: 8,
                      bold: false,
                    },
                    {
                      text: `\n ${limitString(adiantamentoDados.localEndereco, 80)}`,
                      fontSize: 8,
                      bold: false,
                    },
                  ],
                },
                {
                  alignment: "center",
                  text: [
                    {
                      text: "Recibo de Adiantamento Salarial",
                      fontSize: 8,
                      bold: true,
                    },
                    {
                      text: `\n ${adiantamentoDados.periodoDescricao}`,
                      fontSize: 10,
                      bold: true,
                    },
                    {
                      text: `\n Data pagamento: ${formatDate(adiantamentoDados.dtPagamento)}`,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          layout: {
            hLineWidth: function (i, node) {
              if (
                i === 0 ||
                (i === node.table.body.length &&
                  !adiantamentoDados.contaBancaria)
              ) {
                return TABLE_CONFIGS_PDFMAKE.borderWidth;
              }
              return 0;
            },
            vLineWidth: function () {
              return 0;
            },
            hLineColor: function () {
              return TABLE_CONFIGS_PDFMAKE.borderColor;
            },
          },
          table: {
            widths: ["*", "auto", "auto"],
            body: [
              [
                {
                  marginTop: 5,
                  alignment: "left",
                  colSpan: 3,
                  text: [
                    {
                      lineHeight: 0.8,
                      text: limitString(
                        `${adiantamentoDados.matricula} - ${adiantamentoDados.nomeTrabalhador}`,
                        100
                      ),
                      bold: true,
                      fontSize: 10,
                    },
                  ],
                },
                { text: "" },
                { text: "" },
              ],
              [
                {
                  marginBottom: !adiantamentoDados.contaBancaria ? 5 : 0,
                  lineHeight: !adiantamentoDados.contaBancaria ? 1 : 0.7,
                  alignment: "left",
                  text: [
                    {
                      text: limitString(
                        `${adiantamentoDados.cboFuncao} - ${adiantamentoDados.descricaoFuncao}`,
                        60
                      ),
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  lineHeight: !adiantamentoDados.contaBancaria ? 1 : 0.7,
                  alignment: "right",
                  text: [
                    {
                      text: "Admissão: ",
                      bold: true,
                      fontSize: 8,
                    },
                    {
                      text: `${formatDate(adiantamentoDados.dtAdmissao)}`,
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  lineHeight: !adiantamentoDados.contaBancaria ? 1 : 0.7,
                  alignment: "right",
                  text: [
                    {
                      text: "Salário Base: ",
                      bold: true,
                      fontSize: 8,
                    },
                    {
                      text: `${formatPriceWithoutBRL(
                        adiantamentoDados.salarioBase
                      )} ${adiantamentoDados.undSalario}`,
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        adiantamentoDados.contaBancaria
          ? {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length) {
                    return TABLE_CONFIGS_PDFMAKE.borderWidth;
                  }
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
                hLineColor: function () {
                  return TABLE_CONFIGS_PDFMAKE.borderColor;
                },
              },
              table: {
                widths: ["*", "*", "auto", "auto", "auto"],
                body: [
                  [
                    { text: "" },
                    { text: "" },
                    {
                      alignment: "left",
                      marginBottom: 5,
                      text: [
                        {
                          text: "Banco: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: adiantamentoDados.banco,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "center",
                      text: [
                        {
                          text: "Agência: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: adiantamentoDados.bancoAgencia,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: "Conta: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: `${adiantamentoDados.contaBancaria}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                ],
              },
            }
          : null,
        {
          marginTop: 5,
          marginBottom: adiantamentoDados.contaBancaria ? 6 : 3,
          layout: {
            hLineWidth: function () {
              return 0;
            },
            vLineWidth: function () {
              return 0;
            },
            fillColor: function (i) {
              return i === 0 ? "#f2f6f8" : "#fff";
            },
          },
          table: {
            widths: [250, 100, "*", "*"],
            body: [
              [
                {
                  text: "DESCRIÇÃO",
                  bold: true,
                  fontSize: 8,
                },
                {
                  text: "REFERÊNCIA",
                  bold: true,
                  fontSize: 8,
                },
                {
                  text: "PROVENTOS",
                  alignment: "right",
                  bold: true,
                  fontSize: 8,
                },
                {
                  text: "DESCONTOS",
                  alignment: "right",
                  bold: true,
                  fontSize: 8,
                },
              ],
              ...eventosAdiantamentoOrdered
                .slice(i, i + totalEventosPerPage)
                .map(evento => [
                  {
                    alignment: "left",
                    text: [
                      {
                        text: customLabel({
                          codigo: evento.rubricaCodigo,
                          descricao: limitString(evento.rubricaDescricao, 60),
                        }),
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "left",
                    text: [
                      {
                        text:
                          evento.natureza === "INFORMATIVA_DEDUTORA" ||
                          evento.natureza === "INFORMATIVA"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "0,00"
                            : evento.descReferencia
                              ? limitString(evento.descReferencia, 25)
                              : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    text: [
                      {
                        text:
                          evento.natureza === "PROVENTO"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "0,00"
                            : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    text: [
                      {
                        text:
                          evento.natureza === "DESCONTO"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "0,00"
                            : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                ]),
              ...ficArray.map(() => [
                {
                  alignment: "left",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "left",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ]),
            ],
          },
        },
        {
          layout: tableWithoutLines,
          columns: [ENCARGOS_TABLE(), TOTAIS_TABLE()],
        },
        {
          marginTop: 3,
          layout: "noBorders",
          table: {
            widths: ["*"],
            heights: [20],
            body: [
              [
                {
                  alignment: "left",
                  text: [
                    {
                      text:
                        adiantamentoDados.msgRecibo !== null
                          ? adiantamentoDados.msgRecibo
                          : "",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          marginTop: 3,
          layout: "noBorders",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  alignment: "center",
                  text: [
                    {
                      text: "DECLARO TER RECEBIDO A IMPORTÂNCIA LÍQUIDA DISCRIMINADA NESTE RECIBO.",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          columns: [
            {
              width: 150,
              marginTop: 11,
              layout: {
                hLineWidth: function () {
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
              },
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "____/____/_________",
                          bold: false,
                          fontSize: 12,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
            {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length - 1) {
                    return 0.9;
                  }
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
              },
              marginTop: 8,
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      alignment: "right",
                      text: [
                        {
                          text: " ",
                          bold: false,
                          fontSize: 8.5,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "center",
                      text: [
                        {
                          text: `${adiantamentoDados.nomeTrabalhador}`,
                          bold: false,
                          fontSize: 8.5,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          layout: "noBorders",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  alignment: "right",
                  text: [
                    {
                      text: currentPage,
                      bold: false,
                      fontSize: 10,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          marginTop: 8,
          marginBottom: 10,
          layout: {
            hLineWidth: function (i, node) {
              if (i === node.table.body.length - 1) {
                return 1.5;
              }
              return 0;
            },
            hLineColor: function () {
              return "#a0aec0";
            },
            hLineStyle: function () {
              return { dash: { length: 5, space: 3 } };
            },
            vLineWidth: function () {
              return 0;
            },
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: "",
                  bold: false,
                  fontSize: 8.5,
                },
              ],
            ],
          },
        },
        {
          layout: "noBorders",
          table: {
            widths: ["auto", "*", 160],
            body: [
              [
                logoEmpregador
                  ? {
                      marginBottom: 5,
                      image: logoEmpregador,
                      fit: ["auto", 35],
                    }
                  : {
                      text: " ",
                      marginBottom: 20,
                    },
                {
                  alignment: "left",
                  text: [
                    {
                      text: `${limitString(adiantamentoDados.localDenominacao, 60)} \n`,
                      bold: true,
                      fontSize: 10,
                    },
                    {
                      text: `${adiantamentoDados.localInscricao}`,
                      fontSize: 8,
                      bold: false,
                    },
                    {
                      text: `\n ${limitString(adiantamentoDados.localEndereco, 80)}`,
                      fontSize: 8,
                      bold: false,
                    },
                  ],
                },

                {
                  alignment: "center",
                  text: [
                    {
                      text: "Recibo de Adiantamento Salarial \n",
                      fontSize: 8,
                      bold: true,
                    },
                    {
                      text: `${adiantamentoDados.periodoDescricao} \n`,
                      fontSize: 10,
                      bold: true,
                    },
                    {
                      text: `Data pagamento: ${formatDate(adiantamentoDados.dtPagamento)}`,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          layout: {
            hLineWidth: function (i, node) {
              if (
                i === 0 ||
                (i === node.table.body.length &&
                  !adiantamentoDados.contaBancaria)
              ) {
                return TABLE_CONFIGS_PDFMAKE.borderWidth;
              }
              return 0;
            },
            vLineWidth: function () {
              return 0;
            },
            hLineColor: function () {
              return TABLE_CONFIGS_PDFMAKE.borderColor;
            },
          },
          table: {
            widths: ["*", "auto", "auto"],
            body: [
              [
                {
                  marginTop: 5,
                  alignment: "left",
                  colSpan: 3,
                  text: [
                    {
                      lineHeight: 0.8,
                      text: limitString(
                        `${adiantamentoDados.matricula} - ${adiantamentoDados.nomeTrabalhador}`,
                        100
                      ),
                      bold: true,
                      fontSize: 10,
                    },
                  ],
                },
                { text: "" },
                { text: "" },
              ],
              [
                {
                  marginBottom: !adiantamentoDados.contaBancaria ? 5 : 0,
                  lineHeight: !adiantamentoDados.contaBancaria ? 1 : 0.7,
                  alignment: "left",
                  text: [
                    {
                      text: limitString(
                        `${adiantamentoDados.cboFuncao} - ${adiantamentoDados.descricaoFuncao}`,
                        60
                      ),
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  lineHeight: !adiantamentoDados.contaBancaria ? 1 : 0.7,
                  alignment: "right",
                  text: [
                    {
                      text: "Admissão: ",
                      bold: true,
                      fontSize: 8,
                    },
                    {
                      text: `${formatDate(adiantamentoDados.dtAdmissao)}`,
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  lineHeight: !adiantamentoDados.contaBancaria ? 1 : 0.7,
                  alignment: "right",
                  text: [
                    {
                      text: "Salário Base: ",
                      bold: true,
                      fontSize: 8,
                    },
                    {
                      text: `${formatPriceWithoutBRL(
                        adiantamentoDados.salarioBase
                      )} ${adiantamentoDados.undSalario}`,
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        adiantamentoDados.contaBancaria
          ? {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length) {
                    return TABLE_CONFIGS_PDFMAKE.borderWidth;
                  }
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
                hLineColor: function () {
                  return TABLE_CONFIGS_PDFMAKE.borderColor;
                },
              },
              table: {
                widths: ["*", "*", "auto", "auto", "auto"],
                body: [
                  [
                    { text: "" },
                    { text: "" },
                    {
                      alignment: "left",
                      marginBottom: 5,
                      text: [
                        {
                          text: "Banco: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: adiantamentoDados.banco,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "center",
                      text: [
                        {
                          text: "Agência: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: adiantamentoDados.bancoAgencia,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: "Conta: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: `${adiantamentoDados.contaBancaria}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                ],
              },
            }
          : null,
        {
          marginTop: 5,
          marginBottom: adiantamentoDados.contaBancaria ? 6 : 3,
          layout: {
            hLineWidth: function () {
              return 0;
            },
            vLineWidth: function () {
              return 0;
            },
            fillColor: function (i) {
              return i === 0 ? "#f2f6f8" : "#fff";
            },
          },
          table: {
            widths: [250, 100, "*", "*"],
            body: [
              [
                {
                  text: "DESCRIÇÃO",
                  bold: true,
                  fontSize: 8,
                },
                {
                  text: "REFERÊNCIA",
                  bold: true,
                  fontSize: 8,
                },
                {
                  text: "PROVENTOS",
                  alignment: "right",
                  bold: true,
                  fontSize: 8,
                },
                {
                  text: "DESCONTOS",
                  alignment: "right",
                  bold: true,
                  fontSize: 8,
                },
              ],
              ...eventosAdiantamentoOrdered
                .slice(i, i + totalEventosPerPage)
                .map(evento => [
                  {
                    alignment: "left",
                    text: [
                      {
                        text: customLabel({
                          codigo: evento.rubricaCodigo,
                          descricao: limitString(evento.rubricaDescricao, 60),
                        }),
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "left",
                    text: [
                      {
                        text:
                          evento.natureza === "INFORMATIVA_DEDUTORA" ||
                          evento.natureza === "INFORMATIVA"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "0,00"
                            : evento.descReferencia
                              ? limitString(evento.descReferencia, 25)
                              : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    text: [
                      {
                        text:
                          evento.natureza === "PROVENTO"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "0,00"
                            : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    text: [
                      {
                        text:
                          evento.natureza === "DESCONTO"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "0,00"
                            : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                ]),
              ...ficArray.map(() => [
                {
                  alignment: "left",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "left",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ]),
            ],
          },
        },
        {
          layout: tableWithoutLines,
          columns: [ENCARGOS_TABLE(), TOTAIS_TABLE()],
        },
        {
          marginTop: 3,
          layout: "noBorders",
          table: {
            widths: ["*"],
            heights: [20],
            body: [
              [
                {
                  alignment: "left",
                  text: [
                    {
                      text:
                        adiantamentoDados.msgRecibo !== null
                          ? adiantamentoDados.msgRecibo
                          : "",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          layout: "noBorders",
          marginTop: 3,
          table: {
            widths: ["*"],
            body: [
              [
                {
                  alignment: "center",
                  text: [
                    {
                      text: "DECLARO TER RECEBIDO A IMPORTÂNCIA LÍQUIDA DISCRIMINADA NESTE RECIBO.",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          columns: [
            {
              width: 150,
              marginTop: 11,
              layout: {
                hLineWidth: function () {
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
              },
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "____/____/_________",
                          bold: false,
                          fontSize: 12,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
            {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length - 1) {
                    return 0.9;
                  }
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
              },
              marginTop: 8,
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      alignment: "right",
                      text: [
                        {
                          text: " ",
                          bold: false,
                          fontSize: 8.5,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "center",
                      text: [
                        {
                          text: `${adiantamentoDados.nomeTrabalhador}`,
                          bold: false,
                          fontSize: 8.5,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          layout: "noBorders",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  alignment: "right",
                  text: [
                    {
                      text: currentPage,
                      bold: false,
                      fontSize: 10,
                    },
                  ],
                },
              ],
            ],
          },
        },
      ];
    }

    return {
      info: {
        title: `Recibo de Adiantamento de ${adiantamentoDados.nomeTrabalhador} - ${adiantamentoDados.periodoDescricao}`,
        author: "Calculato Sistemas LTDA",
        keywords: "Folha Pagamento",
      },
      pageOrientation: "portrait",
      pageSize: "A4",
      pageMargins: [18, 17, 18, 18],
      content: pages,
    };
  };

  const { openPdfNewTab, downloadPDF } = usePdfMake();

  const onOpenWindowPrint = async () => {
    let adiantamentoDados = null;
    let logoEmpregador = null;

    const respAdiantamento = await apolloClient.query({
      query: getReciboAdiantamentoSource,
      variables: {
        idEmpregador: idEmpregador,
        reciboAdiantamentoRequest: {
          dtPagamento: periodo,
        },
      },
      fetchPolicy: "cache-first",
    });

    if (respAdiantamento.data) {
      let apiAdiantamento = respAdiantamento.data.reciboAdiantamentoDataSource;
      adiantamentoDados = {
        ...apiAdiantamento,
        eventosAdiantamento: apiAdiantamento.eventosAdiantamento.map(evfl => {
          return Object.assign({
            ...evfl,
            edit: false,
            isFixed: false,
            isLoading: false,
            loadingRevertEvento: false,
            tooltipMsg: null,
          });
        }),
      };

      logoEmpregador = await imageUrlToBase64(respAdiantamento.data.reciboAdiantamentoDataSource.logoUrl);
    }

    if (typeCreate === "newTab") {
      openPdfNewTab(docDefinition(adiantamentoDados, logoEmpregador));
    } else {
      downloadPDF(
        `Recibo de Adiantamento de ${adiantamentoDados.nomeTrabalhador} - ${formatMonthAndYearPtBr(periodo)}`,
        docDefinition(adiantamentoDados, logoEmpregador)
      );
    }
  };

  await onOpenWindowPrint();
};
