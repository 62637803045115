import { imageUrlToBase64 } from "@/composables/useBase64Img";
import { formatDayPtBr } from "@/composables/useFormatDate";
import { formatPriceWithoutBRL } from "@/composables/useFormatMoney";
import { zeroPad } from "@/composables/useHandleString";
import { usePdfMake } from "@/composables/usePdfMake";
import { valorPorExtenso } from "@/composables/useValidateNumber";
import { apolloClient } from "@/state/client";
import { getReciboFeriasSource } from "@/state/graphql/data-sources";
import { limitString } from "@/composables/useHandleString";

export const pdfMakeReciboFerias = async (dtInicio, idEmpregador, typeCreate = "newTab") => {
  let docDefinition = (feriasDados, logoEmpregador) => {
    const totalProventos = formatPriceWithoutBRL(feriasDados.bruto)
    const totalDescontos = formatPriceWithoutBRL(feriasDados.descontos)
    const valorReceber = formatPriceWithoutBRL(feriasDados.liquido)

    const layoutRemovePadding = {
      paddingTop: function () {
        return 0
      },
      paddingLeft: function () {
        return 0
      },
      paddingBottom: function () {
        return 0
      },
      hLineWidth: function () {
        return 0
      },
      vLineWidth: function () {
        return 0
      }
    }

    const getEventosDeProvento = (naturezaEvento = 'PROVENTO') => {
      let eventos = []
      if (feriasDados.qtdPeriodos === 2) {
        eventos = feriasDados.eventosFerias
          .filter(evento => evento.natureza.value === naturezaEvento)
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo)
          .map(evento => {
            return [
              {
                layout: layoutRemovePadding,
                table: {
                  widths: [200, 50],
                  body: [
                    [
                      {
                        text: `${zeroPad(evento?.rubricaCodigo || 0)} - ${
                          limitString(evento?.rubricaDescricao, 32) || 0
                        }`,
                        bold: false,
                        alignment: 'left',
                        fontSize: 9
                      },
                      {
                        text: evento?.descReferencia,
                        bold: false,
                        alignment: 'left',
                        fontSize: 9
                      }
                    ]
                  ]
                }
              },
              {
                text: ''
              },
              {
                text: evento.mes1Valor
                  ? formatPriceWithoutBRL(evento.mes1Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },
              {
                text: ''
              },
              {
                text: evento.mes2Valor
                  ? formatPriceWithoutBRL(evento.mes2Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },
              {
                text: ''
              },
              {
                text: evento.valor
                  ? formatPriceWithoutBRL(evento.valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              }
            ]
          })
        return [
          {
            marginTop: 5,
            layout: {
              hLineWidth: function () {
                return 0
              },
              vLineWidth: function () {
                return 0
              }
            },
            table: {
              widths: [255, 4, 90, 0.1, 90, 0.1, 68],
              body: [
                [
                  {
                    text: `${naturezaEvento}S`,
                    bold: true,
                    marginRight: 10,
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: feriasDados.eventosFerias[0].mes1Descricao,
                    bold: true,
                    alignment: 'right',
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: feriasDados.eventosFerias[0].mes2Descricao,
                    bold: true,
                    alignment: 'right',
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: 'TOTAL',
                    bold: true,
                    alignment: 'right',
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  }
                ]
              ]
            }
          },
          {
            marginTop: 2,
            marginLeft: 4,
            layout: 'noBorders',
            table: {
              widths: [255, 4, 90, 0.1, 90, 0.1, 68],
              body: [
                [
                  {
                    text: [
                      {
                        text: 'DESCRIÇÃO                                                             ',
                        bold: true,
                        alignment: 'left',
                        preserveLeadingSpaces: true,
                        fontSize: 9
                      },
                      {
                        text: 'REFERÊNCIA',
                        bold: true,
                        alignment: 'left',
                        fontSize: 9
                      }
                    ]
                  },
                  {
                    text: ''
                  },
                  {
                    text: feriasDados.eventosFerias[0].mes1Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: feriasDados.eventosFerias[0].mes2Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: 'VALOR',
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  }
                ],
                ...eventos
              ]
            }
          }
        ]
      } else if (feriasDados.qtdPeriodos === 3) {
        eventos = feriasDados.eventosFerias
          .filter(evento => evento.natureza.value === naturezaEvento)
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo)
          .map(evento => {
            return [
              {
                layout: layoutRemovePadding,
                table: {
                  widths: [170, 60],
                  body: [
                    [
                      {
                        text: `${zeroPad(evento?.rubricaCodigo || 0)} - ${
                          limitString(evento?.rubricaDescricao, 32) || 0
                        }`,
                        bold: false,
                        alignment: 'left',
                        fontSize: 9
                      },
                      {
                        text: evento?.descReferencia,
                        bold: false,
                        alignment: 'left',
                        fontSize: 9
                      }
                    ]
                  ]
                }
              },
              {
                text: ''
              },
              {
                text: evento.mes1Valor
                  ? formatPriceWithoutBRL(evento.mes1Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },

              {
                text: evento.mes2Valor
                  ? formatPriceWithoutBRL(evento.mes2Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },
              {
                text: evento.mes3Valor
                  ? formatPriceWithoutBRL(evento.mes3Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },

              {
                text: evento.valor
                  ? formatPriceWithoutBRL(evento.valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              }
            ]
          })
        return [
          {
            marginTop: 5,
            layout: {
              hLineWidth: function () {
                return 0
              },
              vLineWidth: function () {
                return 0
              }
            },
            table: {
              widths: [230, 4, 75, 75, 75, 60],
              body: [
                [
                  {
                    text: `${naturezaEvento}S`,
                    bold: true,
                    marginRight: 10,
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: feriasDados.eventosFerias[0].mes1Descricao,
                    bold: true,
                    alignment: 'right',
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  },

                  {
                    text: feriasDados.eventosFerias[0].mes2Descricao,
                    bold: true,
                    alignment: 'right',
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  },

                  {
                    text: feriasDados.eventosFerias[0].mes3Descricao,
                    bold: true,
                    alignment: 'right',
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  },

                  {
                    text: 'TOTAL',
                    bold: true,
                    alignment: 'right',
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  }
                ]
              ]
            }
          },
          {
            marginTop: 2,
            marginLeft: 4,
            layout: 'noBorders',
            table: {
              widths: [230, 4, 75, 75, 75, 60],
              body: [
                [
                  {
                    text: [
                      {
                        text: 'DESCRIÇÃO                                                   ',
                        bold: true,
                        alignment: 'left',
                        preserveLeadingSpaces: true,
                        fontSize: 9
                      },
                      {
                        text: 'REFERÊNCIA',
                        bold: true,
                        alignment: 'left',
                        fontSize: 9
                      }
                    ]
                  },
                  {
                    text: ''
                  },
                  {
                    text: feriasDados.eventosFerias[0].mes1Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },

                  {
                    text: feriasDados.eventosFerias[0].mes2Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },

                  {
                    text: feriasDados.eventosFerias[0].mes3Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },

                  {
                    text: 'VALOR',
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  }
                ],
                ...eventos
              ]
            }
          }
        ]
      } else {
        eventos = feriasDados.eventosFerias
          .filter(evento => evento.natureza.value === naturezaEvento)
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo)
          .map(evento => {
            return [
              {
                text: `${zeroPad(evento?.rubricaCodigo || 0)} - ${
                  evento?.rubricaDescricao || 0
                }`,
                bold: false,
                fontSize: 9
              },
              {
                text: evento?.descReferencia,
                bold: false,
                alignment: 'left',
                fontSize: 9
              },
              {
                text: formatPriceWithoutBRL(evento?.valor || 0),
                bold: false,
                alignment: 'right',
                fontSize: 9
              }
            ]
          })
        return [
          {
            marginTop: 5,
            layout: {
              hLineWidth: function () {
                return 0
              },
              vLineWidth: function () {
                return 0
              },
              fillColor: function () {
                return '#f2f6f9'
              }
            },
            table: {
              widths: ['*'],
              margin: 0,
              body: [
                [
                  {
                    text: `${naturezaEvento}S`,
                    bold: true,
                    fontSize: 9
                  }
                ]
              ]
            }
          },
          {
            marginTop: 2,
            marginLeft: 4,
            layout: 'noBorders',
            table: {
              widths: ['*', 140, 75],
              body: [
                [
                  {
                    text: 'DESCRIÇÃO',
                    bold: true,
                    alignment: 'left',
                    fontSize: 9
                  },
                  {
                    text: 'REFERÊNCIA',
                    bold: true,
                    alignment: 'left',
                    fontSize: 9
                  },
                  {
                    text: 'VALOR',
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  }
                ],
                ...eventos
              ]
            }
          }
        ]
      }
    }

    const getEventosDeDesconto = (naturezaEvento = 'DESCONTO') => {
      let eventos = []
      if (feriasDados.qtdPeriodos === 2) {
        eventos = feriasDados.eventosFerias
          .filter(evento => evento.natureza.value === naturezaEvento)
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo)
          .map(evento => {
            return [
              {
                layout: layoutRemovePadding,
                table: {
                  widths: [200, 50],
                  body: [
                    [
                      {
                        text: `${zeroPad(evento?.rubricaCodigo || 0)} - ${
                          limitString(evento?.rubricaDescricao, 32) || 0
                        }`,
                        bold: false,
                        alignment: 'left',
                        fontSize: 9
                      },
                      {
                        text: evento?.descReferencia,
                        bold: false,
                        alignment: 'left',
                        fontSize: 9
                      }
                    ]
                  ]
                }
              },
              {
                text: ''
              },
              {
                text: evento.mes1Valor
                  ? formatPriceWithoutBRL(evento.mes1Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },
              {
                text: ''
              },
              {
                text: evento.mes2Valor
                  ? formatPriceWithoutBRL(evento.mes2Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },
              {
                text: ''
              },
              {
                text: evento.valor
                  ? formatPriceWithoutBRL(evento.valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              }
            ]
          })
        return [
          {
            marginTop: 5,
            layout: {
              hLineWidth: function () {
                return 0
              },
              vLineWidth: function () {
                return 0
              }
            },
            table: {
              widths: [255, 4, 90, 0.1, 90, 0.1, 68],
              body: [
                [
                  {
                    text: `${naturezaEvento}S`,
                    bold: true,
                    marginRight: 10,
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  }
                ]
              ]
            }
          },
          {
            marginTop: 2,
            marginLeft: 4,
            layout: 'noBorders',
            table: {
              widths: [255, 4, 90, 0.1, 90, 0.1, 68],
              body: [
                [
                  {
                    text: [
                      {
                        text: 'DESCRIÇÃO                                                             ',
                        bold: true,
                        alignment: 'left',
                        preserveLeadingSpaces: true,
                        fontSize: 9
                      },
                      {
                        text: 'REFERÊNCIA',
                        bold: true,
                        alignment: 'left',
                        fontSize: 9
                      }
                    ]
                  },
                  {
                    text: ''
                  },
                  {
                    text: feriasDados.eventosFerias[0].mes1Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: feriasDados.eventosFerias[0].mes2Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: 'VALOR',
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  }
                ],
                ...eventos
              ]
            }
          }
        ]
      } else if (feriasDados.qtdPeriodos === 3) {
        eventos = feriasDados.eventosFerias
          .filter(evento => evento.natureza.value === naturezaEvento)
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo)
          .map(evento => {
            return [
              {
                layout: layoutRemovePadding,
                table: {
                  widths: [170, 60],
                  body: [
                    [
                      {
                        text: `${zeroPad(evento?.rubricaCodigo || 0)} - ${
                          limitString(evento?.rubricaDescricao, 32) || 0
                        }`,
                        bold: false,
                        alignment: 'left',
                        fontSize: 9
                      },
                      {
                        text: evento?.descReferencia,
                        bold: false,
                        alignment: 'left',
                        fontSize: 9
                      }
                    ]
                  ]
                }
              },
              {
                text: ''
              },
              {
                text: evento.mes1Valor
                  ? formatPriceWithoutBRL(evento.mes1Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },

              {
                text: evento.mes2Valor
                  ? formatPriceWithoutBRL(evento.mes2Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },
              {
                text: evento.mes3Valor
                  ? formatPriceWithoutBRL(evento.mes3Valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              },

              {
                text: evento.valor
                  ? formatPriceWithoutBRL(evento.valor || 0)
                  : '-',
                bold: false,
                alignment: 'right',
                fontSize: 9
              }
            ]
          })
        return [
          {
            marginTop: 5,
            layout: {
              hLineWidth: function () {
                return 0
              },
              vLineWidth: function () {
                return 0
              }
            },
            table: {
              widths: [230, 4, 75, 75, 75, 60],
              body: [
                [
                  {
                    text: `${naturezaEvento}S`,
                    bold: true,
                    marginRight: 10,
                    fillColor: '#f2f6f9',
                    fontSize: 9
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  },
                  {
                    text: ''
                  }
                ]
              ]
            }
          },
          {
            marginTop: 2,
            marginLeft: 4,
            layout: 'noBorders',
            table: {
              widths: [230, 4, 75, 75, 75, 60],
              body: [
                [
                  {
                    text: [
                      {
                        text: 'DESCRIÇÃO                                                   ',
                        bold: true,
                        alignment: 'left',
                        preserveLeadingSpaces: true,
                        fontSize: 9
                      },
                      {
                        text: 'REFERÊNCIA',
                        bold: true,
                        alignment: 'left',
                        fontSize: 9
                      }
                    ]
                  },
                  {
                    text: ''
                  },
                  {
                    text: feriasDados.eventosFerias[0].mes1Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },

                  {
                    text: feriasDados.eventosFerias[0].mes2Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },

                  {
                    text: feriasDados.eventosFerias[0].mes3Dias,
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  },

                  {
                    text: 'VALOR',
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  }
                ],
                ...eventos
              ]
            }
          }
        ]
      } else {
        eventos = feriasDados.eventosFerias
          .filter(evento => evento.natureza.value === naturezaEvento)
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo)
          .map(evento => {
            return [
              {
                text: `${zeroPad(evento?.rubricaCodigo || 0)} - ${
                  evento?.rubricaDescricao || 0
                }`,
                bold: false,
                fontSize: 9
              },
              {
                text: evento?.descReferencia,
                bold: false,
                alignment: 'left',
                fontSize: 9
              },
              {
                text: formatPriceWithoutBRL(evento?.valor || 0),
                bold: false,
                alignment: 'right',
                fontSize: 9
              }
            ]
          })
        return [
          {
            marginTop: 5,
            layout: {
              hLineWidth: function () {
                return 0
              },
              vLineWidth: function () {
                return 0
              },
              fillColor: function () {
                return '#f2f6f9'
              }
            },
            table: {
              widths: ['*'],
              margin: 0,
              body: [
                [
                  {
                    text: `${naturezaEvento}S`,
                    bold: true,
                    fontSize: 9
                  }
                ]
              ]
            }
          },
          {
            marginTop: 2,
            marginLeft: 4,
            layout: 'noBorders',
            table: {
              widths: ['*', 140, 75],
              body: [
                [
                  {
                    text: 'DESCRIÇÃO',
                    bold: true,
                    alignment: 'left',
                    fontSize: 9
                  },
                  {
                    text: 'REFERÊNCIA',
                    bold: true,
                    alignment: 'left',
                    fontSize: 9
                  },
                  {
                    text: 'VALOR',
                    bold: true,
                    alignment: 'right',
                    fontSize: 9
                  }
                ],
                ...eventos
              ]
            }
          }
        ]
      }
    }

    let page = {
      info: {
        title: `Recibo de Férias - ${feriasDados.nomeTrabalhador} - ${feriasDados.matricula}`,
        author: 'Calculato Sistemas LTDA',
        keywords: 'Recibo de Férias'
      },
      pageSize: 'A4',
      pageMargins: [15, 15, 15, 15],
      defaultStyle: {
        font: 'NotoSans'
      },
      content: [
        {
          text: 'RECIBO DE FÉRIAS',
          bold: true,
          alignment: 'center',
          fontSize: 17,
          marginBottom: 5,
          color: '#333333'
        },
        {
          marginTop: 5,
          layout: {
            hLineWidth: function () {
              return 1.5
            },
            vLineWidth: function () {
              return 0
            },

            hLineColor: function () {
              return '#f2f6f9'
            }
          },
          table: {
            widths: ['auto', '*'],
            body: [
              [
                logoEmpregador
                  ? {
                      image: logoEmpregador,
                      fit: ['auto', 35],
                      marginTop: 5
                    }
                  : {
                      text: ''
                    },
                {
                  alignment: 'left',
                  marginTop: 1,
                  text: [
                    {
                      text: limitString(feriasDados.localDenominacao, 60) + '\n',
                      bold: true,
                      fontSize: 12
                    },
                    {
                      text: `${feriasDados.localInscricao}\n`,
                      fontSize: 10,
                      bold: false
                    },
                    {
                      text: `${limitString(feriasDados.localEndereco, 80)}`,
                      fontSize: 10,
                      bold: false
                    }
                  ]
                }
              ]
            ]
          }
        },
        {
          marginTop: 7,
          layout: 'noBorders',
          table: {
            widths: ['*', 'auto'],
            body: [
              [
                {
                  alignment: 'left',
                  text: [
                    {
                      text: limitString(`${feriasDados.matricula} - ${feriasDados.nomeTrabalhador}`, 100) + '\n',
                      bold: true,
                      fontSize: 11,
                      lineHeight: 1.6
                    },
                    {
                      text: limitString(`${feriasDados.cboFuncao} - ${feriasDados.descricaoFuncao} \n`, 60),
                      fontSize: 9,
                      lineHeight: 1.6
                    },
                    {
                      text: [
                        {
                          text: 'Salário Base: ',
                          bold: true,
                          fontSize: 9
                        },
                        {
                          text: `${formatPriceWithoutBRL(
                            feriasDados.salarioBase
                          )} ${feriasDados.undSalario}`,
                          bold: false,
                          fontSize: 9
                        }
                      ]
                    }
                  ]
                },

                {
                  text: [
                    {
                      text: 'Período Aquisitivo: ',
                      bold: true,
                      fontSize: 9
                    },
                    {
                      text: `${formatDayPtBr(
                        feriasDados.dtInicioPeriodoAquisitivo
                      )} até ${formatDayPtBr(
                        feriasDados.dtFimPeriodoAquisitivo
                      )} \n`,
                      bold: false,
                      fontSize: 9,
                      lineHeight: 1.2
                    },
                    {
                      text: 'Período de Gozo: ',
                      bold: true,
                      fontSize: 9
                    },
                    {
                      text: `${formatDayPtBr(
                        feriasDados.dtInicio
                      )} até ${formatDayPtBr(feriasDados.dtFim)} \n`,
                      bold: false,
                      fontSize: 9,
                      lineHeight: 2.4
                    },
                    {
                      text: 'Data de retorno ao Trabalho: ',
                      bold: true,
                      fontSize: 9
                    },
                    {
                      text: ` ${formatDayPtBr(feriasDados.dtRetorno)} \n`,
                      bold: false,
                      fontSize: 9
                    }
                  ]
                }
              ]
            ]
          }
        },
        ...getEventosDeProvento('PROVENTO'),
        ...getEventosDeDesconto('DESCONTO'),
        {
          marginTop: 10,
          layout: 'noBorders',
          table: {
            widths: ['auto', 100],
            body: [
              [
                {
                  text: 'Total dos Proventos',
                  // bold: true,
                  fontSize: 11,
                  lineHeight: 0.7,
                  italics: true,
                  bold: true,
                  font: 'NotoSans'
                },
                {
                  alignment: 'right',
                  text: totalProventos,
                  fontSize: 11,
                  lineHeight: 0.7,
                  italics: true,
                  bold: true
                }
              ],
              [
                {
                  text: 'Total dos Descontos',
                  italics: true,
                  bold: true,
                  fontSize: 11
                },
                {
                  alignment: 'right',
                  text: totalDescontos,
                  italics: true,
                  bold: true,
                  fontSize: 11
                }
              ],
              [
                {
                  text: 'Valor a Receber',
                  bold: true,
                  fontSize: 12
                },
                {
                  alignment: 'right',
                  text: valorReceber,
                  bold: true,
                  fontSize: 12
                }
              ]
            ]
          }
        },
        {
          marginTop: 15,
          fontSize: 9,
          text: `Pelo presente comunicamos-lhe, conforme a Lei, ser-lhe-ão concedidas férias relativas ao período acima descrito e, à sua disposição, fica o líquido de: R$ ${valorReceber} (${valorPorExtenso(valorReceber)}), a ser paga adiantadamente.`
        },
        {
          marginTop: 25,
          layout: 'noBorders',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: `${feriasDados.localCidadeUf}, ${formatDayPtBr(
                    feriasDados.dtPagamento
                  )} \n \n`,
                  fontSize: 9,
                  marginRight: 10
                },
                {
                  text: 'Ciente: ',
                  fontSize: 9,
                  marginLeft: 10
                }
              ],
              [
                {
                  table: {
                    widths: ['*'],
                    body: [
                      [
                        {
                          text: '',
                          lineHeight: 0,
                          borderColor: ['#000000']
                        }
                      ],
                      [
                        {
                          text: '',
                          lineHeight: 0,
                          borderColor: ['#000000']
                        }
                      ]
                    ]
                  },
                  layout: {
                    // @ts-ignore
                    hLineWidth: function (i, node) {
                      return i === 0 || i === node.table.body.length ? 0 : 0.5
                    },
                    vLineWidth: function () {
                      return 0
                    }
                  },
                  marginBottom: -15,
                  marginRight: 10
                },
                {
                  marginBottom: -15,
                  marginLeft: 10,
                  table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [
                      [
                        {
                          text: '',
                          borderColor: ['#000000']
                        }
                      ],
                      [
                        {
                          text: '',
                          borderColor: ['#000000']
                        }
                      ]
                    ]
                  },
                  layout: {
                    // @ts-ignore
                    hLineWidth: function (i, node) {
                      return i === 0 || i === node.table.body.length ? 0 : 0.5
                    },
                    vLineWidth: function () {
                      return 0
                    }
                  }
                }
              ],
              [
                {
                  text: `${limitString(feriasDados.localDenominacao, 60) ?? ''}`,
                  alignment: 'center',
                  fontSize: 9,
                  marginRight: 10
                },
                {
                  text: `${feriasDados.nomeTrabalhador}`,
                  alignment: 'center',
                  fontSize: 9,
                  marginLeft: 10
                }
              ]
            ]
          }
        },
        {
          marginTop: 15,
          marginBottom: 15,
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: '',
                  lineHeight: 0,
                  borderColor: ['#a0aec0', '#a0aec0', '#a0aec0', '#a0aec0']
                }
              ],
              [
                {
                  text: '',
                  lineHeight: 0,
                  borderColor: ['#a0aec0']
                }
              ]
            ]
          },
          layout: {
            // @ts-ignore
            hLineWidth: function (i, node) {
              return i === 0 || i === node.table.body.length ? 0 : 1.5
            },
            vLineWidth: function () {
              return 0
            },
            hLineStyle: function () {
              return { dash: { length: 5, space: 5 } } // Definindo a linha pontilhada
            }
          }
        },
        {
          marginBottom: 5,
          text: 'RECIBO DE FÉRIAS',
          bold: true,
          alignment: 'center',
          fontSize: 13,
          color: '#333333'
        },
        {
          text: ` ${limitString(feriasDados.localEndereco, 80)}`,
          bold: true,
          fontSize: 9,
          marginBottom: 10
        },
        {
          text: ` Recebi o valor de: ${valorReceber} (${valorPorExtenso(
            valorReceber
          )}), que me é paga adiantadamente por motivo de minhas férias regulamentares, ora concedidas, e que vou gozar conforme a descrição acima, tudo conforme aviso que recebi em tempo hábil ao qual dei meu 'Ciente'. Para clareza e documento, firmo o presente recibo, dando ao empregador plena e geral quitação.`,
          fontSize: 9,
          alignment: 'justify',
          marginBottom: 10
        },
        {
          marginTop: 25,
          layout: 'noBorders',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  table: {
                    widths: ['*'],
                    body: [
                      [
                        {
                          text: '',
                          lineHeight: 0,
                          border: [false, false, false, false]
                        }
                      ],
                      [
                        {
                          text: '',
                          lineHeight: 0,
                          border: [false, false, false, false]
                        }
                      ]
                    ]
                  },
                  layout: {
                    // @ts-ignore

                    hLineWidth: function (i, node) {
                      return i === 0 || i === node.table.body.length ? 0 : 0.5
                    },
                    vLineWidth: function () {
                      return 0
                    }
                  },
                  marginBottom: -15,
                  marginRight: 10
                },
                {
                  marginBottom: -15,
                  marginLeft: 10,
                  table: {
                    headerRows: 1,
                    widths: ['*'],
                    body: [
                      [
                        {
                          text: '',
                          borderColor: ['#000000']
                        }
                      ],
                      [
                        {
                          text: '',
                          borderColor: ['#000000']
                        }
                      ]
                    ]
                  },
                  layout: {
                    // @ts-ignore

                    hLineWidth: function (i, node) {
                      return i === 0 || i === node.table.body.length ? 0 : 0.5
                    },
                    vLineWidth: function () {
                      return 0
                    }
                  }
                }
              ],
              [
                {
                  text: `${feriasDados.localCidadeUf}, ${formatDayPtBr(
                    feriasDados.dtPagamento
                  )}`,
                  alignment: 'left',
                  fontSize: 9,
                  marginRight: 10
                },
                {
                  text: `${feriasDados.nomeTrabalhador}`,
                  alignment: 'center',
                  fontSize: 9,
                  marginLeft: 10
                }
              ]
            ]
          }
        }
      ]
    }
    return page
  }

  const onOpenWindowPrint = async () => {
    const { openPdfNewTab, downloadPDF } = usePdfMake()

    let logoEmpregador = null
    let feriasDados = null
    const respFerias = await apolloClient.query({
      query: getReciboFeriasSource,
      variables: {
        reciboFeriasRequest: {
          dtInicio
        },
        idEmpregador
      },
      fetchPolicy: 'cache-first'
    })
    if (respFerias.data) {
      let apiFerias = respFerias.data.reciboFeriasDataSource
      feriasDados = {
        ...apiFerias,
        eventosFerias: apiFerias.eventosFerias.map(evfl => {
          return Object.assign({
            ...evfl,
            edit: false,
            isFixed: false,
            isLoading: false,
            loadingRevertEvento: false,
            tooltipMsg: null
          })
        })
      }

    logoEmpregador = await imageUrlToBase64(respFerias.data.reciboFeriasDataSource.logoUrl);
    }

    if (typeCreate === 'newTab') {
      openPdfNewTab(
        docDefinition(
          feriasDados,
          logoEmpregador
        )
      )
    } else {
      downloadPDF(
      `Recibo de Férias de ${feriasDados.nomeTrabalhador} -
      ${formatDayPtBr(
        feriasDados.dtInicio
      )} até ${formatDayPtBr(feriasDados.dtFim)}`,
      docDefinition(
        feriasDados,
        logoEmpregador
      )
      )
    }
  }
  await onOpenWindowPrint()
}
