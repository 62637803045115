// const Cookies = require('js-cookie')
import Cookies from "js-cookie";

export const getCookie = key => {
  return Cookies.get(key);
};
export const saveCookie = (key, value, options) => {
  return Cookies.set(key, value, options);
};
export const removeCookie = key => {
  return Cookies.remove(key);
};
