import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { capitalizeFirstLetter } from "./useCapitalizeFirstLetter";

export const formatDateApi = dateApi => {
  if (typeof dateApi === "string") {
    if (
      dateApi.search(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/g) !== -1
    ) {
      // verify if is yyyy-mm-dd
      const modernType = `${dateApi.split("-")[1]}-${dateApi.split("-")[2]}-${
        dateApi.split("-")[0]
      }`; // mm-dd-yyyy
      return modernType;
    }
    return dateApi;
  }
  return dateApi;
};

export const formatMonthAndYearPtBr = monthYear => {
  if (monthYear) {
    let year = monthYear.toString().substr(0, 4);
    let month = monthYear.toString().substr(4, 2);
    let date = `${Number(year)}/${Number(month)}/1`;
    return capitalizeFirstLetter(
      format(new Date(date), "MMMM/yyyy", { locale: ptBR })
    );
  }
  return null;
};

export const formatMonthAndYear = date => {
  let getMonth = date.toString().substr(4, 2);
  if (getMonth === "13") {
    let year = date?.substr(0, 4);
    return `Décimo Terceiro - ${year}`;
  } else {
    return format(new Date(date), "MMMM - yyyy", { locale: ptBR });
  }
};

export const formatDateWithHours = date => {
  return format(new Date(formatDateApi(date)), "dd/MM/yyyy 'às' HH:mm");
};

export const formatPeriodMonthAndYear = date => {
  return format(new Date(date), "MM/yyyy", { locale: ptBR });
};

export const formatDayPtBr = day => {
  if (day && typeof day === "string") {
    return day.substr(0, 10).split("-").reverse().join("/");
  }
  return null;
};

export const formatDay = day => {
  if (day) {
    return format(new Date(day), "yyyy-MM-dd");
  }
  return null;
};

export const formatDate = date => {
  return format(new Date(date), "dd/MM/yyyy");
};

export const formatPeriod = date => {
  let getMonth = date.toString().substr(4, 2);
  if (getMonth === "13") {
    return Number(date);
  }
  if (date) {
    return Number(format(new Date(date), "yyyyMM"));
  }
  return null;
};
