export const zeroPad = (num) => num.toString().padStart(3, '0')

export const limitString = (varString, comprimentoMaximo) => {
  if (varString) {
    if (varString.length <= comprimentoMaximo) {
      return varString
    }

    let substring = varString.substring(0, comprimentoMaximo)
    let ultimoEspaco = substring.lastIndexOf(' ')

    if (ultimoEspaco === -1) {
      return substring
    }

    return substring.substring(0, ultimoEspaco)
  } else {
    return ''
  }
}
