<template>
  <div v-if="demonstrativosDisplay.length">
    <div>
      <p class="fs-5 text-dark fw-bold d-md-none">Férias</p>
      <div class="overflow-auto ct-scrollbar-thin-horizontal">
        <div class="accordion-min-width w-100">
          <CtAccordion
            v-for="(accordion, indexAccordion) in demonstrativosDisplay"
            :key="indexAccordion"
            v-model="accordion.visible"
          >
            <template #trigger>
              <div class="d-flex flex-row w-100">
                <div class="d-flex flex-column col-4 me-8">
                  <span href="#" class="fs-6 text-secondary"> Período </span>
                  <span href="#" class="fs-5 fw-normal text-dark">{{
                    `${formatDayPtBr(accordion.dtInicio)} à ${formatDayPtBr(
                      accordion.dtFim
                    )}`
                  }}</span>
                </div>
                <div class="d-flex flex-column col-2 me-8">
                  <span href="#" class="fs-6 text-secondary">
                    Dias Gozados
                  </span>
                  <span href="#" class="fs-5 fw-normal text-dark">
                    {{ accordion.qntDias }}
                  </span>
                </div>
                <div class="d-flex flex-column col-3 me-8">
                  <span href="#" class="fs-6 text-secondary">
                    Líquido a receber
                  </span>
                  <span href="#" class="fs-5 fw-normal text-dark">
                    {{ formatMoney(accordion.liquido) }}
                  </span>
                </div>
                <div class="d-flex flex-row w-100 justify-content-end pe-5">
                  <DownloadDemonstrativo
                    :id="accordion.idFeriasGozo"
                    :periodo="accordion.dtInicio"
                    tipo-recibo="FERIAS"
                  />
                </div>
              </div>
            </template>
            <template #content>
              <ViewQuadroDemonstrativoFerias
                v-model="accordion.visible"
                :id-ferias="accordion.idFeriasGozo"
              />
            </template>
          </CtAccordion>
        </div>
      </div>
    </div>
    <div class="d-flex mb-2 mt-4 w-100">
      <div class="fw-bold fs-6">
        <span>
          Mostrando {{ demonstrativosDisplay.length }} de
          {{ totalDemonstrativos }}.
        </span>
      </div>
      <div
        v-if="showButtonLoadMore"
        class="text-primary ms-auto text-end similar-pointer d-flex align-items-center"
        @click="loadMore"
      >
        <i
          class="mdi mdi-rotate-3d-variant mdi-18px"
          :class="`${loadingDemonstrativos ? 'mdi-spin' : ''}`"
        ></i>
        <span class="ms-1 fw-bold">Carregar mais registros</span>
      </div>
      <div />
    </div>
  </div>

  <NoDataList
    v-if="!demonstrativosDisplay.length && !loadingDemonstrativos"
    text="Não possui demonstrativo de férias."
  />

  <div v-if="!demonstrativosDisplay.length && loadingDemonstrativos">
    <div class="d-flex flex-column w-100 align-items-center justify-center">
      <div class="spinner-border text-primary font-size-10 m-1" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { formatMonthAndYearPtBr } from "@/composables/useFormatDate";
import CtAccordion from "@/components/CtAccordion/CtAccordion.vue";
import { formatMoney } from "@/composables/useFormatMoney";
import { useLazyQuery } from "@vue/apollo-composable";
import { getDemosFeriasDisplay } from "@/state/graphql/demonstrativos";
import { formatDayPtBr } from "@/composables/useFormatDate";
import ViewQuadroDemonstrativoFerias from "./components/ViewQuadroDemonstrativoFerias";
import DownloadDemonstrativo from "./components/DownloadDemonstrativo";
import NoDataList from "./components/NoDataList.vue";

export default {
  name: "FeriasDisplayView",
  components: {
    CtAccordion,
    ViewQuadroDemonstrativoFerias,
    DownloadDemonstrativo,
    NoDataList,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    /*
    const demonstrativosDisplay = ref([
      {
        visible: false,
        dtInicio: "2022-12-02",
        dtFim: "2022-12-31",
        salario: 3000,
        qntDias: 10,
      },
    ]);
    */
    const demonstrativosDisplay = ref([]);

    const showButtonLoadMore = ref(false);

    /* Queries */
    const {
      result: resultDemonstrativos,
      onResult: onResultDemonstrativos,
      loading: loadingDemonstrativos,
      load: loadDemonstrativos,
      // error: errorOnDemonstrativos,
      //refetch: refetchDemonstrativos,
      fetchMore,
    } = useLazyQuery(getDemosFeriasDisplay, () => ({}), {
      fetchPolicy: "network-only",
    });

    onResultDemonstrativos(result => {
      let apiDemonstrativos = result.data.feriasTrabalhadorDisplayPage.data;
      showButtonLoadMore.value =
        result.data.feriasTrabalhadorDisplayPage.cursor;
      demonstrativosDisplay.value = apiDemonstrativos.map(demo => {
        return Object.assign({ ...demo, id: demo.id, visible: false });
      });
    });

    const totalDemonstrativos = computed(() => {
      if (resultDemonstrativos.value) {
        return resultDemonstrativos.value.feriasTrabalhadorDisplayPage.total;
      }
      return 0;
    });

    function loadMore() {
      fetchMore({
        variables: {
          cursor:
            resultDemonstrativos.value.feriasTrabalhadorDisplayPage.cursor,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          return {
            ...previousResult,
            feriasTrabalhadorDisplayPage: {
              ...previousResult.feriasTrabalhadorDisplayPage,
              cursor: fetchMoreResult.feriasTrabalhadorDisplayPage.cursor,
              data: [
                ...previousResult.feriasTrabalhadorDisplayPage.data,
                ...fetchMoreResult.feriasTrabalhadorDisplayPage.data,
              ],
            },
          };
        },
      });
    }

    return {
      demonstrativosDisplay,
      formatMonthAndYearPtBr,
      formatMoney,
      loadingDemonstrativos,
      loadDemonstrativos,
      totalDemonstrativos,
      showButtonLoadMore,
      formatDayPtBr,
      loadMore,
    };
  },
  watch: {
    modelValue(nextValue) {
      this.$emit("update:modelValue", nextValue);
      if (nextValue && !this.demonstrativosDisplay.length)
        this.loadDemonstrativos();
    },
  },
};
</script>
