<template>
  <div class="d-flex justify-center align-items-center">
    <div
      class="border border-primary px-1 py-1 rounded"
      @click.stop="generateRecibo"
      style="margin-right: 1rem"
      title="Visualizar PDF"
    >
      <div
        v-if="generatingRecibo"
        class="d-flex flex-column w-100 align-items-center justify-center"
      >
        <div
          class="spinner-border spinner-border-sm text-primary m-1"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <i v-else class="mdi mdi-file-pdf font-size-18 text-primary"></i>
    </div>

    <div
      class="border border-primary px-1 py-1 rounded"
      @click.stop="onDownloadRecibo"
      title="Baixar PDF"
    >
      <i
        v-if="!isWorking"
        class="mdi mdi-arrow-collapse-down font-size-18 text-primary"
      ></i>

      <div
        v-else
        class="d-flex flex-column w-100 align-items-center justify-center"
      >
        <div
          class="spinner-border spinner-border-sm text-primary m-1"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed, ref } from "vue";
import { getters } from "@/state/modules/trabalhador.store";
import Toast from "@/components/Toast/Toast.vue";
import { TYPE, useToast } from "vue-toastification";
import { gerarReciboFolhaPagamento } from "../recibos/pdfs/reciboFolhaPagamentoPDF";
import { gerarReciboDecimoTerceiro } from "../recibos";
import { pdfMakeReciboFerias } from "../recibos/pdfs/reciboFeriasPDF";
import { pdfMakeTermoRescisao } from "../recibos/pdfs/reciboRescisaoPDF";
import { pdfMakeReciboAdiantamentos } from "../recibos/pdfs/reciboAdiantamentoPDF";

export default {
  props: {
    periodo: {
      type: [Number, String, Object],
      required: true,
    },
    tipoRecibo: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup() {
    const trabalhador = computed(() => getters.currentTrabalhador.value);
    const isWorking = ref(false);
    const generatingRecibo = ref(false);

    const toast = useToast();

    return {
      trabalhador,
      toast,
      isWorking,
      generatingRecibo,
    };
  },
  methods: {
    async onDownload() {
      try {
        this.isWorking = true;
        let valuesMutation = {};
        if (this.tipoRecibo === "DECIMO_TERCEIRO") {
          valuesMutation = {
            idEmpregador: this.trabalhador.idEmpregador,
            ano: this.periodo.ano,
            parcela: this.periodo.parcela,
          };
        } else {
          valuesMutation = {
            idEmpregador: this.trabalhador.idEmpregador,
            periodo: this.periodo,
          };
        }
        await this.mutateCreateDemonstrativo({
          ...valuesMutation,
        });
      } catch {
        this.toast(
          {
            component: Toast,
            props: {
              title: "Não foi possível salvar!",
              text: this.errorMsg,
            },
          },
          { type: TYPE.ERROR }
        );
      } finally {
        this.isWorking = false;
      }
    },
    async onDownloadRecibo() {
      try {
        this.isWorking = true;
        if (this.tipoRecibo === "FOLHA") {
          await gerarReciboFolhaPagamento(
            this.periodo,
            this.trabalhador.idEmpregador,
            "download"
          );
        } else if (this.tipoRecibo === "DECIMO_TERCEIRO") {
          await gerarReciboDecimoTerceiro(
            this.trabalhador.idEmpregador,
            this.periodo.ano,
            this.periodo.parcela,
            "download"
          );
        } else if (this.tipoRecibo === "FERIAS") {
          await pdfMakeReciboFerias(
            this.periodo,
            this.trabalhador.idEmpregador,
            "download"
          );
        } else if (this.tipoRecibo === "RESCISAO") {
          await pdfMakeTermoRescisao(
            this.periodo,
            this.trabalhador.idEmpregador,
            "download"
          );
        } else if (this.tipoRecibo === "ADIANTAMENTO") {
          await pdfMakeReciboAdiantamentos(
            this.periodo,
            this.trabalhador.idEmpregador,
            "download"
          );
        }
      } catch (error) {
        console.error(error);
        this.toast(
          {
            component: Toast,
            props: {
              title: "Não foi possível Baixar!",
              text: this.errorMsg,
            },
          },
          { type: TYPE.ERROR }
        );
      } finally {
        this.isWorking = false;
      }
    },
    async generateRecibo() {
      try {
        this.generatingRecibo = true;
        if (this.tipoRecibo === "FOLHA") {
          await gerarReciboFolhaPagamento(
            this.periodo,
            this.trabalhador.idEmpregador,
            "newTab"
          );
        } else if (this.tipoRecibo === "DECIMO_TERCEIRO") {
          await gerarReciboDecimoTerceiro(
            this.trabalhador.idEmpregador,
            this.periodo.ano,
            this.periodo.parcela,
            "newTab"
          );
        } else if (this.tipoRecibo === "FERIAS") {
          await pdfMakeReciboFerias(
            this.periodo,
            this.trabalhador.idEmpregador,
            "newTab"
          );
        } else if (this.tipoRecibo === "RESCISAO") {
          await pdfMakeTermoRescisao(
            this.periodo,
            this.trabalhador.idEmpregador,
            "newTab"
          );
        } else if (this.tipoRecibo === "ADIANTAMENTO") {
          await pdfMakeReciboAdiantamentos(
            this.periodo,
            this.trabalhador.idEmpregador,
            "newTab"
          );
        }
      } catch (error) {
        console.error(error);
        this.toast(
          {
            component: Toast,
            props: {
              title: "Não foi possível Baixar!",
              text: this.errorMsg,
            },
          },
          { type: TYPE.ERROR }
        );
      } finally {
        this.generatingRecibo = false;
      }
    },
  },
};
</script>
