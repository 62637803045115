const fetchBlob = async (url) => {
  const response = await fetch(`https://corsproxy.io/?url=${url}`)
  return response.blob()
}

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}

const convertToPngBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      const canvas = document.createElement('canvas')
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)
      resolve(canvas.toDataURL('image/png'))
    }
    img.onerror = reject
    img.src = URL.createObjectURL(blob)
  })
}

export const convertImageBase64FromUrl = async (url) => {
  const blob = await fetchBlob(url)
  return blobToBase64(blob)
}

export const convertToPngAndBase64 = async (url) => {
  const blob = await fetchBlob(url)
  return convertToPngBase64(blob)
}

export const imageUrlToBase64 = async (url) => {
  if (!url) return null
  return url.match(/\.(png|jpg|jpeg)$/i)
    ? convertImageBase64FromUrl(url)
    : convertToPngAndBase64(url)
}

export const base64EmpregadorLogo = async () => {
  const imgToExport = document.getElementById('logoEmpregador')
  if (!imgToExport?.src) return null

  const { src } = imgToExport
  const isSupportedFormat = /\.(png|jpeg|jpg)$/i.test(src)
  const defaultImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAMAAABHPGVmAAAAclBMVEU...'

  if (src === defaultImg || src.includes('assets/images/company.png')) return null
  if (src.startsWith('data:image/png')) return src

  if (src.startsWith('http')) {
    const blob = await fetchBlob(src)
    return isSupportedFormat ? blobToBase64(blob) : convertToPngBase64(blob)
  }

  return null
}
