<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center w-100 mt-4 md:mt-0"
  >
    <svg
      width="100"
      height="100"
      viewBox="0 0 58 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_118_4532)">
        <path
          d="M36.7358 12.5942H21.2772C20.9249 12.5946 20.5872 12.7347 20.3381 12.9838C20.089 13.2329 19.9489 13.5707 19.9484 13.9229V48.2799L19.7713 48.334L15.979 49.4953C15.7993 49.5501 15.6052 49.5314 15.4393 49.4432C15.2734 49.355 15.1492 49.2046 15.0941 49.0249L3.81388 12.1787C3.75899 11.999 3.7777 11.8048 3.8659 11.6389C3.9541 11.473 4.10458 11.3488 4.28426 11.2938L10.1281 9.5044L27.0697 4.31874L32.9135 2.52936C33.0024 2.50199 33.0959 2.49242 33.1885 2.50121C33.2811 2.51 33.3711 2.53697 33.4533 2.58058C33.5355 2.62419 33.6083 2.68358 33.6675 2.75535C33.7268 2.82712 33.7712 2.90986 33.7984 2.99885L36.6818 12.417L36.7358 12.5942Z"
          fill="#F2F2F2"
        />
        <path
          d="M40.1092 12.4171L36.6341 1.06601C36.5762 0.876877 36.4817 0.700993 36.3559 0.548407C36.2301 0.39582 36.0754 0.269521 35.9008 0.176726C35.7262 0.0839318 35.5349 0.0264594 35.3381 0.00759568C35.1412 -0.011268 34.9425 0.00884663 34.7534 0.0667881L26.5373 2.58167L9.59672 7.76822L1.38062 10.284C0.998946 10.4012 0.679363 10.665 0.492022 11.0176C0.304681 11.3702 0.264887 11.7827 0.381377 12.1646L12.2586 50.9579C12.3533 51.2661 12.5442 51.536 12.8034 51.7277C13.0627 51.9195 13.3765 52.0232 13.699 52.0235C13.8483 52.0236 13.9967 52.0012 14.1392 51.9571L19.7714 50.2332L19.9485 50.1783V49.9932L19.7714 50.0472L14.087 51.7879C13.7501 51.8906 13.3862 51.8555 13.0752 51.6902C12.7641 51.525 12.5313 51.2431 12.4278 50.9065L0.551493 12.1123C0.500211 11.9455 0.482338 11.7702 0.498897 11.5965C0.515455 11.4228 0.566118 11.254 0.647989 11.0999C0.729859 10.9458 0.841328 10.8093 0.97601 10.6984C1.11069 10.5874 1.26594 10.5041 1.43287 10.4532L9.64896 7.93741L26.5896 2.75175L34.8057 0.235984C34.9324 0.197344 35.064 0.177642 35.1964 0.177519C35.4805 0.178156 35.7569 0.269748 35.9852 0.438877C36.2134 0.608006 36.3816 0.845785 36.4649 1.11739L39.9241 12.4171L39.979 12.5942H40.1632L40.1092 12.4171Z"
          fill="#3F3D56"
        />
        <path
          d="M11.1826 11.3227C11.0119 11.3226 10.8457 11.2677 10.7084 11.1662C10.5711 11.0647 10.47 10.9219 10.4198 10.7587L9.27884 7.03185C9.24818 6.93174 9.23754 6.82657 9.24753 6.72235C9.25752 6.61813 9.28795 6.5169 9.33707 6.42443C9.38618 6.33197 9.45304 6.25009 9.53381 6.18347C9.61457 6.11685 9.70767 6.06679 9.80779 6.03615L25.393 1.26473C25.5952 1.20303 25.8136 1.22407 26.0003 1.32323C26.187 1.42239 26.3267 1.59157 26.3888 1.79363L27.5298 5.52053C27.5914 5.72271 27.5704 5.94109 27.4712 6.12776C27.372 6.31444 27.2029 6.45415 27.0009 6.51627L11.4156 11.2877C11.3401 11.3109 11.2616 11.3227 11.1826 11.3227Z"
          :fill="getBGMenuColor"
        />
        <path
          d="M17.1596 3.98229C18.1381 3.98229 18.9313 3.18909 18.9313 2.21063C18.9313 1.23217 18.1381 0.438965 17.1596 0.438965C16.1811 0.438965 15.3879 1.23217 15.3879 2.21063C15.3879 3.18909 16.1811 3.98229 17.1596 3.98229Z"
          :fill="getBGMenuColor"
        />
        <path
          d="M17.1596 3.33237C17.7792 3.33237 18.2815 2.83009 18.2815 2.2105C18.2815 1.5909 17.7792 1.08862 17.1596 1.08862C16.54 1.08862 16.0377 1.5909 16.0377 2.2105C16.0377 2.83009 16.54 3.33237 17.1596 3.33237Z"
          fill="white"
        />
        <path
          d="M53.6987 51.5708H23.7576C23.558 51.5706 23.3666 51.4912 23.2254 51.3501C23.0843 51.2089 23.0049 51.0175 23.0046 50.8179V14.9417C23.0049 14.742 23.0843 14.5507 23.2254 14.4095C23.3666 14.2683 23.558 14.1889 23.7576 14.1887H53.6987C53.8984 14.189 54.0897 14.2684 54.2309 14.4095C54.3721 14.5507 54.4515 14.7421 54.4517 14.9417V50.8179C54.4515 51.0175 54.372 51.2089 54.2309 51.35C54.0897 51.4912 53.8984 51.5706 53.6987 51.5708Z"
          fill="#E6E6E6"
        />
        <path
          d="M39.9239 12.417H21.2772C20.8779 12.4176 20.4952 12.5764 20.2129 12.8587C19.9306 13.141 19.7718 13.5237 19.7712 13.9229V50.0471L19.9484 49.9931V13.9229C19.9488 13.5706 20.089 13.2329 20.3381 12.9838C20.5872 12.7347 20.9249 12.5946 21.2772 12.5942H39.9789L39.9239 12.417ZM56.1789 12.417H21.2772C20.8779 12.4176 20.4952 12.5764 20.2129 12.8587C19.9306 13.141 19.7718 13.5237 19.7712 13.9229V54.494C19.7718 54.8932 19.9306 55.276 20.2129 55.5582C20.4952 55.8405 20.8779 55.9994 21.2772 55.9999H56.1789C56.5782 55.9994 56.9609 55.8405 57.2432 55.5582C57.5255 55.276 57.6843 54.8932 57.6849 54.494V13.9229C57.6843 13.5237 57.5255 13.141 57.2432 12.8587C56.9609 12.5764 56.5782 12.4176 56.1789 12.417ZM57.5077 54.494C57.5073 54.8463 57.3671 55.184 57.118 55.4331C56.8689 55.6822 56.5312 55.8223 56.1789 55.8228H21.2772C20.9249 55.8223 20.5872 55.6822 20.3381 55.4331C20.089 55.184 19.9488 54.8463 19.9484 54.494V13.9229C19.9488 13.5706 20.089 13.2329 20.3381 12.9838C20.5872 12.7347 20.9249 12.5946 21.2772 12.5942H56.1789C56.5312 12.5946 56.8689 12.7347 57.118 12.9838C57.3671 13.2329 57.5073 13.5706 57.5077 13.9229V54.494Z"
          fill="#3F3D56"
        />
        <path
          d="M46.8778 16.3147H30.5785C30.3671 16.3144 30.1645 16.2304 30.015 16.0809C29.8656 15.9314 29.7815 15.7288 29.7812 15.5174V11.6198C29.7815 11.4084 29.8656 11.2057 30.015 11.0563C30.1645 10.9068 30.3671 10.8227 30.5785 10.8225H46.8778C47.0892 10.8227 47.2918 10.9068 47.4413 11.0563C47.5907 11.2057 47.6748 11.4084 47.6751 11.6198V15.5174C47.6748 15.7288 47.5908 15.9314 47.4413 16.0809C47.2918 16.2304 47.0892 16.3144 46.8778 16.3147Z"
          :fill="getBGMenuColor"
        />
        <path
          d="M38.7281 11.0883C39.7065 11.0883 40.4998 10.295 40.4998 9.31659C40.4998 8.33812 39.7065 7.54492 38.7281 7.54492C37.7496 7.54492 36.9564 8.33812 36.9564 9.31659C36.9564 10.295 37.7496 11.0883 38.7281 11.0883Z"
          :fill="getBGMenuColor"
        />
        <!-- fill="#AC1A60" -->
        <path
          d="M38.728 10.3958C39.324 10.3958 39.8071 9.91263 39.8071 9.31666C39.8071 8.72068 39.324 8.23755 38.728 8.23755C38.1321 8.23755 37.6489 8.72068 37.6489 9.31666C37.6489 9.91263 38.1321 10.3958 38.728 10.3958Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_118_4532">
          <rect
            width="57.3697"
            height="56"
            fill="white"
            transform="translate(0.315186)"
          />
        </clipPath>
      </defs>
    </svg>
    <p class="pt-4">{{ text }}</p>
  </div>
</template>

<script>
import {
  getBGMenuColor,
  // getColorPrimaria,
  // getTextMenuColor,
  // setBGMenuColor,
  // setColorPrimaria,
  // setColorSecondary,
  // setTextMenuColor,
} from "@/state/modules/themeColor.store";

export default {
  props: {
    text: {
      type: String,
      default: "Não possui demonstrativo ainda.",
    },
  },
  setup() {
    return {
      getBGMenuColor,
    };
  },
};
</script>
