import { imageUrlToBase64 } from "@/composables/useBase64Img";
import {
  formatDate,
  formatMonthAndYearPtBr,
} from "@/composables/useFormatDate";
import {
  formatNumber,
  formatPriceWithoutBRL,
} from "@/composables/useFormatMoney";
import { usePdfMake } from "@/composables/usePdfMake";
import { apolloClient } from "@/state/client";
import { getReciboFolhaMensalDataSource } from "@/state/graphql/data-sources";
import { limitString } from "@/composables/useHandleString";
// import {  getEmpregadorHeaderPdf } from "@/state/graphql/data-sources";

export const gerarReciboFolhaPagamento = async (
  periodo,
  idEmpregador,
  typeCreate = "newTab"
) => {
  const customLabel = ({ codigo, descricao }) =>
    `${
      codigo.toString().length < 3 ? ("00" + codigo).slice(-3) : codigo
    } - ${descricao}`;

  const { openPdfNewTab, downloadPDF } = usePdfMake();

  const TABLE_CONFIGS_PDFMAKE = {
    borderColor: "#f2f6f9",
    borderWidth: 1.5,
  };

  let docDefinition = (folhaDados, logoEmpregador) => {
    let pages = [];
    let currentPage = null;
    let totalEventosPerPage = folhaDados.contaBancaria ? 9 : 10;
    for (
      let i = 0;
      i < folhaDados.eventosFolha.length;
      i += totalEventosPerPage
    ) {
      const eventosFolhaOrdered = [
        ...folhaDados.eventosFolha
          .filter(item => item.natureza === "PROVENTO")
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo),
        ...folhaDados.eventosFolha
          .filter(item => item.natureza === "DESCONTO")
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo),
        ...folhaDados.eventosFolha
          .filter(
            item => item.natureza !== "DESCONTO" && item.natureza !== "PROVENTO"
          )
          .sort((a, b) => a.rubricaCodigo - b.rubricaCodigo),
      ];
      let ficArray = [];
      currentPage =
        eventosFolhaOrdered.length > totalEventosPerPage
          ? "Página " + Math.trunc(i / 10 + 1)
          : " ";
      for (
        let j = 0;
        j <
        totalEventosPerPage -
          eventosFolhaOrdered.slice(i, i + totalEventosPerPage).length;
        j++
      ) {
        ficArray.push(0);
      }
      pages = [
        ...pages,
        {
          layout: "noBorders",
          table: {
            widths: ["auto", "*", 160],
            body: [
              [
                logoEmpregador
                  ? {
                      marginBottom: 5,
                      image: logoEmpregador,
                      fit: ["auto", 35],
                    }
                  : {
                      text: " ",
                      marginBottom: 20,
                    },
                {
                  alignment: "left",
                  text: [
                    {
                      text: limitString(folhaDados.localDenominacao, 60) + "\n",
                      bold: true,
                      fontSize: 10,
                    },
                    {
                      text: `${folhaDados.localInscricao}`,
                      fontSize: 8,
                      bold: false,
                    },
                    {
                      text: `\n ${limitString(folhaDados.localEndereco, 80)}`,
                      fontSize: 8,
                      bold: false,
                    },
                  ],
                },
                {
                  alignment: "center",
                  text: [
                    {
                      text: "Recibo de Pagamento de Salário",
                      fontSize: 8,
                      bold: false,
                    },
                    {
                      text: `\n ${formatMonthAndYearPtBr(periodo)
                        .toUpperCase()
                        .replace("-", "/")}`,
                      fontSize: 10,
                      bold: true,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          layout: {
            hLineWidth: function (i, node) {
              if (
                i === 0 ||
                (i === node.table.body.length && !folhaDados.contaBancaria)
              ) {
                return TABLE_CONFIGS_PDFMAKE.borderWidth;
              }
              return 0;
            },
            vLineWidth: function () {
              return 0;
            },
            hLineColor: function () {
              return TABLE_CONFIGS_PDFMAKE.borderColor;
            },
          },
          table: {
            widths: ["*", "auto", "auto"],
            body: [
              [
                {
                  marginTop: 5,
                  alignment: "left",
                  colSpan: 3,
                  text: [
                    {
                      lineHeight: 0.8,
                      text: limitString(
                        `${folhaDados.matricula} - ${folhaDados.nomeTrabalhador}`,
                        100
                      ),
                      bold: true,
                      fontSize: 10,
                    },
                  ],
                },
                { text: "" },
                { text: "" },
              ],
              [
                {
                  marginBottom: !folhaDados.contaBancaria ? 5 : 0,
                  lineHeight: !folhaDados.contaBancaria ? 1 : 0.7,
                  alignment: "left",
                  text: [
                    {
                      text: limitString(
                        `${folhaDados.cboFuncao} - ${folhaDados.descricaoFuncao}`,
                        60
                      ),
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  lineHeight: !folhaDados.contaBancaria ? 1 : 0.7,
                  alignment: "left",
                  text: [
                    {
                      text: "Admissão: ",
                      bold: true,
                      fontSize: 8,
                    },
                    {
                      text: `${
                        folhaDados.dtAdmissao
                          ? formatDate(folhaDados.dtAdmissao)
                          : "não tem dtAdmissao"
                      }`,
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  lineHeight: !folhaDados.contaBancaria ? 1 : 0.7,
                  alignment: "right",
                  text: [
                    {
                      text: "Salário Base: ",
                      bold: true,
                      fontSize: 8,
                    },
                    {
                      text: `${formatPriceWithoutBRL(folhaDados.salarioBase)} ${
                        folhaDados.undSalario
                      }`,
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        folhaDados.contaBancaria
          ? {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length) {
                    return TABLE_CONFIGS_PDFMAKE.borderWidth;
                  }
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
                hLineColor: function () {
                  return TABLE_CONFIGS_PDFMAKE.borderColor;
                },
              },
              table: {
                widths: ["*", "*", "auto", "auto", "auto"],
                body: [
                  [
                    { text: "" },
                    { text: "" },
                    {
                      alignment: "left",
                      marginBottom: 5,
                      text: [
                        {
                          text: "Banco: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: folhaDados.banco,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "center",
                      text: [
                        {
                          text: "Agência: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: folhaDados.bancoAgencia
                            ? folhaDados.bancoAgencia
                            : "",
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: "Conta: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: `${folhaDados.contaBancaria ? folhaDados.contaBancaria : ""}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                ],
              },
            }
          : null,
        {
          marginTop: 5,
          marginBottom: folhaDados.contaBancaria ? 6 : 3,
          layout: {
            hLineWidth: function () {
              return 0;
            },
            vLineWidth: function () {
              return 0;
            },
            fillColor: function (i) {
              return i === 0 ? "#f2f6f8" : "#fff";
            },
          },
          table: {
            widths: [250, 100, "*", "*"],
            body: [
              [
                {
                  alignment: "left",
                  text: [
                    {
                      text: "DESCRIÇÃO",
                      bold: true,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "left",
                  text: [
                    {
                      text: "REFERÊNCIA",
                      bold: true,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: "PROVENTOS",
                      bold: true,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: "DESCONTOS",
                      bold: true,
                      fontSize: 8,
                    },
                  ],
                },
              ],
              ...eventosFolhaOrdered
                .slice(i, i + totalEventosPerPage)
                .map(evento => [
                  {
                    alignment: "left",
                    text: [
                      {
                        text: customLabel({
                          codigo: evento.rubricaCodigo,
                          descricao: limitString(evento.rubricaDescricao, 60),
                        }),
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "left",
                    text: [
                      {
                        text:
                          evento.natureza === "INFORMATIVA_DEDUTORA" ||
                          evento.natureza === "INFORMATIVA"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "R$ 0,00"
                            : evento.descReferencia
                              ? limitString(evento.descReferencia, 25)
                              : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    text: [
                      {
                        text:
                          evento.natureza === "PROVENTO"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "R$ 0,00"
                            : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    text: [
                      {
                        text:
                          evento.natureza === "DESCONTO"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "R$ 0,00"
                            : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                ]),
              ...ficArray.map(() => [
                {
                  alignment: "left",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "left",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ]),
            ],
          },
        },
        {
          columns: [
            {
              layout: {
                hLineWidth: function () {
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
                fillColor: function (i) {
                  return i === 0 ? "#f2f6f8" : "#fff";
                },
              },
              table: {
                widths: [45, 60, 30, 40, 60, 25, 60],
                body: [
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "ENCARGOS",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    { text: "" },
                    { text: "" },
                    { text: "" },
                    { text: "" },
                    { text: "" },
                    { text: "" },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Base FGTS: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.baseFgts)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatNumber(folhaDados.percFgts)}%`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Valor:",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.fgts)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: "",
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Base INSS: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.baseInss)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatNumber(folhaDados.percInss)}%`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Valor:",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.inss)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: "",
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Base IRRF: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.baseIrrf)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatNumber(folhaDados.percIrrf)}%`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Dedução:",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.deduIrrf)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Valor:",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.irrf)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
            {
              width: 150,
              layout: {
                hLineWidth: function () {
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
                fillColor: function (i) {
                  return i === 0 ? "#f2f6f8" : "#fff";
                },
              },
              table: {
                widths: ["*", "*"],
                body: [
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "TOTAIS",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    { text: "" },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Proventos: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.bruto)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Descontos: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            folhaDados.descontos
                          )}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Líquido: ",
                          bold: true,
                          fontSize: 10,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.liquido)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          marginTop: 3,
          layout: "noBorders",
          table: {
            widths: ["*"],
            heights: [20],
            body: [
              [
                {
                  alignment: "left",
                  text: [
                    {
                      text:
                        folhaDados.msgRecibo !== null
                          ? folhaDados.msgRecibo
                          : "",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          marginTop: 3,
          layout: "noBorders",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  alignment: "center",
                  text: [
                    {
                      text: "DECLARO TER RECEBIDO A IMPORTÂNCIA LÍQUIDA DISCRIMINADA NESTE RECIBO.",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          columns: [
            {
              width: 150,
              marginTop: 11,
              layout: {
                hLineWidth: function () {
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
              },
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "____/____/________",
                          bold: false,
                          fontSize: 12,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
            {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length - 1) {
                    return 0.9;
                  }
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
              },
              marginTop: 8,
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      alignment: "right",
                      text: [
                        {
                          text: " ",
                          bold: false,
                          fontSize: 8.5,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "center",
                      text: [
                        {
                          text: `${folhaDados.nomeTrabalhador}`,
                          bold: false,
                          fontSize: 8.5,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          layout: "noBorders",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  alignment: "right",
                  text: [
                    {
                      text: currentPage,
                      bold: false,
                      fontSize: 10,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          marginTop: 8,
          marginBottom: 10,
          layout: {
            hLineWidth: function (i, node) {
              if (i === node.table.body.length - 1) {
                return 1.5;
              }
              return 0;
            },
            hLineColor: function () {
              return "#a0aec0";
            },
            hLineStyle: function () {
              return { dash: { length: 5, space: 3 } };
            },
            vLineWidth: function () {
              return 0;
            },
          },
          table: {
            widths: ["*"],
            body: [
              [
                {
                  text: "",
                  bold: false,
                  fontSize: 8.5,
                },
              ],
            ],
          },
        },
        {
          layout: "noBorders",
          table: {
            widths: ["auto", "*", 160],
            body: [
              [
                logoEmpregador
                  ? {
                      marginBottom: 5,
                      image: logoEmpregador,
                      fit: ["auto", 35],
                    }
                  : {
                      text: " ",
                      marginBottom: 20,
                    },
                {
                  alignment: "left",
                  text: [
                    {
                      text: limitString(folhaDados.localDenominacao, 60) + "\n",
                      bold: true,
                      fontSize: 10,
                    },
                    {
                      text: `${folhaDados.localInscricao}`,
                      fontSize: 8,
                      bold: false,
                    },
                    {
                      text: `\n ${limitString(folhaDados.localEndereco, 80)}`,
                      fontSize: 8,
                      bold: false,
                    },
                  ],
                },
                {
                  alignment: "center",
                  text: [
                    {
                      text: "Recibo de Pagamento de Salário",
                      fontSize: 8,
                      bold: false,
                    },
                    {
                      text: `\n ${formatMonthAndYearPtBr(periodo)
                        .toUpperCase()
                        .replace("-", "/")}`,
                      fontSize: 10,
                      bold: true,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          layout: {
            hLineWidth: function (i, node) {
              if (
                i === 0 ||
                (i === node.table.body.length && !folhaDados.contaBancaria)
              ) {
                return TABLE_CONFIGS_PDFMAKE.borderWidth;
              }
              return 0;
            },
            vLineWidth: function () {
              return 0;
            },
            hLineColor: function () {
              return TABLE_CONFIGS_PDFMAKE.borderColor;
            },
          },
          table: {
            widths: ["*", "auto", "auto"],
            body: [
              [
                {
                  marginTop: 5,
                  alignment: "left",
                  colSpan: 3,
                  text: [
                    {
                      lineHeight: 0.8,
                      text: limitString(
                        `${folhaDados.matricula} - ${folhaDados.nomeTrabalhador}`,
                        100
                      ),
                      bold: true,
                      fontSize: 10,
                    },
                  ],
                },
                { text: "" },
                { text: "" },
              ],
              [
                {
                  marginBottom: !folhaDados.contaBancaria ? 5 : 0,
                  lineHeight: !folhaDados.contaBancaria ? 1 : 0.7,
                  alignment: "left",
                  text: [
                    {
                      text: limitString(
                        `${folhaDados.cboFuncao} - ${folhaDados.descricaoFuncao}`,
                        60
                      ),
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  lineHeight: !folhaDados.contaBancaria ? 1 : 0.7,
                  alignment: "left",
                  text: [
                    {
                      text: "Admissão: ",
                      bold: true,
                      fontSize: 8,
                    },
                    {
                      text: `${
                        folhaDados.dtAdmissao
                          ? formatDate(folhaDados.dtAdmissao)
                          : "não tem dtAdmissao"
                      }`,
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  lineHeight: !folhaDados.contaBancaria ? 1 : 0.7,
                  alignment: "right",
                  text: [
                    {
                      text: "Salário Base: ",
                      bold: true,
                      fontSize: 8,
                    },
                    {
                      text: `${formatPriceWithoutBRL(folhaDados.salarioBase)} ${
                        folhaDados.undSalario
                      }`,
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        folhaDados.contaBancaria
          ? {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length) {
                    return TABLE_CONFIGS_PDFMAKE.borderWidth;
                  }
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
                hLineColor: function () {
                  return TABLE_CONFIGS_PDFMAKE.borderColor;
                },
              },
              table: {
                widths: ["*", "*", "auto", "auto", "auto"],
                body: [
                  [
                    { text: "" },
                    { text: "" },
                    {
                      alignment: "left",
                      marginBottom: 5,
                      text: [
                        {
                          text: "Banco: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: folhaDados.banco.displayName,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "center",
                      text: [
                        {
                          text: "Agência: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: folhaDados.bancoAgencia
                            ? folhaDados.bancoAgencia
                            : "",
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: "Conta: ",
                          bold: true,
                          fontSize: 8,
                        },
                        {
                          text: `${folhaDados.contaBancaria ? folhaDados.contaBancaria : ""}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                ],
              },
            }
          : null,
        {
          marginTop: 5,
          marginBottom: folhaDados.contaBancaria ? 6 : 3,
          layout: {
            hLineWidth: function () {
              return 0;
            },
            vLineWidth: function () {
              return 0;
            },
            fillColor: function (i) {
              return i === 0 ? "#f2f6f8" : "#fff";
            },
          },
          table: {
            widths: [250, 100, "*", "*"],
            body: [
              [
                {
                  alignment: "left",
                  text: [
                    {
                      text: "DESCRIÇÃO",
                      bold: true,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "left",
                  text: [
                    {
                      text: "REFERÊNCIA",
                      bold: true,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: "PROVENTOS",
                      bold: true,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: "DESCONTOS",
                      bold: true,
                      fontSize: 8,
                    },
                  ],
                },
              ],
              ...eventosFolhaOrdered
                .slice(i, i + totalEventosPerPage)
                .map(evento => [
                  {
                    alignment: "left",
                    text: [
                      {
                        text: customLabel({
                          codigo: evento.rubricaCodigo,
                          descricao: limitString(evento.rubricaDescricao, 60),
                        }),
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "left",
                    text: [
                      {
                        text:
                          evento.natureza === "INFORMATIVA_DEDUTORA" ||
                          evento.natureza === "INFORMATIVA"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "R$ 0,00"
                            : evento.descReferencia
                              ? limitString(evento.descReferencia, 25)
                              : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    text: [
                      {
                        text:
                          evento.natureza === "PROVENTO"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "R$ 0,00"
                            : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    text: [
                      {
                        text:
                          evento.natureza === "DESCONTO"
                            ? evento.valor !== null
                              ? formatPriceWithoutBRL(evento.valor)
                              : "R$ 0,00"
                            : "",
                        bold: false,
                        fontSize: 8,
                      },
                    ],
                  },
                ]),
              ...ficArray.map(() => [
                {
                  alignment: "left",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "left",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
                {
                  alignment: "right",
                  text: [
                    {
                      text: " ",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ]),
            ],
          },
        },
        {
          columns: [
            {
              layout: {
                hLineWidth: function () {
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
                fillColor: function (i) {
                  return i === 0 ? "#f2f6f8" : "#fff";
                },
              },
              table: {
                widths: [45, 60, 30, 40, 60, 25, 60],
                body: [
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "ENCARGOS",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    { text: "" },
                    { text: "" },
                    { text: "" },
                    { text: "" },
                    { text: "" },
                    { text: "" },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Base FGTS: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.baseFgts)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatNumber(folhaDados.percFgts)}%`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Valor:",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.fgts)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: "",
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Base INSS: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.baseInss)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatNumber(folhaDados.percInss)}%`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Valor:",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.inss)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: "",
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Base IRRF: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.baseIrrf)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatNumber(folhaDados.percIrrf)}%`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Dedução:",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.deduIrrf)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Valor:",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.irrf)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
            {
              width: 150,
              layout: {
                hLineWidth: function () {
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
                fillColor: function (i) {
                  return i === 0 ? "#f2f6f8" : "#fff";
                },
              },
              table: {
                widths: ["*", "*"],
                body: [
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "TOTAIS",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    { text: "" },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Proventos: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.bruto)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Descontos: ",
                          bold: true,
                          fontSize: 8,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(
                            folhaDados.descontos
                          )}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "Líquido: ",
                          bold: true,
                          fontSize: 10,
                        },
                      ],
                    },
                    {
                      alignment: "right",
                      text: [
                        {
                          text: `${formatPriceWithoutBRL(folhaDados.liquido)}`,
                          bold: false,
                          fontSize: 8,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          marginTop: 3,
          layout: "noBorders",
          table: {
            widths: ["*"],
            heights: [20],
            body: [
              [
                {
                  alignment: "left",
                  text: [
                    {
                      text:
                        folhaDados.msgRecibo !== null
                          ? folhaDados.msgRecibo
                          : "",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          marginTop: 3,
          layout: "noBorders",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  alignment: "center",
                  text: [
                    {
                      text: "DECLARO TER RECEBIDO A IMPORTÂNCIA LÍQUIDA DISCRIMINADA NESTE RECIBO.",
                      bold: false,
                      fontSize: 8,
                    },
                  ],
                },
              ],
            ],
          },
        },
        {
          columns: [
            {
              width: 150,
              marginTop: 11,
              layout: {
                hLineWidth: function () {
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
              },
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      alignment: "left",
                      text: [
                        {
                          text: "____/____/________",
                          bold: false,
                          fontSize: 12,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
            {
              layout: {
                hLineWidth: function (i, node) {
                  if (i === node.table.body.length - 1) {
                    return 0.9;
                  }
                  return 0;
                },
                vLineWidth: function () {
                  return 0;
                },
              },
              marginTop: 8,
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      alignment: "right",
                      text: [
                        {
                          text: " ",
                          bold: false,
                          fontSize: 8.5,
                        },
                      ],
                    },
                  ],
                  [
                    {
                      alignment: "center",
                      text: [
                        {
                          text: `${folhaDados.nomeTrabalhador}`,
                          bold: false,
                          fontSize: 8.5,
                        },
                      ],
                    },
                  ],
                ],
              },
            },
          ],
        },
        {
          layout: "noBorders",
          table: {
            widths: ["*"],
            body: [
              [
                {
                  alignment: "right",
                  text: [
                    {
                      text: currentPage,
                      bold: false,
                      fontSize: 10,
                    },
                  ],
                },
              ],
            ],
          },
        },
      ];
    }

    return {
      info: {
        title: `Recibo de Pagamento Salário de ${
          folhaDados.nomeTrabalhador
        } - ${formatMonthAndYearPtBr(periodo)}`,
        author: "Calculato Sistemas LTDA",
        keywords: "Folha Pagamento",
      },
      pageOrientation: "portrait",
      pageSize: "A4",
      pageMargins: [18, 17, 18, 18],
      content: pages,
    };
  };
  let logoEmpregador = null;

  const onOpenWindowPrint = async () => {
    let folhaDados = null;
    const respFolha = await apolloClient.query({
      query: getReciboFolhaMensalDataSource,
      variables: {
        reciboFolhaRequest: {
          periodo,
        },
        idEmpregador: idEmpregador,
      },
      fetchPolicy: "cache-first",
    });
    if (respFolha.data) {
      let apifolha = respFolha.data.reciboFolhaDataSource;
      folhaDados = {
        ...apifolha,
        eventosFolha: apifolha.eventosFolha.map(evfl => {
          return Object.assign({
            ...evfl,
            edit: false,
            isFixed: false,
            isLoading: false,
            loadingRevertEvento: false,
            tooltipMsg: null,
          });
        }),
      };

      logoEmpregador = await imageUrlToBase64(respFolha.data.reciboFolhaDataSource.logoUrl);
    }

    if (typeCreate === "newTab") {
      openPdfNewTab(docDefinition(folhaDados, logoEmpregador));
    } else {
      downloadPDF(
        `Recibo de Pagamento de Salário de ${
          folhaDados.nomeTrabalhador
        } - ${formatMonthAndYearPtBr(periodo)}`,
        docDefinition(folhaDados, logoEmpregador)
      );
    }
  };

  await onOpenWindowPrint();
};
